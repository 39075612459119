import { css } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';

// Schemas
import { RootState } from 'redux/schemas';
import { PracticeRoomTab } from 'redux/schemas/models/video-practice';
import { SubmissionsListstate } from 'redux/schemas/app/video-practice';

// Actions
import { getVideoPracticeSubmissions } from 'redux/actions/video-practice';

// Selectors
import { getFeaturedSubmissions, getSubmissionIds } from 'redux/selectors/video-practice';

// Styles
import {
  largeSpacing, extraLargeSpacing,
} from 'styles/global_defaults/scaffolding';
import { handheld } from 'styles/global_defaults/media-queries';

// Hooks
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';

// Components
import EmptyTab from '../shared/empty-tab';
import TabLoadingWrapper from '../shared/tab-loading-wrapper';
import GalleryCard from '../shared/gallery-card';

const styles = () => css`
  margin-top: ${largeSpacing}px;
  ${handheld(css`
    margin-top: ${extraLargeSpacing}px;
  `)};
`;

const FeaturedTab = () => {
  const dispatch = useAppDispatch();

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  const scenarioId = useSelector((state) => state.app.practiceRoom.params.scenarioId);
  const selectedTab = useSelector((state) => state.app.practiceRoom.params.selectedTab);
  const {
    isOnceLoaded, isLoading,
    hasMore,
  } = useSelector<RootState, SubmissionsListstate>((state) => getFeaturedSubmissions(state));
  const submissionIds = useSelector<RootState, number[]>((state) => getSubmissionIds(state, {
    selectedTab: PracticeRoomTab.FEATURED,
  }));
  const scrollingElement: HTMLElement = document.querySelector('.tab-content');
  const isPageBottom = useInfiniteScroll(scrollingElement, 40);

  useEffect(() => {
    if (selectedTab === PracticeRoomTab.FEATURED) {
      dispatch(getVideoPracticeSubmissions({
        scenarioId,
        tab: PracticeRoomTab.FEATURED,
        page: pageNo,
      }));
    }
  }, [selectedTab, scenarioId, dispatch, pageNo]);

  // Change page no when scrolled to bottom
  useEffect(() => {
    if (scrollingElement
      && isPageBottom
      && !isLoading
      && hasMore) {
      setPageNo((prePageNo => prePageNo + 1));
    }
  }, [scrollingElement, isPageBottom, isLoading, hasMore]);

  // First load doesn't need the bottom loading. Bottom loading need to be set
  // only after the first call
  useEffect(() => {
    if (isOnceLoaded && pageNo > 1) {
      setIsPageLoading(isLoading);
    }
  }, [isOnceLoaded, isLoading, pageNo]);

  return (
    <div
      css={styles}
      // Margin bottom given while loading more data on infinte scrolling
      // to display margin below the loading placeholder
      className={`d-flex flex-column mx-auto gallery-card-tab-container ${isPageLoading ? 'mb-6' : ''}`}
    >
      <div
        className='text-medium text-gray-2 mb-5 featured-intro'
      >
        {t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.FEATURED_TAB.INTRO()}
      </div>
      <TabLoadingWrapper isLoaded={isOnceLoaded} isMoreLoading={isPageLoading}>
        <React.Fragment>
          {submissionIds.length > 0
            ? (
              <div className='d-flex flex-wrap'>
                {submissionIds.map(submissionId => (
                  <GalleryCard key={submissionId} submissionId={submissionId} />
                ))}
              </div>
            )
            : (
              <EmptyTab
                icon='practice'
                description={t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.TABS.NO_CONTENT.FEATURED()}
              />
            )}
        </React.Fragment>
      </TabLoadingWrapper>
    </div>
  );
};

export default FeaturedTab;
