/* @ngInject */
export default function UserCourseModelService(
  _,
  $q,
  UserCoursesResource,
) {
  const LIVE_SESSION_SOURCES = {
    ZOOM_USER_LEVEL: 'zoomOauth',
    ZOOM_ACCOUNT_LEVEL: 'zoomAccountOauth',
    MS_TEAMS_USER_LEVEL: 'microsoftGraphOauth',
  };

  class UserCourseModel {
    constructor(attributes) {
      _.extend(this, attributes);

      this.LIVE_SESSION_SOURCES = LIVE_SESSION_SOURCES;
    }

    validateAndSaveZoomHostUser(host) {
      return UserCoursesResource.validateAndSaveZoomHostUser({ catalogId: this.catalogId }, { hostUserId: host })
        .$promise.then((response) => {
          const isValidHost = response.result.isValid;
          if (isValidHost) {
            this.zoomUserId = host;
          }

          return isValidHost;
        });
    }

    getLiveSessionSettings() {
      if (this.integrationSettings) {
        return $q.when(this.integrationSettings);
      }

      return UserCoursesResource.getLiveSessionSettings({ catalogId: this.catalogId })
        .$promise.then((response) => {
          const returnResponse = {};
          if (response.result) {
            _.map(response.result, (integration, key) => {
              if (!_.isEmpty(integration)) {
                if (key === this.LIVE_SESSION_SOURCES.ZOOM_ACCOUNT_LEVEL) {
                  returnResponse.ZoomLiveSession = {
                    ...integration,
                    source: key,
                    authenticated: integration.authenticatedStatus === 'authenticated',
                    needsReAuthentication: integration?.authenticatedStatus === 'not_authenticated',
                    zoomUserId: response.result.zoomAccountOauthUserId,
                  };
                }
                if (
                  key === this.LIVE_SESSION_SOURCES.ZOOM_USER_LEVEL
                  || key === this.LIVE_SESSION_SOURCES.MS_TEAMS_USER_LEVEL
                ) {
                  returnResponse[key === this.LIVE_SESSION_SOURCES.ZOOM_USER_LEVEL ? 'ZoomLiveSession' : 'TeamsLiveSession'] = {
                    ...integration,
                    source: key,
                    authenticated: integration?.authenticatedStatus === 'authenticated',
                    email: integration?.authenticationEmail,
                    needsReAuthentication: integration?.authenticatedStatus === 'not_authenticated',
                  };
                }
              }
            });
            returnResponse.authenticatedStatus = response.result.authenticatedStatus;
          }
          this.integrationSettings = returnResponse;
          return returnResponse;
        });
    }
  }

  return UserCourseModel;
}
