/* eslint-disable react-hooks/rules-of-hooks */
import TranslationButton from 'components/translation-button';
import React from 'react';
import { extractTextFromHTML } from 'shared/utils';
import { standardSpacing } from 'styles/global_defaults/scaffolding';

/* @ngInject */
/* @ngInject */
export default function NvTranslationButton(_, $timeout) {
  return {
    scope: {
      item: '=',
      pendoTag: '@?'
    },
    controller: ['$scope', function ($scope) {
      const vm = this;

      let setToggleState;
      let setLoadingState;

      $scope.ReactAngularTranslationButton = () => {
        const [isTranslationOn, setIsTranslationOn] = React.useState(vm.item.isTranslationOn);
        const [isLoading, setIsLoading] = React.useState(vm.item.isTranslationLoading)

        setToggleState = setIsTranslationOn;
        setLoadingState = setIsLoading;

        const onToggleHandler = (isStateOn: boolean, language: string) => {
          vm.item.onToggleTranslate(isStateOn, language);
        };

        return (
          <div
            style={{
              marginLeft: `${standardSpacing}px`,
            }}
          >
            <TranslationButton
              isToggled={isTranslationOn}
              isLoading={isLoading}
              isVisible={!vm.item.belongsToCurrentUser()}
              onToggle={onToggleHandler}
              dataQa={vm.pendoTag}
              sourceText={extractTextFromHTML(vm.item.body)}
            />
          </div>
        );
      };

      $scope.$watch('vm.item.isTranslationOn', (newToggleState) => setToggleState?.(newToggleState));
      $scope.$watch('vm.item.isTranslationLoading', (newLoadingState) => setLoadingState?.(newLoadingState));
    }],
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'shared/directives/nv-translation-button/template.html',
  };
}
