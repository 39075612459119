import AdvancedSettings from 'institutions/components/advanced-settings/advanced-settings';

/* @ngInject */
export default function InstitutionAdvancedSettingsCtrl(
  $scope,
  InstitutionsManager,
  validationConstants,
  StateManager,
  CurrentPermissionsManager,
  config,
  _,
) {
  $scope.StateManager = StateManager;

  const vm = this;

  vm.config = config;

  vm.InstitutionsManager = InstitutionsManager;
  vm.CurrentPermissionsManager = CurrentPermissionsManager;
  vm.validationConstants = validationConstants;
  $scope.AdvancedSettings = AdvancedSettings;
}
