import React, { useContext, useEffect, useState } from 'react';
import t from 'react-translate';
import { AngularContext, AngularServicesContext } from 'react-app';

import SectionContent from '../section-content';
import { DisableProps } from './integrations';
import { config } from '../../../../../config/pendo.config.json';

const MsCalendarIntegration = (props: { onDisable: (props: DisableProps) => void }) => {
  const { RailsRoutes } = useContext(AngularServicesContext);
  const { injectServices, $scope } = useContext(AngularContext);
  const [InstitutionsManager] = injectServices(['InstitutionsManager']);
  const [hasIntegration, setHasIntegration] = useState($scope.$eval('vm.InstitutionsManager.institution.hasMicrosoftCalendarIntegration'));

  useEffect(() => {
    $scope.$watch('vm.InstitutionsManager.institution.hasMicrosoftCalendarIntegration', (newValue) => {
      setHasIntegration(newValue);
    });
  }, [$scope]);

  const configureCalendar = () => {
    window.location.href = RailsRoutes.authenticateMicrosoftCalendarOauthUrl(InstitutionsManager.institution.id);
  };

  const disableCalendar = () => {
    props.onDisable({
      action: () => InstitutionsManager.disableCalendarSettings(InstitutionsManager.INTEGRATION_TYPES.MS_CALENDAR_USER_LEVEL),
      onSuccess: () => InstitutionsManager.institution.updateFromReact({
        hasMicrosoftCalendarIntegration: null,
      }),
      successMessage: t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_CALENDAR_INSTITUTION_INTEGRATION.DISABLE_SUCCESS(),
      title: t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_CALENDAR_INSTITUTION_INTEGRATION.DISABLE_TITLE(),
      bodyText: t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_CALENDAR_INSTITUTION_INTEGRATION.DISABLE_DESCRIPTION(),
      confirmDataQa: config.pendo.settings.msCalendar.disableConfirm,
      cancelDataQa: config.pendo.settings.msCalendar.disableCancel,
    });
  };

  const ctaText = hasIntegration ? t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_CALENDAR_INSTITUTION_INTEGRATION.DISABLE()
    : t.INSTITUTIONS.ADVANCED_SETTINGS.CONFIGURE();
  const description = [t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_CALENDAR_INSTITUTION_INTEGRATION.SECTION_DESCRIPTION()];
  if (hasIntegration) {
    description.push(t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_CALENDAR_INSTITUTION_INTEGRATION.ADVICE(
      InstitutionsManager.institution.calendarSettings?.authenticatedUser?.accountEmail,
    ));
  }

  return (
    <SectionContent
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_CALENDAR_INSTITUTION_INTEGRATION.SECTION_TITLE()}
      description={description}
      showCTAButton
      ctaText={ctaText}
      onAction={() => (hasIntegration ? disableCalendar() : configureCalendar())}
      buttonVariant={hasIntegration ? 'secondary' : 'primary'}
      dataQa={config.pendo.settings.msCalendar.cta}
    />
  );
};

export default MsCalendarIntegration;
