import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { LoadingSpinnerType } from 'shared/components/loading-spinner';
import LoadingWrapper, { LoaderType } from 'shared/components/loading-wrapper';

import { getSubmission } from 'redux/selectors/video-practice';
import { Button } from 'react-bootstrap';
import { useAppDispatch } from 'redux/store';
import { generateInsights } from 'redux/actions/video-practice';
import { PracticeSubmissionContext } from '../utils';
import FillerWords from './filler-words/filler-words';
import KeyPhrases from './key-phrases/key-phrases';
import { config } from '../../../../../config/pendo.config.json';

const InsightsTab = () => {
  const { submissionId, scenarioId } = useContext(PracticeSubmissionContext);
  const {
    transcriptionInsightsStatus,
    fillerInsightStatus,
  } = useSelector((state) => getSubmission(state, submissionId));

  const dispatch = useAppDispatch();

  return (
    <React.Fragment>
      {/* Display CTA to generate insights if both insights are in `not_started` state */}
      {transcriptionInsightsStatus === 'not_started' ? (
        <div className='d-flex flex-column text-gray-1 text-small'>
          {t.PRACTICE_ROOM.INSIGHTS.GENERATE_INSIGHTS.INFO()}
          <Button
            variant='primary'
            className='align-self-center mt-6 text-small'
            onClick={() => dispatch(generateInsights({ submissionId, scenarioId }))}
            data-qa={config.pendo.practice.generateInsights}
          >
            {t.PRACTICE_ROOM.INSIGHTS.GENERATE_INSIGHTS.CTA()}
          </Button>
        </div>
      ) : (
      /**
       * Display the loading spinner till the status changes to `completed` in case of keyphrases(transcription),
       * and for filler words both `not_started` and `completed` states are loaded states,
       * and display CTA for `not_started`
       */
        <LoadingWrapper
          loaderType={LoaderType.SPINNER}
          spinnerType={LoadingSpinnerType.CIRCLE}
          isLoaded={transcriptionInsightsStatus === 'completed'
          && (fillerInsightStatus === 'completed' || fillerInsightStatus === 'not_started' || fillerInsightStatus === 'disabled')}
          className='insights-loader d-flex flex-column align-items-center'
          loadingInfo={(
            <div className='page-title-small gray-2 mt-6'>
              {t.PRACTICE_ROOM.INSIGHTS.LOADING()}
            </div>
          )}
          loadingInfoNoRender
        >
          <KeyPhrases />
          <FillerWords />
        </LoadingWrapper>
      )}
    </React.Fragment>

  );
};
export default InsightsTab;
