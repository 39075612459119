import React from 'react';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';

import t from 'react-translate';
import { RootState } from 'redux/schemas';
import { useAppDispatch } from 'redux/store';
import NvIcon from 'shared/components/nv-icon';
import NvFroala from 'froala/components/nv-froala';
import { AngularServicesContext } from 'react-app';
import NvTooltip from 'shared/components/nv-tooltip';
import NvPopover from 'shared/components/nv-popover';
import { ProgressiveQuizMode } from 'quizzes/components/mode';
import { getFlatCourseAliases } from 'redux/selectors/course';
import ClickableContainer from 'components/clickable-container';
import useDebouncedEffect from 'shared/hooks/use-debounced-effect';
import { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import { gray4, gray5, gray7, primary } from 'styles/global_defaults/colors';
import { QuizQuestionFeedback } from 'redux/schemas/models/progressive-quiz';
import ValidationErrorMessage from 'shared/components/validation-error-message';
import ProgressiveQuizContext, { QuestionContext } from 'quizzes/components/context';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import {
  addQuizQuestionFeedback,
  editQuizQuestionFeedback,
  deleteQuizQuestionFeedback,
} from 'redux/actions/quizzes';
import {
  doubleSpacing,
  halfSpacing,
  largeSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { isEmpty } from 'underscore';
import { normalizeHTMLInput } from 'lecture_pages/components/accordion-lecture-component/accordion-utils';
import { wrapThunkAction } from 'redux/utils';
import { config } from '../../../config/config.json';

const getFeedbackStyles = (isEditMode: boolean) => css`
    width: 100%;

    .fr-element {
      overflow-y: hidden;
      border: 0!important;
      outline: 0!important;
      padding-top: 0!important;
      padding-bottom: 0!important;
      background-color: transparent!important;
      padding-left: ${isEditMode ? halfSpacing : 0}px!important;
      padding-right: ${isEditMode ? halfSpacing : 0}px!important;

      p:last-child {
        margin-bottom: 0px;
      }
    }

    .fr-placeholder {
      border: 0!important;
      background-color: transparent!important;
    }
  `;

type Props = {
  onVisibleChange: (visible: boolean) => void;
};

const ProgressiveFeedback = (props: Props) => {
  const { onVisibleChange } = props;

  const onVisibleChangeRef = React.useRef(onVisibleChange);
  onVisibleChangeRef.current = onVisibleChange;

  const dispatch = useAppDispatch();
  const courseAliases = useSelector(getFlatCourseAliases);
  const angularServices = React.useContext(AngularServicesContext);
  const { registerSaving } = React.useContext(SavingRegistryContext);
  const {
    mode,
    progressiveQuiz,
    setIsQuizSaving,
    setIsSaved,
    setShowSavingContainer,
    timeoutRef,
  } = React.useContext(ProgressiveQuizContext);
  const { currentQuestion, currentQuestionResponse } = React.useContext(QuestionContext);
  const questionOptions = useSelector((state: RootState) => state.models.quizQuestionOptions);
  const quizQuestionFeedbacks = useSelector((state: RootState) => state.models.quizQuestionFeedbacks);

  const isEditMode = mode === ProgressiveQuizMode.EDIT;

  const { response, numberOfCorrectOptions } = currentQuestionResponse ?? {};

  let correctOptionsCount;

  if (numberOfCorrectOptions > 1) {
    correctOptionsCount = (response as number[]).map((id) => questionOptions[id]).filter((questionOption) => questionOption.isCorrect).length;
  }

  const hasPredefinedHint = correctOptionsCount !== undefined;

  const currentFeedback = quizQuestionFeedbacks[currentQuestionResponse?.attemptFeedback];

  const hasFeedbackDefined = !!currentFeedback?.feedbackText && currentFeedback?.feedbackText !== defaultEditorTemplate;

  const isFeedbackVisible = (currentQuestionResponse && !currentQuestionResponse?.isCorrect) && (
    hasPredefinedHint || hasFeedbackDefined
  );


  React.useLayoutEffect(() => {
    onVisibleChangeRef.current(isFeedbackVisible);
  }, [isFeedbackVisible]);

  if (isEditMode) {
    const styles = css`
      border-top: 4px solid ${gray5};

      .add-option {
        padding: 0 ${threeQuartersSpacing}px;
      }
    `;

    const handleAdd = () => {
      const unregister = registerSaving();
      setShowSavingContainer(true);
      setIsQuizSaving(true);
      clearTimeout(timeoutRef.current);

      wrapThunkAction(dispatch(addQuizQuestionFeedback({
        feedback: '',
        questionId: currentQuestion.id,
      }))).then(() => {
        setIsSaved(true);
      }).catch(() => {
        setIsSaved(false);
      }).finally(() => {
        setIsQuizSaving(false);
        unregister();

        timeoutRef.current = setTimeout(() => {
          setShowSavingContainer(false);
        }, 2000);
      });
    };

    const isAddFeedbackHidden = currentQuestion.attemptFeedbacks.length >= progressiveQuiz.questionMaximumAttempts;

    const isOneFeedbackLeft = currentQuestion.attemptFeedbacks.length === 1;

    return (
      <div css={styles} className='d-flex flex-column pt-2'>
        {currentQuestion.attemptFeedbacks.map((feedbackId, index) => {
          const feedback = quizQuestionFeedbacks[feedbackId];

          return (
            <ProgressiveFeedbackItem
              index={index}
              key={feedbackId}
              feedback={feedback}
              deleteDisabled={isOneFeedbackLeft}
            />
          );
        })}
        {!isAddFeedbackHidden && (
          <NvTooltip
            maxWidth={300}
            text={t.QUIZZES[currentQuestion.attemptFeedbacks ? 'HINT_EXPLANATION_AGAIN' : 'HINT_EXPLANATION'](courseAliases)}
          >
            <ClickableContainer
              onClick={handleAdd}
              className='text-regular add-option align-self-start text-primary'
              data-qa={config.pendo.activities.progressiveQuiz.questionModal.addNewQuestionAttemptHint}
            >
              <NvIcon icon='add' size='small' className='mr-2' />
              {t.QUIZZES.ADD_PROGRESSIVE_FEEDBACK()}
            </ClickableContainer>
          </NvTooltip>
        )}
      </div>
    );
  }

  const notEditModeFeedbackStyles = css`
    ${getFeedbackStyles(false)};
  `;

  return isFeedbackVisible && (
    <div>
      {hasPredefinedHint && (
        <div className='text-danger d-flex align-items-center pt-2'>
          <NvIcon icon='warning' size='small' className='mr-2' />
          <div className='text-large-body bold'>{t.QUIZZES.MCMA.CORRECT_ANSWER_COUNT(numberOfCorrectOptions.toString(), correctOptionsCount)}</div>
        </div>
      )}
      {hasFeedbackDefined && (
        <div css={notEditModeFeedbackStyles}>
          <ResponsivelyEmbeddedAngularHTML
            angularServices={angularServices}
            template={currentFeedback.feedbackText}
            className='fr-element question-option-content-view'
          />
        </div>
      )}
    </div>
  );
};

const defaultEditorTemplate = '<p class="froala-style-medium"><strong><br></strong></p>';

type ProgressiveFeedbackItemProps = {
  index: number;
  feedback: QuizQuestionFeedback;
  deleteDisabled: boolean;
};

const ProgressiveFeedbackItem = (props: ProgressiveFeedbackItemProps) => {
  const { index, feedback, deleteDisabled } = props;

  const dispatch = useAppDispatch();
  const mountedRef = React.useRef(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const courseAliases = useSelector(getFlatCourseAliases);
  const { currentQuestion } = React.useContext(QuestionContext);
  const { registerSaving } = React.useContext(SavingRegistryContext);

  const {
    progressiveQuiz,
    setIsQuizSaving,
    setShowSavingContainer,
    setIsSaved,
    timeoutRef,
  } = React.useContext(ProgressiveQuizContext);
  const { questionMaximumAttempts } = progressiveQuiz;

  const [inputValue, setInputValue] = React.useState(feedback.feedbackText || defaultEditorTemplate);

  useDebouncedEffect(() => {
    if (mountedRef.current) {
      const unregister = registerSaving();
      setShowSavingContainer(true);
      setIsQuizSaving(true);
      clearTimeout(timeoutRef.current);

      dispatch(editQuizQuestionFeedback({
        questionId: currentQuestion.id,
        questionFeedbackId: feedback.id,
        feedback: inputValue,
      })).then((res) => {
        setIsQuizSaving(false);
        if (isEmpty(res.error)) {
          setIsSaved(true);
        } else {
          setIsSaved(false);
        }
      }).finally(() => {
        unregister();
        timeoutRef.current = setTimeout(() => {
          setShowSavingContainer(false);
        }, 2000);
      });
    } else {
      mountedRef.current = true;
    }
  }, 250, [inputValue, currentQuestion.id, feedback.id]);

  const styles = css`
    padding: ${threeQuartersSpacing}px;

    .feedback-index {
      width: ${largeSpacing}px;
      height: ${largeSpacing}px;
    }

    .input-container {
      flex: 1;
      min-height: ${doubleSpacing}px;

      ${!isFocused && css`
        &:hover {
          background-color: ${gray7}
        }
      `}

      ${!feedback.feedbackText && css`
        border: none;
      `};

      ${isFocused && css`
        background-color: transparent;
      `};

      .feedback-content {
        ${getFeedbackStyles(true)};
      }
    }

    .delete-button {
      opacity: 0;
    }

    &:hover {
      .delete-button {
        opacity: 1;
      }
    }
  `;

  const handleDelete = () => {
    const unregister = registerSaving();
    setShowSavingContainer(true);
    setIsQuizSaving(true);
    clearTimeout(timeoutRef.current);

    wrapThunkAction(dispatch(deleteQuizQuestionFeedback({
      questionFeedbackId: feedback.id,
      questionId: currentQuestion.id,
    }))).then(() => {
      setIsSaved(true);
    }).catch(() => {
      setIsSaved(false);
    }).finally(() => {
      setIsQuizSaving(false);
      unregister();

      timeoutRef.current = setTimeout(() => {
        setShowSavingContainer(false);
      }, 2000);
    });
  };

  const showUnshownFeedbackWarning = questionMaximumAttempts < (index + 1);

  return (
    <div className='d-flex flex-row align-items-center mb-2' css={styles}>
      <div className='feedback-index course-title-small mr-1 d-flex align-items-center justify-content-center'>
        {index + 1}
      </div>
      <NvTooltip
        maxWidth={300}
        text={t.QUIZZES[index ? 'HINT_EXPLANATION_AGAIN' : 'HINT_EXPLANATION'](courseAliases)}
      >
        <div className='input-container d-flex align-items-center flex-row'>
          <NvFroala
            allowToolbar
            value={inputValue}
            editorClass='no-padding'
            immediateReactModelUpdate
            className='feedback-content'
            minHeight={threeQuartersSpacing}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={t.QUIZZES.PROGRESSIVE_FEEDBACK_PLACEHOLDER()}
            keepFormatOnDelete
            pastePlain
            blockBackspaceOnEmpty
            // Removing background this way because we can't use "no-background"
            // class because it changes depending on whether it has a value or
            // not, and editorClass is only evaluated once on first render,
            // consequent renders config is not applied.
            css={!!inputValue && css`
              .fr-element {
                background-color: transparent!important;
              }
            `}
            onChange={(html) => {
              setInputValue(html);
            }}
            dataQa={config.pendo.activities.progressiveQuiz.questionModal.questionAttemptHintInput}
            dataQaId={`${config.pendo.activities.progressiveQuiz.questionModal.questionAttemptHintInput}_${feedback.id}`}
          />
        </div>
      </NvTooltip>
      {showUnshownFeedbackWarning && (
        <NvPopover
          showOnHover
          placement='top'
          content={<ValidationErrorMessage text={t.QUIZZES.UNSHOWN_FEEDBACK_WARNING(courseAliases)} />}
          className='ml-3'
        >
          <div>
            <NvIcon icon='warning' size='small' className='text-danger' />
          </div>
        </NvPopover>
      )}
      {!deleteDisabled && (
      <NvTooltip text={t.NOVOED.DELETE()}>
        <ClickableContainer
          onClick={handleDelete}
          className='delete-button ml-3'
          data-qa={config.pendo.activities.progressiveQuiz.questionModal.questionAttemptHintDelete}
          data-qa-id={`${config.pendo.activities.progressiveQuiz.questionModal.questionAttemptHintDelete}_${feedback.id}`}
        >
          <NvIcon icon='trash' size='small' className='text-primary' />
        </ClickableContainer>
      </NvTooltip>
      )}
    </div>
  );
};

export default ProgressiveFeedback;
