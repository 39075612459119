import { normalize } from 'normalizr';
import { contains, each, find, isEmpty, map, without } from 'underscore';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import mergeWith from 'lodash/mergeWith';
import transform from 'lodash/transform';
import { createReducer, Draft } from '@reduxjs/toolkit';
import {
  updateLectureComponent, moveComponent, setTimelineVisible, getLecturePage,
  setLeftPanelVisibility, setTopBarVisibility, setBottomBarVisibility,
  setNewComponentIndex, setLeftPanelView, addAccordionSection, deleteAccordionSection,
  updateAccordionSection, setLectureAppStateFromRouteParams, createLecturePage,
  deleteLecturePage, duplicateLecturePage, markLecturePageAsViewed, updateLecturePage,
  updateLecturePageEstimates, previewLecturePageViewOptions, setLectureHeaderIsSaving,
  setLecturePageAsCached, unsetLecturePageAsCached, unsetAllCachedLecturePages,
  LectureComponentUpdateParams, setTimelineIsExpanded,
  getCourseExercises, setLecturePageModeIsSwitching, markLecturePageExited, setPreventLecturePageNavigation,
  resetTimelineLoadedState,
  unlinkCollectionLesson,
  unsetcurrentLectureAppState,
  setLecturePageSyncAsCompleted,
  linkCollectionLessonsToCourse,
  setDestinationLecturePage,
  resetDestinationLecturePage,
  setLeftPanelNovoAIItemType,
  setLeftPanelNovoAIItemTrueType,
  resetLeftPanelNovoAI,
  setLeftPanelNovoAIContentId,
  setLeftPanelAiMode,
  lecturePageBottomReachedState,
  setLeftPanelNovoAILengthAndTone,
  updateLecturePageReleaseDate,
} from 'redux/actions/lecture-pages';
import { LecturePageSchema } from 'redux/schemas/api/lecture-pages';
import { LectureComponentSchema } from 'redux/schemas/api/lecture-components';
import { CombinedLecturePage, LecturePage, NovoAIItemType, LecturePageState, PreventNavigationReason, SyncingStatus } from 'redux/schemas/models/lecture-page';
import { AccordionLectureComponentData, ComponentType, NLectureComponent } from 'redux/schemas/models/lecture-component';
import { RootState } from 'redux/schemas';
import { replaceArrays } from 'shared/lodash-utils';
import { getLecturePagefromParam, getLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { getCurrentUser } from 'redux/selectors/users';
import { innerPayloadKey } from 'redux/schemas/models/activity';
import moment from 'moment';
import { initialLecturePageState, initialRootState } from './index';

const addLecturePageToModel = (state: Draft<RootState>, lecturePage: LecturePage) => {
  const currentUser = getCurrentUser(state);
  const data = normalize(lecturePage, LecturePageSchema);

  if (currentUser.translationPreferenceLanguage) {
    data.entities.lecturePages = transform(data.entities.lecturePages, (result, value, key) => {
      result[key] = {
        ...omit(value, 'title'),
        translatedTitle: value.title,
      };
    }, {});
  }

  mergeWith(state.models, data.entities, replaceArrays);
};

/** Adds a lecture page's id to its lecture section's id list, and then re-indexes all the pages
 * under that section.
 * Note that the new lecture page needs to already be in the lecture page model before calling this
 * function or else this will error. */
const updateSectionWithLecturePage = (state: Draft<RootState>, lecturePage: LecturePage) => {
  const lectureSection = state.models.lectureSections[lecturePage.lectureSectionId];
  // Add the lecture section id to the section's lecture page list, and then re-index the pages
  if (!isEmpty(lectureSection)) {
    lectureSection.lecturePages.splice(lecturePage.index, 0, lecturePage.id);
    lectureSection.lecturePages.forEach((lpIndex, i) => { state.models.lecturePages[lpIndex].index = i; });
  }
};

const updateLectureComponentData = (state: Draft<RootState>, componentData: LectureComponentUpdateParams['componentData'], merge: boolean) => {
  const data = normalize(componentData, LectureComponentSchema);

  if (merge) {
    mergeWith(state.models.lectureComponents[data.result], data.entities.lectureComponents[data.result], replaceArrays);
  } else {
    state.models.lectureComponents[data.result] = data.entities.lectureComponents[data.result];
  }

  // Populating lecture component entities to models
  mergeWith(state.models, data.entities, replaceArrays);
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(getLecturePage.pending, (state, action) => {
      // TODO: I think I failed to use this anywhere; revise
      if (action.meta.arg.lecturePageId === state.app.destinationLecturePage?.id) {
        state.app.destinationLecturePage.loaded = false;
      } else {
        state.app.lecturePage.isCurrentLectureLoaded = false;
      }
    })
    .addCase(getLecturePage.fulfilled, (state, action) => {
      const lecturePage = cloneDeep(action.payload);

      /**
       * Removing the `pointsReceived` field from the lecture component activity
       * to avoid overriding of the value.
       */
      if (state.app.lecturePage.isAdminMode) {
        lecturePage.lectureComponents = map(lecturePage.lectureComponents, (lc) => {
          if (contains([ComponentType.VIDEO, ComponentType.AUDIO], lc.type)) {
            lc[innerPayloadKey[lc.type]] = map(lc[innerPayloadKey[lc.type]], (activity) => omit(activity, 'pointsReceived'));
          } else {
            lc[innerPayloadKey[lc.type]] = omit(lc[innerPayloadKey[lc.type]], 'pointsReceived');
          }
          return lc;
        });
      }

      addLecturePageToModel(state, lecturePage);
      state.app.lecturePage.isTranslating = false;

      if (action.meta.arg.lecturePageId === state.app.destinationLecturePage?.id) {
        state.app.destinationLecturePage.loaded = true;
      } else {
        state.app.lecturePage.isCurrentLectureLoaded = true;
      }
    })
    // Let's consider achieving this by calling getLecturePage above if the logic grows
    .addCase(createLecturePage.pending, (state, action) => {
      state.app.lecturePage.isCurrentLectureLoaded = false;
    })
    .addCase(createLecturePage.fulfilled, (state, action) => {
      const lecturePage = cloneDeep(action.payload);
      addLecturePageToModel(state, lecturePage);
      updateSectionWithLecturePage(state, lecturePage);
    })
    .addCase(duplicateLecturePage.pending, (state, action) => {
      const { lecturePageId, isFromLessonsList } = action.meta.arg;

      /**
       * We are not showing a saving overlay when the copying is initiated from
       * the lesson list. So keeping the copying lecture page in Zero id to show
       * the copying status in the lesson list.
       */
      if (isFromLessonsList) {
        const lecturePage = state.models.lecturePages[lecturePageId];
        const collectionFolder = state.models.collectionFolders[lecturePage.folderId];

        state.models.lecturePages[0] = { ...lecturePage, id: 0 };
        collectionFolder.lecturePages = [...collectionFolder.lecturePages ?? [], 0];
        state.app.collection.newLecturePages[lecturePage.folderId] = [...state.app.collection.newLecturePages[lecturePage.folderId] ?? [], 0];
      } else {
        state.app.showSavingOverlay = true;
      }
    })
    .addCase(duplicateLecturePage.fulfilled, (state, action) => {
      state.app.showSavingOverlay = false;
      const lecturePage = cloneDeep(action.payload);
      addLecturePageToModel(state, lecturePage);

      if (action.meta.arg.currentCourseIsCollection) {
        const course = state.models.courses[action.meta.arg.catalogId];
        course.lecturePagesCount += 1;

        const collectionFolder = state.models.collectionFolders[lecturePage.folderId];
        collectionFolder.lecturePages = [...without(collectionFolder.lecturePages, 0) ?? [], lecturePage.id];
        collectionFolder.lecturePagesCount += 1;
        state.app.collection.scrollableLessonId = lecturePage.id;

        // Removing the lecture page which has id as Zero if it shows copying loading.
        if (action.meta.arg.isFromLessonsList) {
          delete state.models.lecturePages[0];
          state.app.collection.newLecturePages[lecturePage.folderId] = [lecturePage.id];
        }
      } else {
        updateSectionWithLecturePage(state, lecturePage);
      }
    })
    .addCase(deleteLecturePage.fulfilled, (state, action) => {
      // TODO: Are there other references to delete?
      const lecturePage = state.models.lecturePages[action.meta.arg.lecturePageId];
      if (action.meta.arg.currentCourseIsCollection) {
        const course = state.models.courses[action.meta.arg.catalogId];
        course.lecturePagesCount -= 1;

        const collectionFolder = state.models.collectionFolders[lecturePage.folderId];
        collectionFolder.lecturePagesCount -= 1;
        if (!isEmpty(collectionFolder.lecturePages)) {
          const lpIndex = collectionFolder.lecturePages.indexOf(lecturePage.id);
          collectionFolder.lecturePages.splice(lpIndex, 1);
        }
      } else if (!isEmpty(lecturePage)) {
        /**
         * For deleting lessons, the same Redux action is used in the angular
         * outline. The lecture page data needs to be checked to update Redux after
         * lesson deletion because if the lesson is deleted directly from thelectureSection.lecturePages.splice(lpIndex, 1);
         * outline, Redux will not have the lesson data.
         */
        const lectureSection = state.models.lectureSections[lecturePage.lectureSectionId];
        const lpIndex = lectureSection.lecturePages.indexOf(lecturePage.id);
        lectureSection.lecturePages.splice(lpIndex, 1);

        // If this lecture page contains any exercises which is associated
        // with rating feedback activity in another page delete the association too.
        const lectureComponents = Object.values(state.models.lectureComponents)
          .filter((lectureComponent) => lectureComponent.lecturePageId === action.meta.arg.lecturePageId);
        const exerciseIds = lectureComponents.filter((lectureComponent) => (
          lectureComponent.type === ComponentType.EXERCISE
          && !!lectureComponent.exercise
        )).map((lectureComponent: NLectureComponent<ComponentType.EXERCISE>) => lectureComponent.exercise);
        if (exerciseIds.length > 0) {
          Object.values(state.models.exerciseSkillsRatingActivities).forEach((ratingActivity) => {
            if (exerciseIds.includes(ratingActivity.activity?.id)) {
              ratingActivity.activity = null;
            }
          });
        }
      }

      /**
       * Assigning nextPage and previousPage fields correctly for the lecture
       * pages adjuscent to the lecture page that is going to be deleted
       */
      if (!isEmpty(lecturePage?.nextPage) && state.models.lecturePages[lecturePage?.nextPage?.id]) {
        state.models.lecturePages[lecturePage.nextPage.id].previousPage = lecturePage.previousPage;
      }
      if (!isEmpty(lecturePage?.previousPage) && state.models.lecturePages[lecturePage?.previousPage?.id]) {
        state.models.lecturePages[lecturePage.previousPage.id].nextPage = lecturePage.nextPage;
      }
      delete state.models.lecturePages[action.meta.arg.lecturePageId];
    })
    .addCase(updateLecturePage.pending, (state, action) => {
      Object.assign(state.models.lecturePages[action.meta.arg.lecturePageId], action.meta.arg.lecturePage);
      state.app.lecturePage.preventPageNavigation = PreventNavigationReason.SAVING_IN_PROGRESS;
    })
    .addCase(updateLecturePage.fulfilled, (state, action) => {
      state.app.lecturePage.preventPageNavigation = null;

      const lecturePage = cloneDeep(action.payload);
      addLecturePageToModel(state, lecturePage);
    })
    .addCase(updateLecturePageReleaseDate.pending, (state) => {
      state.app.lecturePage.preventPageNavigation = PreventNavigationReason.SAVING_IN_PROGRESS;
    })
    .addCase(updateLecturePageReleaseDate.fulfilled, (state, action) => {
      state.app.lecturePage.preventPageNavigation = null;

      const data = normalize(action.payload, LecturePageSchema);
      mergeWith(state.models, data.entities, replaceArrays);
    })
    .addCase(unlinkCollectionLesson.fulfilled, (state, action) => {
      each(action.meta.arg.lecturePages, (lp) => {
        if (!isEmpty(state.models.lecturePages[lp.id])) {
          state.models.lecturePages[lp.id].isLinked = false;
          state.models.lecturePages[lp.id].contentManagementCollection = null;
        }
      });
    })
    .addCase(previewLecturePageViewOptions, (state, action) => {
      const lecturePage = getLecturePagefromParam(state, getLecturePageParams(state));
      lecturePage.viewOptions = {
        ...lecturePage.viewOptions,
        ...action.payload.viewOptions,
      };
    })
    .addCase(updateLecturePageEstimates.pending, (state, action) => {
      state.models.lecturePages[action.meta.arg.lecturePageId].estimation = action.meta.arg.estimate;
      state.app.lecturePage.preventPageNavigation = PreventNavigationReason.SAVING_IN_PROGRESS;
    })
    .addCase(updateLecturePageEstimates.fulfilled, (state, action) => {
      state.app.lecturePage.preventPageNavigation = null;
    })
    .addCase(updateLectureComponent.pending, (state, action) => {
      // Setting a "Generating" status to show a special component
      // instead of showing the modal when generating components with AI.
      if (action.meta.arg.componentData?.aiProperties?.aiAction
        && action.meta.arg.componentData.aiProperties.aiAction !== NovoAIItemType.NO_AI) {
        state.app.newGeneratedAIComponent = {
          index: action.meta.arg.componentData.index,
          isNew: false,
        }
      } else if (action.meta.arg.showSavingOverlay) {
        state.app.showSavingOverlay = true;
      }

      state.app.lecturePage.preventPageNavigation = PreventNavigationReason.SAVING_IN_PROGRESS;

      // Eager-update the new values so they're immediately represented.
      // lecture component update requests can take quite a while in some
      // circumstances
      const componentData: LectureComponentUpdateParams['componentData'] = cloneDeep(action.meta.arg.componentData);
      updateLectureComponentData(state, componentData, true);
    })
    .addCase(updateLectureComponent.fulfilled, (state, action) => {
      if (action.meta.arg.componentData.aiProperties?.aiAction !== NovoAIItemType.NO_AI) {
        state.app.newGeneratedAIComponent = null;
      }
      if (!action.meta.arg.commitChanges) {
        const componentData: LectureComponentUpdateParams['componentData'] = cloneDeep(action.payload);
        updateLectureComponentData(state, componentData, false);
      }

      state.app.lecturePage.preventPageNavigation = null;
      state.app.showSavingOverlay = false;
    })
    .addCase(updateLectureComponent.rejected, (state, action) => {
      if (action.meta.arg.componentData.aiProperties?.aiAction !== NovoAIItemType.NO_AI) {
        state.app.newGeneratedAIComponent = null;
      }
      state.app.lecturePage.preventPageNavigation = null;
      state.app.showSavingOverlay = false;
    })
    .addCase(markLecturePageExited, (state, action) => {
      state.app.lecturePage.exitedLecturePages[action.payload] = true;
    })
    .addCase(markLecturePageAsViewed.pending, (state, action) => {
      state.models.lecturePages[action.meta.arg.lecturePageId].viewed = true;
      state.app.lecturePage.locallyViewedLecturePages[action.meta.arg.lecturePageId] = true;
    })
    .addCase(markLecturePageAsViewed.rejected, (state, action) => {
      state.models.lecturePages[action.meta.arg.lecturePageId].viewed = false;
      state.app.lecturePage.locallyViewedLecturePages[action.meta.arg.lecturePageId] = false;
    })
    /** When reordering these components, we are only re-ordering their presentation on the page and not
     * saving the new positions as they're made. Saving only occurrs when you leave the reorder experience.
     * Currently the react lecture page renders components in the order the component IDs appear in in these lectureComponent lists, so we only swap their positions here by using the same order given from
     * the angular app */
    .addCase(moveComponent, (state, action) => {
      (state.models.lecturePages[action.payload.lecturePageId].lectureComponents) = action.payload.componentIds;
    })
    .addCase(setTimelineVisible, (state, action) => {
      state.app.timelineVisible = action.payload;
    })

    .addCase(setLeftPanelVisibility, (state, action) => {
      state.app.lecturePage.isLeftPanelVisible = action.payload;
    })
    .addCase(setTopBarVisibility, (state, action) => {
      state.app.lecturePage.isTopBarVisible = action.payload;
    })
    .addCase(setBottomBarVisibility, (state, action) => {
      state.app.lecturePage.isBottomBarVisible = action.payload;
    })
    .addCase(setLeftPanelView, (state, action) => {
      state.app.lecturePage.leftPanelView = action.payload;
    })
    .addCase(setLectureHeaderIsSaving, (state, action) => {
      state.app.lecturePage.isHeaderSaving = action.payload;
    })
    .addCase(setNewComponentIndex, (state, action) => {
      state.app.lecturePage.newComponentIndex = action.payload;
    })
    .addCase(setLectureAppStateFromRouteParams, (state, action) => {
      state.app.currentCatalogId = action.payload.catalogId;

      // Reset some app state values when switching betwen lecture pages.
      // TODO: It may be best to actually to re-init from initialLecturePageState
      state.app.lecturePage.newComponentIndex = initialLecturePageState.newComponentIndex;
      state.app.lecturePage.currentLectureId = action.payload.lecturePageId;
      state.app.lecturePage.mode = action.payload.mode;
      state.app.lecturePage.isAdminMode = action.payload.isAdminMode;
      state.app.lecturePage.targetActivityId = action.payload.lectureActivityId;
      state.app.lecturePage.preventPageNavigation = null;
    })
    .addCase(unsetcurrentLectureAppState, (state) => {
      state.app.lecturePage.currentLectureId = null;
      state.app.lecturePage.targetActivityId = null;
    })
    .addCase(setLecturePageSyncAsCompleted, (state, action) => {
      const lecturePage = state.models.lecturePages[action.payload];
      lecturePage.syncingStatus = SyncingStatus.COMPLETED;
      lecturePage.hasUpdatedLinks = true;
      each(lecturePage.contentManagementLinks ?? [], ((link) => {
        link.hasUpdatedLink = true;
      }));
    })
    .addCase(addAccordionSection.fulfilled, (state, action) => {
      const accordionComponent = state.models.lectureComponents[
        action.meta.arg.accordionId
      ] as unknown as AccordionLectureComponentData; // TODO: Check this `as unknown`; probably need to rework AccordionLectureComponentData now that we have normalized types
      accordionComponent.accordionSections.push(action.payload);
    })
    .addCase(updateAccordionSection.pending, (state, action) => {
      const accordionComponent = state.models.lectureComponents[
        action.meta.arg.accordionId
      ] as unknown as AccordionLectureComponentData; // TODO: See TODO above

      accordionComponent.accordionSections = accordionComponent.accordionSections.filter((section) => section.id !== action.meta.arg.childLectureComponent.id);
      accordionComponent.accordionSections.splice(action.meta.arg.childLectureComponent.index, 0, action.meta.arg.childLectureComponent);
      accordionComponent.accordionSections = accordionComponent.accordionSections.map((section, index) => ({ ...section, index }));
    })
    .addCase(updateAccordionSection.fulfilled, (state, action) => {
      const accordionComponent = state.models.lectureComponents[
        action.meta.arg.accordionId
      ] as unknown as AccordionLectureComponentData; // TODO: See TODO above

      const index = accordionComponent.accordionSections.findIndex((section) => section.id === action.meta.arg.childLectureComponent.id);
      accordionComponent.accordionSections[index] = action.payload;
    })
    .addCase(deleteAccordionSection.pending, (state, action) => {
      const accordionComponent = state.models.lectureComponents[
        action.meta.arg.accordionId
      ] as unknown as AccordionLectureComponentData; // TODO: See TODO above

      accordionComponent.accordionSections = accordionComponent.accordionSections
        .filter((section) => section.id !== action.meta.arg.childLectureComponentId)
        // fix section indexes
        .map((section, index) => ({ ...section, index }));
    })
    .addCase(setLecturePageAsCached, (state, action) => {
      state.app.lecturePage.cachedLecturePageLookup[action.payload] = true;
    })
    .addCase(unsetLecturePageAsCached, (state, action) => {
      state.app.lecturePage.cachedLecturePageLookup[action.payload] = false;
    })
    .addCase(unsetAllCachedLecturePages, (state, action) => {
      state.app.lecturePage.cachedLecturePageLookup = {};
    })
    .addCase(setTimelineIsExpanded.pending, (state, action) => {
      state.app.lecturePage.leftPanelVisibilityPreference = action.meta.arg;
    })
    .addCase(lecturePageBottomReachedState, (state, action) => {
      state.app.lecturePage.isLecturePageBottomReached = action.payload;
    })
    .addCase(getCourseExercises.fulfilled, (state, action) => {
      state.app.lecturePage.exercises = action.payload.result;
    })
    .addCase(setLecturePageModeIsSwitching, (state, action) => {
      state.app.lecturePage.isModeSwitching = action.payload;
    })
    .addCase(setPreventLecturePageNavigation, (state, action) => {
      state.app.lecturePage.preventPageNavigation = action.payload;
    })
    .addCase(resetTimelineLoadedState, (state) => {
      state.app.lecturePage.isTimelineContentLoaded = false;
    })
    .addCase(linkCollectionLessonsToCourse.fulfilled, (state, action) => {
      each(action.meta.arg.lecturePages, (argLecturePage, index) => {
        const contentLink = find(action.payload, (lp) => argLecturePage.id === lp.sourceId);
        const lecturePage: CombinedLecturePage = {
          ...state.models.lecturePages[contentLink.sourceId],
          lectureComponents: [],
          communications: [],
          isLinked: true,
          syncingStatus: SyncingStatus.LINK_IN_PROGRESS,
          id: contentLink.targetId,
          releaseDate: moment().add(1, 'months').endOf('day').toISOString(),
          course: state.models.courses[contentLink.course.catalogId],
          lectureSectionId: action.meta.arg.lectureSectionId,
          index: argLecturePage.index,
        };

        addLecturePageToModel(state, lecturePage);
        updateSectionWithLecturePage(state, lecturePage);
      });
    })
    .addCase(setDestinationLecturePage, (state, action) => {
      state.app.destinationLecturePage.id = action.payload.lecturePageId;
      state.app.destinationLecturePage.mode = action.payload.mode;
    })
    .addCase(resetDestinationLecturePage, (state) => {
      state.app.destinationLecturePage.id = null;
      state.app.destinationLecturePage.action = null;
      state.app.destinationLecturePage.component = null;
    })
    // Novo AI start
    .addCase(setLeftPanelNovoAIItemType, (state, action) => {
      state.app.lecturePage.novoAI.itemType = action.payload;
      state.app.lecturePage.novoAI.content = null;
    })
    .addCase(setLeftPanelNovoAIContentId, (state, action) => {
      state.app.lecturePage.novoAI.content = action.payload;
    })
    .addCase(resetLeftPanelNovoAI, (state, action) => {
      state.app.lecturePage.novoAI = initialLecturePageState.novoAI;
    })
    .addCase(setLeftPanelNovoAIItemTrueType, (state, action) => {
      state.app.lecturePage.novoAI.itemTrueType = action.payload;
    })
    .addCase(setLeftPanelNovoAILengthAndTone, (state, action) => {
      state.app.lecturePage.novoAI.lengthText = action.payload.lengthText;
      state.app.lecturePage.novoAI.tone = action.payload.tone;
    })
    // Open the lecture page LHS in AI mode
    .addCase(setLeftPanelAiMode, (state, action) => {
      state.app.lecturePage.leftPanelView = 'novo_ai';
      mergeWith(state.app.lecturePage.novoAI, action.payload, replaceArrays);
    });
  // Novo AI start
});
