import { css } from '@emotion/react';
import { AlertMessageProps, AlertMessageType } from 'redux/schemas/app/alert-message';
import {
  primary, success, yellow, danger, warning, gray4,
} from 'styles/global_defaults/colors';
import {
  halfSpacing, quarterSpacing, standardSpacing,
} from 'styles/global_defaults/scaffolding';

const styles = (customColor) => css`
&.alert-message {
  position: absolute;
  background-color: white;
  border-top: ${quarterSpacing}px solid;
  box-shadow: 0 0 ${quarterSpacing}px ${gray4};
  margin-bottom: ${quarterSpacing}px;
  width: 100%;

  &.flash-success {
    border-top-color: ${success};

    .flash-icon {
      color: ${success};
    }
  }

  &.flash-warning, &.flash-info {
    border-top-color: ${yellow};

    .flash-icon {
      color: ${yellow};
    }
  }

  &.flash-error {
    border-top-color: ${danger};

    .flash-icon {
      color: ${danger};
    }
  }

  &.flash-liveEvent {
    border-top-color: ${warning};

    .flash-icon {
      color: ${primary};
    }
  }

  &.flash-custom {
    border-top-color: ${customColor};
    .flash-icon {
      color: ${customColor};
    }
  }

  .flash-content {
    padding: ${standardSpacing}px ${2 * standardSpacing}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .flash-icon {
      margin-right: 45px;
      align-self: center;
      width: 0px;
      text-align:center;
    }

    .flash-header {
      font-weight: bold;
      margin-right: ${quarterSpacing}px;
    }

    .flash-button {
      margin-left: auto;
    }
  }

  .flash-close {
    position: absolute;
    right: ${halfSpacing}px;
    top: ${halfSpacing}px;

    .icon-close {
      color: ${gray4};
      cursor: pointer;
    }
  }
}
`;

const AlertMessage = (props: AlertMessageProps & { removeAlertMessage: Function }) => {
  const {
    header, message, button, type, removeAlertMessage, customColor,
  } = props;

  const getIconClass = () => {
    switch (type) {
      case AlertMessageType.SUCCESS:
        return 'icon-success';
      case AlertMessageType.WARNING:
        return 'icon-warning';
      case AlertMessageType.ERROR:
        return 'icon-error';
      case AlertMessageType.LIVE_EVENT:
        return 'icon-zoom';
      case AlertMessageType.INFO:
        return 'icon-info';
      default:
        return 'icon-info';
    }
  };

  return (
    <div css={styles(customColor)} className={`alert-message flash-${customColor ? 'custom' : type}`}>
      <div className='flash-close'>
        <div className='icon icon-close' onClick={() => removeAlertMessage()} />
      </div>
      <div className='flash-content' role='alert'>
        <div className={`flash-icon icon-medium ${getIconClass()}`} />
        <div className='flash-content-text'>
          {header && (
            <span className='flash-header'>{header}</span>
          )}
          {message && (
            <span className='flash-message'>{message}</span>
          )}
        </div>
        {button && (
          <div className='flash-button'>{button}</div>
        )}
      </div>
    </div>
  );
};

export default AlertMessage;
