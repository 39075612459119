import ClickableContainer from 'components/clickable-container';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { deleteSubmission, toggleFeatureSubmission } from 'redux/actions/video-practice';
import { SubmissionPrivacySetting } from 'redux/schemas/models/video-practice';
import { getCurrentUserId } from 'redux/selectors/timeline';
import { getScenario, getSubmission } from 'redux/selectors/video-practice';
import { useAppDispatch } from 'redux/store';
import NvDropdown, { NvDropdownAlign, NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';

type PracticeSubmissionOptionsProps = {
  submissionId: number;
  loadMentionableUsers: () => void;
};

const PracticeSubmissionOptions = ({ submissionId, loadMentionableUsers }: PracticeSubmissionOptionsProps) => {
  const dispatch = useAppDispatch();
  const scenarioId = useSelector((state) => state.app.practiceRoom.params.scenarioId);
  const practicesubmission = useSelector((state) => getSubmission(state, submissionId));
  const currentUserId = useSelector(getCurrentUserId);
  const {
    activity,
    featured,
    user,
  } = practicesubmission;
  const {
    isPracticeAdmin,
    privacy,
    hasCourseAdmin: adminCanDeleteSubmissions,
  } = useSelector((state) => getScenario(state, scenarioId));

  const [showSettingsOption, setShowSettings] = useState(false);
  const [showUserActions, setShowUserActions] = useState(false);

  const dropdownOptionText = featured
    ? t.PRACTICE_ROOM.SUBMISSION.FEATURE.STOP_FEATURING()
    : t.PRACTICE_ROOM.SUBMISSION.FEATURE.FEATURE_SUBMISSION();

  const toolTipText = privacy === SubmissionPrivacySetting.SHARED
    ? t.PRACTICE_ROOM.TOOLTIP.FEATURE_SUBMISSION.SHARED()
    : t.PRACTICE_ROOM.TOOLTIP.FEATURE_SUBMISSION.PRIVATE();

  /**
   * Can be deleted only if it is not connected to an activity
   * Can be deleted if it is not featured (Admins can delete featured too)
   */
  const canBeDeleted = (adminCanDeleteSubmissions || !featured) && !activity;

  let deleteDisabledTooltip = t.PRACTICE_ROOM.SUBMISSION.DELETE.DISABLED.OTHERS();
  if (adminCanDeleteSubmissions && activity) {
    deleteDisabledTooltip = t.PRACTICE_ROOM.SUBMISSION.DELETE.DISABLED.ACTIVITY_ADMIN();
  } else if (!adminCanDeleteSubmissions && featured) {
    deleteDisabledTooltip = t.PRACTICE_ROOM.SUBMISSION.DELETE.DISABLED.FEATURED();
  }

  const deleteOption: NvDropdownOption = {
    type: 'custom',
    customItem: (
      <ClickableContainer
        className='submission-action-option d-flex text-nowrap px-3 py-1'
        onClick={() => onDelete()}
        disabled={!canBeDeleted}
        aria-label={canBeDeleted ? t.FORM.DELETE() : deleteDisabledTooltip}
      >
        <span
          className={canBeDeleted ? 'text-danger' : 'gray-4'}
        >
          {t.FORM.DELETE()}
        </span>
        {!canBeDeleted && (
          <NvTooltip
            text={deleteDisabledTooltip}
            placement='top'
          >
            <div>
              <NvIcon
                icon='info'
                size='smallest'
                className='text-primary ml-1'
              />
            </div>
          </NvTooltip>
        )}
      </ClickableContainer>
    ),
  };

  const adminDropDownItems: NvDropdownOption[] = [
    {
      type: 'header',
      title: t.PRACTICE_ROOM.SUBMISSION.FEATURE.HEADER(),
      class: 'mx-3',
    },
    {
      type: 'custom',
      customItem: (
        <div className='submission-action-option d-flex text-nowrap px-3 py-1'>
          <ClickableContainer
            onClick={() => dispatch(toggleFeatureSubmission({
              scenarioId,
              submissionId,
              // feature if the submission is not featured or stop featuring
              featured: !featured,
              fullName: user?.fullName,
            })).then(() => {
              loadMentionableUsers();
              setShowSettings(false);
            })}
          >
            {dropdownOptionText}
          </ClickableContainer>
          {/* Display info-icon only for featuring option */}
          {!featured && (
            <NvTooltip text={toolTipText} placement='top'>
              <div>
                <NvIcon
                  icon='info'
                  size='smallest'
                  className='text-primary ml-1'
                />
              </div>
            </NvTooltip>
          )}
        </div>
      ),
    },
  ];

  if (adminCanDeleteSubmissions || currentUserId === user.id) {
    /**
     * This is to handle Mentors. Delete option need to append to admin
     * options only if admin can delete submissions OR it is user's own
     * submission
     */
    adminDropDownItems.push({ type: 'divider' });
    adminDropDownItems.push(deleteOption);
  }

  const onDelete = useCallback(() => {
    dispatch(openConfirmationDialog({
      onConfirm: () => dispatch(deleteSubmission({
        scenarioId,
        submissionId,
      })).then(() => {
        setShowSettings(false);
        setShowUserActions(false);
      }),
      cancelText: t.FORM.CANCEL(),
      confirmText: t.FORM.YES_SURE(),
      title: t.PRACTICE_ROOM.SUBMISSION.DELETE.CONFIRM.TITLE(),
      bodyText: t.PRACTICE_ROOM.SUBMISSION.DELETE.CONFIRM.TEXT(),
    }));
  }, [dispatch, scenarioId, submissionId]);

  return (
    <React.Fragment>
      {isPracticeAdmin && (
        <NvDropdown
          buttonStyle={NvDropdownButtonStyle.ICON}
          items={adminDropDownItems}
          align={NvDropdownAlign.RIGHT}
          iconClass='icon-small icon-settings text-gray-3 ml-2'
          onToggle={() => setShowSettings(!showSettingsOption)}
          show={showSettingsOption}
        />
      )}
      {!isPracticeAdmin && currentUserId === user?.id && (
        <NvDropdown
          buttonStyle={NvDropdownButtonStyle.ICON}
          items={[deleteOption]}
          align={NvDropdownAlign.RIGHT}
          iconClass='icon-smallest icon-edit text-gray-3 ml-2'
          onToggle={() => setShowUserActions(!showUserActions)}
          show={showUserActions}
        />
      )}
    </React.Fragment>
  );
};

export default PracticeSubmissionOptions;
