import store from 'redux/store';
import {
  getLecturePage, setLecturePageSyncAsCompleted, unsetLecturePageAsCached,
} from '../../redux/actions/lecture-pages';
import LecturePageRouter from '../components/lecture-page-routes';
import LecturePageMode from './lecture-content-base-controller';

export default class LecturePageReactController {
  /* @ngInject */
  constructor(
    $scope,
    $stateParams,
    $state,
    ReportsManager,
    DiscussionsManager,
    CurrentCourseManager,
    PusherManager,
    TimedQuizzesManager,
    ReactLecturePageContext,
    humps,
  ) {
    const courseId = CurrentCourseManager.course.id;
    const state = store.getState();
    const isContentManagementCollection = state.models.courses[$stateParams.catalogId]?.isContentManagementCollection;

    if (!isContentManagementCollection) {
      ReportsManager.getApprovalExercises(true);
      DiscussionsManager.initialize({ context: 'lecture', catalogId: $stateParams.catalogId });
      DiscussionsManager.initializePusherEvents(courseId);
      DiscussionsManager.initializeMentions();

      /**
       * This pusher is handling the multiple sessions in timed quiz when starting
       * a quiz. For more Info: https://novoed.atlassian.net/browse/NOV-53292.
       */
      PusherManager.currentUserChannel().bind('timed_quiz_new_window', multipleSessionsHandler);
      PusherManager.courseChannel(courseId).bind('lecture_page_link_completed', linkingCompletionHandler);
    }

    $scope.LecturePageRouter = LecturePageRouter;

    $scope.$on('$destroy', () => {
      if (!isContentManagementCollection) {
        DiscussionsManager.unsubscribeFromPusherEvents(courseId);
        PusherManager.currentUserChannel().unbind('timed_quiz_new_window');

        DiscussionsManager.resetSearchParams();
      }
    });

    function multipleSessionsHandler(pusherData) {
      humps.camelizeKeys(pusherData);

      if (
        pusherData.lecturePageId === $stateParams.lecturePageId
        && pusherData.action === 'activate_window'
        && pusherData.windowId !== TimedQuizzesManager?.currentQuiz?.windowId
      ) {
        ReactLecturePageContext.updateLecturePage();
      }
    }

    function linkingCompletionHandler(pusherData) {
      const data = humps.camelizeKeys(pusherData);

      if (data.lecturePageId === $state.params.id) {
        store.dispatch(getLecturePage({
          catalogId: $stateParams.catalogId,
          lecturePageId: parseInt(data.lecturePageId, 10),
          mode: LecturePageMode.EDIT,
        })).then(() => {
          /**
           * CM-TODO:: This sync status update will not be needed after implementing
           * separate linking statuses for the child and collection parent.
           */
          store.dispatch(setLecturePageSyncAsCompleted(data.lecturePageId));
        });
      } else {
        /**
         * Unseating the lecture page's cache status to get updated data on the
         * next visit to that lecture page.
         */
        store.dispatch(unsetLecturePageAsCached(data.lecturePageId));
      }
    }
  }
}
