/* eslint-disable import/prefer-default-export */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import t from 'react-translate';
import { Result } from 'redux/schemas/api';
import { FetchCourseTimelineParams, fetchCourseTimelineParamsDefault, CourseTimelineResponse } from 'redux/schemas/api/timelines';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { AwardedPoint, TimelineActivity } from 'redux/schemas/models/activity';
import { LecturePage } from 'redux/schemas/models/lecture-page';
import { getCourseTimeLine } from 'redux/selectors/timeline';
import { RootState } from 'redux/schemas';
import { TimelineItemTypes } from 'redux/schemas/models/course';
import { CompletionCriteriaType } from 'redux/schemas/app/timeline';
import { AppTimelineState } from 'redux/reducers/timeline';
import { AutomaticCompletionProgress } from 'redux/schemas/models/courseFull';
import { LectureComponent } from '../schemas/models/lecture-component';
import { addAlertMessage } from './alert-messages';

async function fetchCourseTimeline(params: FetchCourseTimelineParams = fetchCourseTimelineParamsDefault) {
  const response = await axios.get<Result<CourseTimelineResponse>>(`${params.catalogId}/users/${params.userId}/course_timeline.json?lite=${params.outlineOnly}&edit_mode=${params.editMode}`);
  return response.data.result;
}

export const getFullCourseTimelineWithProgress = createAsyncThunk(
  'GET_FULL_COURSE_TIMELINE_WITH_PROGRESS',
  fetchCourseTimeline,
);


export type FetchLecturePageActivitiesParams = {
  catalogId: string,
  lecturePageId: number,
};

/**
 * gets all activities(in lecture component form) for the given lecture page
 * activity = any lecture component that a user can interact with(not display-only like RTE, resources, templates)
 * @param params
 * @returns LectureComponent[]
 */
async function fetchLecturePageActivities(params: FetchLecturePageActivitiesParams) {
  const response = await axios.get<Result<LectureComponent[]>>(`${params.catalogId}/lecture_pages/${params.lecturePageId}/activities.json`);
  return response.data.result;
}

export const getLecturePageActivities = createAsyncThunk(
  'LOAD_LECTURE_PAGE_ACTIVITIES',
  fetchLecturePageActivities,
);

/** This has a generic name but is specifically used to get a new version of the current lecture page after a lecture component is modified. This returns
 * many lecture page fields but I think only the status files like isComplete are relevant
 * Note that we don't handle this via a reducer; instead we pass the response into
 * a getLecturePage action */
export const getCourseLecturePageTimeline = createAsyncThunk(
  'LOAD_LECTURE_PAGE_COURSE_OUTLINE',
  async (params: {
    catalogId: string,
    userId: number,
    lecturePageId: number,
  }, thunkAPI) => {
    const response = await axios.get<Result<LecturePage>>(`${params.catalogId}/users/${params.userId}/course_timeline_lecture_page.json?lecture_page_id=${params.lecturePageId}`);

    // // Manually fire a synchronous getLecturePage fulfilled action to load this response
    // // data into Redux in the same manner as when we normally request a lecture page
    // thunkAPI.dispatch(updateLecturePage.fulfilled(response.data.result, thunkAPI.requestId, {
    //   /* This action only uses this lecturepage param to send lp data up to the backend, and not in the reducer */
    //   lecturePage: null,
    //   catalogId: params.catalogId,
    //   lecturePageId: params.lecturePageId.toString(),
    // }));

    return response.data.result;
  },
);

export const deleteLectureSection = createAsyncThunk(
  'DELETE_LECTURE_SECTION',
  async (params: {
    catalogId: string,
    lectureSectionId: number,
    keepContent: boolean,
  }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.delete(`/${params.catalogId}/lecture_sections/${params.lectureSectionId}?keep_content=${params.keepContent}`);
      return response.data.result;
    } catch (error) {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.OOPS(),
        message: t.FORM.ERROR_SOMETHING_WRONG(),
      }));
      return rejectWithValue(error);
    }
  },
);

export const resetIsTimelineContentLoaded = createAction('RESET_IS_TIMELINE_CONTENT_LOADED');

export const getFullCourseTimelineContent = createAsyncThunk(
  'GET_FULL_COURSE_TIMELINE_CONTENT',
  (params: {
    catalogId: string,
    isFromLhs?: boolean
  }) => axios.get(`/${params.catalogId}/course_outline/content.json`).then((response) => response.data.result),
);

export const getTimelineSectionProgress = createAsyncThunk(
  'GET_TIMELINE_SECTION_PROGRESS',
  (params: { sectionId: number, catalogId: string }) => axios.get(
    `${params.catalogId}/course_outline/progress/${params.sectionId}.json`,
  ).then((response) => response.data.result),
);

export const getTimelineSubsectionSummary = createAsyncThunk(
  'GET_TIMELINE_SUBSECTION_SUMMARY',
  (params: { catalogId: string, subsectionIds: number[] }) => axios.get(
    `${params.catalogId}/course_outline/lecture_subsection_progress.json`,
    { params: {
      lecture_subsections_ids: JSON.stringify(params.subsectionIds),
    } },
  ).then((response) => response.data.result),
);

export const getFullCourseTimelineProgress = createAsyncThunk(
  'GET_FULL_COURSE_TIMELINE_PROGRESS',
  async (params: {
    catalogId: string,
    sectionId?: number,
  }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const timeline = getCourseTimeLine(state);

    const loadSectionData = async (sectionId) => {
      await thunkAPI.dispatch(getTimelineSectionProgress({ catalogId: params.catalogId,
        sectionId,
      }));
    };

    // Load progress of current section if present
    if (params.sectionId) {
      await loadSectionData(params.sectionId);
    }

    const sections = timeline.filter((each) => each.type !== 'page' && (params.sectionId ? each.id !== params.sectionId : true));

    // Load progress of rest of sections one by one
    sections.reduce((acc, section) => new Promise((res) => {
      acc.finally(() => {
        loadSectionData(section.id).finally(res);
      });
    }), Promise.resolve());

    const BATCH_SIZE = 10;

    const subsections = timeline.filter((item) => item.type === TimelineItemTypes.SUBSECTION).map((item) => item.id);

    const batches = subsections.reduce<number[][]>((acc, curr, index) => {
      const batchIndex = Math.trunc(index / BATCH_SIZE);

      acc[batchIndex] = acc[batchIndex] || [];

      acc[batchIndex].push(curr);

      return acc;
    }, []);

    // Start loading summaries of all subsections in batches
    batches.reduce((acc, curr) => new Promise((res) => {
      acc.finally(() => {
        thunkAPI.dispatch(getTimelineSubsectionSummary({
          catalogId: params.catalogId,
          subsectionIds: curr,
        })).finally(res);
      });
    }), Promise.resolve());
  },
);

export const updateTimeline = createAsyncThunk(
  'UPDATE_TIMELINE',
  (lecturePageId: number, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const sectionId = state.models.lecturePages[lecturePageId].lectureSectionId;
    const section = state.models.lectureSections[sectionId];

    if (section.type === 'LectureSubsection') {
      thunkAPI.dispatch(getTimelineSubsectionSummary({
        catalogId,
        subsectionIds: [sectionId],
      }));
    }

    thunkAPI.dispatch(getTimelineSectionProgress({
      catalogId,
      sectionId,
    }));
  },
);

export const getCurrentLecture = createAsyncThunk(
  'GET_CURRENT_LECTURE',
  async (params: { catalogId: string }) => (
    await axios.get<Result<AppTimelineState['currentLecturePage']>>(`${params.catalogId}/users/current_lecture.json`)
  ).data,
);

export const getCourseCompletionProgress = createAsyncThunk<AutomaticCompletionProgress, { catalogId: string }>(
  'GET_COMPLETION_PROGRESS',
  (params: { catalogId: string }) => axios.get(
    `${params.catalogId}/course_outline/progress_towards_completion.json`,
  ).then((response) => response.data.result),
);

export const togglePortableCompletionPanel = createAction('TOGGLE_PORTABLE_COMPLETION_PANEL');

export const resetShowPortableCompletionPanel = createAction('RESET_SHOW_PORTABLE_COMPLETION_PANEL');

export const selectPortableCompletionPanelTab = createAction<CompletionCriteriaType>('SELECT_PORTABLE_COMPLETION_TAB');

export const resetPortableCompletionPanelData = createAction<string>('RESET_PORTABLE_COMPLETION_PANEL_DATA');

export const getTimelineTodosList = createAsyncThunk<
{ perPage: number, todos: TimelineActivity[] },
{ catalogId: string, page: number, onlyForCompletion?: boolean }
>(
  'GET_TIMELINE_TODOS_LIST',
  (params: { catalogId: string, page: number, onlyForCompletion: boolean }) => axios.get(
    `${params.catalogId}/course_outline/todos.json?page=${params.page}${params.onlyForCompletion ? '&only=required_for_completion' : ''}`,
  ).then((response) => response.data.result),
);

export const getTimelineAssignmentsList = createAsyncThunk<{ perPage: number, todos: TimelineActivity[] }, { catalogId: string, page: number }>(
  'GET_TIMELINE_ASSIGNMENTS_LIST',
  (params: { catalogId: string, page: number }) => axios.get(
    `${params.catalogId}/course_outline/exercises_progress.json?page=${params.page}`,
  ).then((response) => response.data.result),
);

export const getTimelinePointsList = createAsyncThunk<{ perPage: number, todos: TimelineActivity[] }, { catalogId: string, page: number }>(
  'GET_TIMELINE_POINTS_LIST',
  (params: { catalogId: string, page: number }) => axios.get(
    `${params.catalogId}/course_outline/received_points.json?page=${params.page}`,
  ).then((response) => response.data.result),
);

export const getAwardedPoints = createAsyncThunk<AwardedPoint[], { catalogId: string, userId: number }>(
  'GET_AWARDED_POINTS',
  (params: { catalogId: string, userId: number }) => axios.get(
    `/${params.catalogId}/users/awarded_points.json?viewee_id=${params.userId}`,
  ).then((response) => response.data.result),
);

