import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AngularServicesContext } from 'react-app';
import t from 'react-translate';
import { ProgressBar } from 'react-bootstrap';
import { isEmpty } from 'underscore';

// Styles
import { gray5, gray7, primary } from 'styles/global_defaults/colors';
import { halfSpacing, quarterSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';

// Redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import { updateLecturePage } from 'redux/actions/lecture-pages';
import { closeConfirmationDialog, openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { getLinkedCourses } from 'redux/selectors/collections';
import { SyncingStatus } from 'redux/schemas/models/lecture-page';
import { setHighlightedLessonId, setScrollableCollectionLessonId, syncLecturePage } from 'redux/actions/collections';
import { useAppDispatch } from 'redux/store';

// Components
import ClickableContainer from 'components/clickable-container';
import NvTooltip, { TextAlign } from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import NvFilePicker from 'shared/components/nv-filepicker';
import NvClickToEdit, { InputType } from 'components/nv-click-to-edit';
import moment from 'moment';
import useUploadFile from 'shared/hooks/use-upload-file';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import { css } from '@emotion/core';
import { getAutoGenerationInProgress } from 'redux/selectors/lecture-components';
import LinkedCourseModal from './linked-course-modal';
import LessonActionDropdown from '../lecture-page/lesson-action-dropdown';
import { config } from '../../../../config/config.json';
import { getTableColumnsWidth } from './folder-lecture-list';

const lessonRowSyles = (columnsWidth) => css`
  border-bottom: 0.5px dashed ${gray5};

  &:hover {
    box-shadow: 0px 0px 10px rgba(29, 33, 38, 0.2);
    background-color: ${gray7};
  }

  &.drag-lesson {
    &.lesson-row {
      border: 2px solid ${primary};
    }
  }

  .lesson-img {
    min-width: 85px;
    height: ${tripleSpacing}px;
  }

  .name-with-desc {
    width: calc(100% - 85px); // 85 - lesson image width

    .content-container {
      background: none;
    }

    .description-text {
      .ellipsis {
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: pre-wrap;
      }
    }

    .description-text.no-display{
      display: none;
    }

    input {
      border: none;
      background: ${gray7};
      padding: 0;
    }
  }

  .title {
    padding: ${halfSpacing}px 54px;
    width: ${columnsWidth.title}
  }
  .links {
    width: ${columnsWidth.links}
  }
  .editor {
    width: ${columnsWidth.editor}
  }
  .edited-on {
    width: ${columnsWidth.editedOn}
  }
  .status {
    width: ${columnsWidth.status}
  }
  .options {
    width: ${columnsWidth.options};
  }
`;
const progressBarStyle = css`
  border-radius: 0px;
  height: ${quarterSpacing}px;
  width: 65px:
`;

const LessonRow = (rowProps) => {
  const {
    data: lessonId,
    canShowFullList,
    isDragging,
    folderId,
    setIsLessonDragging,
  } = rowProps;
  const [showLinkedCourseModel, setShowLinkedCourseModel] = useState<boolean>(false);
  const [isPushModalOpenedBackground, setIsPushModalOpenedBackground] = useState<boolean>(false);
  const [isSavingDescription, setIsSavingDescription] = useState<boolean>(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const lesson = useSelector((state: RootState) => state.models.lecturePages[lessonId]);
  const [cardImageUrl, setCardImageUrl] = useState(lesson?.cardViewImageUrl);
  const { uploadFiles, isUploading, filesUploading, hasUploadPercentage } = useUploadFile();
  const [cardImageLoading, setCardImageLoading] = useState(false);
  let fileUploading;

  /* 50MB as max card image file size(Represented in bytes). */
  const MAX_CARD_IMAGE_SIZE = 52428800;

  useEffect(() => {
    setCardImageUrl(lesson?.cardViewImageUrl);
  }, [lesson?.cardViewImageUrl, cardImageUrl]);

  const dispatch = useAppDispatch();
  const params = useLecturePageParams();
  const lessonRef = useRef<HTMLDivElement>(null);

  const { $state, CurrentPermissionsManager } = useContext(AngularServicesContext);
  const highlightedLessonId: number = useSelector((state: RootState) => state.app.collection.highlightedLessonId);
  const scrollableLessonId: number = useSelector((state: RootState) => state.app.collection.scrollableLessonId);

  const isHighlighted = highlightedLessonId === lesson.id;

  const linkedCourses = useSelector((state: RootState) => getLinkedCourses(state, lesson.id));
  const isPushChangesEnabled = !lesson.hasUpdatedLinks;
  const disabled = lesson?.syncingStatus === SyncingStatus.SYNC_IN_PROGRESS;
  const hasEditPermission = CurrentPermissionsManager.hasCollectionBuilderPermissions();
  const isAutoGenerationInProgress = useSelector((state: RootState) => getAutoGenerationInProgress(state, lesson.id));

  useEffect(() => {
    if (isHighlighted
      && ($state.params?.lessonId === lesson.id || lesson.id === scrollableLessonId)
      && lessonRef?.current) {
      scrollToRef(lessonRef);
    }
    return () => {
      if (isHighlighted) {
        dispatch(setHighlightedLessonId(null));
        dispatch(setScrollableCollectionLessonId(null));
      }
    };
  }, [$state.params?.lessonId, dispatch, isHighlighted, lesson.id, scrollableLessonId]);

  const scrollToRef = (ref) => {
    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  };

  const onImagePicked = useCallback(async (files: File[]) => {
    setCardImageLoading(true);
    const [novoEdFile] = await uploadFiles(files, S3NameSpaces.CARD_VIEW_IMAGES);
    dispatch(updateLecturePage({
      ...params,
      lecturePageId: lesson.id,
      lecturePage: {
        cardViewImageFileName: novoEdFile.name,
        cardViewImageFileSize: novoEdFile.size,
        cardViewImageContentType: novoEdFile.type,
        cardViewImageUniqueId: novoEdFile.uniqueId,
      },
    })).then((res) => {
      setCardImageUrl(res.payload.cardViewImageUrl);
      setCardImageLoading(false);
    });
  }, [dispatch, lesson.id, params, uploadFiles]);

  const openLinkedCoursesModal = () => {
    if (linkedCourses.length > 0) {
      setShowLinkedCourseModel(true);
      setIsPushModalOpenedBackground(true);
      dispatch(closeConfirmationDialog());
    }
  };
  const closeLinkedCourseModel = (): void => {
    setShowLinkedCourseModel(false);
    if (isPushModalOpenedBackground) {
      onPushChanges();
      setIsPushModalOpenedBackground(false);
    }
  };

  const doSyncLecturePage = () => {
    dispatch(syncLecturePage({ lecturePageId: lesson.id })).then(() => {
      dispatch(addAlertMessage({
        type: AlertMessageType.INFO,
        header: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.PUSH_CHANGES.PUSHING(),
        message: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.PUSH_CHANGES.SUCCESS(),
      }));
    });
  };

  const onPushChanges = () => {
    const bodyText: JSX.Element = (
      <div className='text-large-body'>
        <div>
          {t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.PUSH_CHANGES.CONFIRMATION.DESCRIPTION()}
        </div>
        <div className='d-flex justify-content-center'>
          <ClickableContainer
            onClick={openLinkedCoursesModal}
          >
            <u>{t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.PUSH_CHANGES.CONFIRMATION.CHECK_LINKED_LESSONS()}</u>
          </ClickableContainer>
        </div>
      </div>
    );
    dispatch(openConfirmationDialog({
      title: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.PUSH_CHANGES.CONFIRMATION.TITLE(),
      bodyText,
      cancelText: t.FORM.CANCEL(),
      confirmText: t.FORM.YES_SURE(),
      onConfirm: () => doSyncLecturePage(),
    }));
  };

  /** Saves the description text change on input focus out */
  const handleDescriptionChange = useCallback((description: string) => {
    // Do not update if the value hasn't changed
    if (description === lesson.description) {
      return;
    }
    setIsSavingDescription(true);

    dispatch(updateLecturePage({
      ...params,
      lecturePageId: lesson.id,
      lecturePage: {
        description,
      },
    })).then((res) => {
      setIsSavingDescription(false);
    });
  }, [dispatch, lesson.description, lesson.id, params]);

  const onOptionsToggle = (isOpen: boolean) => setIsOptionsOpen(isOpen);

  /**
   * Using the element's height and its contents' line height to check the
   * title has multiple lines.
   *
   * Title is used the 'text-regular' class, which has line-height of 20.
   * So considering the title is multiline if the element height is greater
   * than the line height (20).
   */
  const isMultiLineTitle = (className) => {
    const element: HTMLElement = document.querySelector(`.${className}`);
    return element?.offsetHeight > 20;
  };

  const goToLecturePage = () => {
    $state.go('lecture-page', { catalogId: params.catalogId, id: lesson.id });
  };

  const isLessonDescriptionBlank = isEmpty(lesson.description?.trim());
  const isEditable = !disabled && canShowFullList;
  const isEditableAddTextClass = isEditable || isLessonDescriptionBlank
    ? t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.ADD_DESCRIPTION()
    : lesson.description;

  return (
    <React.Fragment>
      <div
        className={`lesson-row d-flex w-100 align-items-center ${disabled ? 'disabled bg-gray-6 text-gray-1' : ''}  ${isHighlighted ? 'highlight' : ''}  ${isOptionsOpen ? 'options-open' : ''}`}
        css={lessonRowSyles(getTableColumnsWidth(canShowFullList))}
        ref={lessonRef}
        id={lesson.id}
      >
        <div className='title d-flex align-items-center'>
          <NvTooltip text={t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.DRAG_AND_DROP()} enabled={!isDragging && hasEditPermission}>
            <div
              className='pr-2 reorder-lesson-icon drag'
              onMouseDown={() => setIsLessonDragging(true)}
            >
              <NvIcon
                className={`${(isDragging || !hasEditPermission) ? 'invisible' : ''}`}
                icon='reorder'
                size='xs-smallest'
              />
            </div>
          </NvTooltip>
          <NvTooltip
            text={t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.CARD_IMAGE_TOOLTIP()}
            placement='right-start'
            enabled={isEditable && !isDragging}
          >
            <NvFilePicker
              accept={['image/*']}
              onChange={(files) => onImagePicked(files)}
              multiple={false}
              maxSize={MAX_CARD_IMAGE_SIZE}
              disabled={disabled || !canShowFullList}
            >
              {cardImageUrl && !cardImageLoading ? (
                <img className='lesson-img' alt='lesson card' src={cardImageUrl} />
              ) : (
                <div className='lesson-img d-flex align-items-center justify-content-center bg-gray-3'>
                  {cardImageLoading ? (
                    <React.Fragment>
                      {Object.keys(filesUploading).map((key) => {
                        fileUploading = filesUploading[key];
                        let uploadPct = 100;
                        if (hasUploadPercentage && isUploading) {
                          uploadPct = fileUploading.uploadPercentage;
                        }

                        return (
                          <div key={key} className='w-75'>
                            <ProgressBar css={progressBarStyle} now={uploadPct} />
                          </div>
                        );
                      })}
                    </React.Fragment>
                  ) : (
                    <div className='bg-gray-6 lesson-img d-flex align-items-center justify-content-center'>
                      <NvIcon icon='images' size='smallest' className='text-gray-3' />
                    </div>
                  )}
                </div>
              )}
            </NvFilePicker>
          </NvTooltip>
          <NvTooltip
            enabled={disabled}
            text={t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.DISABLE_ROW_TOOLTIP(lesson.title)}
            placement='top-start'
          >
            <div className='name-with-desc d-flex flex-column px-4'>
              <ClickableContainer
                className={`text-regular bold text-black cursor-pointer ${disabled && 'text-gray-3'}`}
                onClick={goToLecturePage}
                disabled={disabled || !CurrentPermissionsManager.hasCollectionViewerPermissions()}
                isFocusable={!disabled}
                data-qa={config.pendo.contentManagement.openCollectionLesson}
                data-qa-id={`${config.pendo.contentManagement.openCollectionLesson}_${lesson.id}`}
              >
                <span className={`title-${lesson.id} two-line-ellipsis`}>{lesson.title}</span>
              </ClickableContainer>
              <NvClickToEdit
                className={`description-text ${(!isEditable && isLessonDescriptionBlank) && 'no-display'}`}
                text={isEditableAddTextClass}
                value={lesson.description?.trim()}
                type={InputType.SHORT_TEXT}
                tooltipText={!isDragging && isEditable ? t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.DESCRIPTION_TOOLTIP() : ''}
                replaceText
                onChange={(newTitle) => handleDescriptionChange(newTitle)}
                editable={isEditable}
                maxLength={255}
                isSaving={isSavingDescription}
                textContainerClassName={`${isMultiLineTitle(`title-${lesson.id}`) ? 'ellipsis' : 'two-line-ellipsis'} ${disabled ? 'text-gray-3' : ''} font-weight-light`}
                tooltipPlacement='top-start'
                tooltipTextAlign={TextAlign.LEFT}
              />
            </div>
          </NvTooltip>
        </div>
        <ClickableContainer
          disabled={disabled || linkedCourses?.length === 0}
          isFocusable={!disabled && linkedCourses?.length > 0}
          onClick={() => setShowLinkedCourseModel(true)}
          className={`links pr-7 justify-content-end text-regular text-${linkedCourses?.length > 0 ? 'primary' : 'gray-3'}`}
          data-qa={linkedCourses?.length > 0 ? config.pendo.contentManagement.collectionHomeLinkedLessonCount : ''}
          data-qa-id={linkedCourses?.length > 0 ? `${config.pendo.contentManagement.collectionHomeLinkedLessonCount}_${lesson.id}` : ''}
        >
          <NvTooltip
            enabled={!disabled && linkedCourses?.length > 0}
            text={t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.VIEW_LINKED_LESSON()}
          >
            <span>
              {linkedCourses?.length.toString().length > 8
                ? '>99999999' : linkedCourses?.length}
            </span>
          </NvTooltip>
        </ClickableContainer>

        <div className='editor pr-5'>
          <span className='two-line-ellipsis text-regular text-gray-1'>
            {`${lesson.lastEditor?.firstName ?? ''} ${lesson.lastEditor?.lastName ?? ''}`}
          </span>
        </div>
        <div className='edited-on pr-5 text-regular text-gray-1'>
          {moment(lesson.updatedAt).format('MM/DD/YYYY h:mm A')}
        </div>
        {canShowFullList && (
          <React.Fragment>
            <div className='status text-regular'>
              {linkedCourses.length > 0 && !isPushChangesEnabled && (
                <span className='text-gray-1'>
                  {t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.STATUSES.UP_TO_DATE()}
                </span>
              )}
              {lesson && linkedCourses?.length === 0 && (
                <span className='text-gray-1'>
                  {t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.STATUSES.NO_LINKS()}
                </span>
              )}
              {linkedCourses.length > 0 && isPushChangesEnabled && (
                <NvTooltip
                  text={t.LECTURE_VIDEO.AUTO_GENERATED_TRANSCRIPT_AND_CAPTIONS.IS_IN_PROGRESS()}
                  enabled={isAutoGenerationInProgress}
                  placement='bottom'
                >
                  <ClickableContainer
                    className={`text-${(isAutoGenerationInProgress || disabled) ? 'gray-3' : 'primary'}`}
                    onClick={onPushChanges}
                    disabled={isAutoGenerationInProgress || disabled}
                    isFocusable={!(isAutoGenerationInProgress || disabled)}
                    data-qa={!(isAutoGenerationInProgress || disabled) ? config.pendo.contentManagement.pushChanges : ''}
                    data-qa-id={!(isAutoGenerationInProgress || disabled) ? `${config.pendo.contentManagement.pushChanges}_${lesson.id}` : ''}
                  >
                    {lesson.syncingStatus === SyncingStatus.SYNC_IN_PROGRESS
                      ? t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.PUSH_CHANGES.PUSHING()
                      : t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.STATUSES.PUSH_CHANGES()}
                  </ClickableContainer>
                </NvTooltip>
              )}
            </div>
            <div className='options align-items-center justify-content-center h-100'>
              <LessonActionDropdown
                lessonId={lesson.id}
                iconClass='icon-smallest icon-more d-flex align-items-center justify-content-center h-100 w-100'
                onMenuToggle={onOptionsToggle}
                setLectureData={rowProps.setLectureData}
                folderId={folderId}
              />
            </div>
          </React.Fragment>
        )}
        {lesson.id === 0 && (
          <div className='position-absolute d-flex justify-content-center w-100 text-warning'>
            {t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.COPYING()}
          </div>
        )}
      </div>
      <LinkedCourseModal
        lessonId={lesson.id}
        showLinkedCourseModel={showLinkedCourseModel}
        closeLinkedCourseModel={closeLinkedCourseModel}
      />
    </React.Fragment>
  );
};

export default LessonRow;
