import React, { useEffect, useState } from 'react';
import { NvTab, NvResponsiveTabsDisplayType } from 'shared/components/nv-responsive-tabs';
import { NvResponsiveTabsRow } from 'shared/components/nv-responsive-tabs-row';
import { createGridStyles } from 'styles/global_defaults/scaffolding';
import t from 'react-translate';
import { textSmallFontSize } from 'styles/global_defaults/fonts';
import { css } from '@emotion/react';
import { RailsRoutes } from 'shared/services/rails-routes';
import { AngularServicesContext } from 'react-app';
import { white } from 'styles/global_defaults/colors';
import { AnalyticsDashBoardType } from 'redux/schemas/app/analytics';
import { BrowserRouter, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AnalyticsDashboard from './analytics-dashboard';

const CourseAnalytics = () => {
  const { cohort } = useSelector((state) => state.app.qrveyConfig);
  const showCohorts = cohort;
  const skillTags = Object.values(useSelector((state) => state.models.institutions[state.app.currentInstitutionId].skillTags));
  const institutionHasSkilltags = skillTags?.length > 0;
  const history = useHistory();
  const location = useLocation();
  let currentActiveTab = 0;

  const sections = [
    {
      url: '/skills',
      activeId: 1,
      display: institutionHasSkilltags,
    },
    {
      url: '/activities',
      activeId: 2,
      display: true,
    },
    {
      url: '/cohorts',
      activeId: 3,
      display: true,
    },
  ];
  // set the default selected tab based on the url.
  sections.forEach((section) => {
    if (location?.hash?.endsWith(section.url) && section.display) {
      currentActiveTab = section.activeId;
    }
  });
  const [activeTab, setActiveTab] = useState(currentActiveTab);
  const { $state } = React.useContext(AngularServicesContext);
  const { catalogId } = $state?.params;
  const baseURL = `/#!/courses/${catalogId}/analytics`;
  const selectTab = (id) => {
    setActiveTab(id);
    // changing history so reloading will mark the right tab
    if (id === 0) {
      history.push(baseURL);
    }
    if (id === 1) {
      history.push(`${baseURL}/activities`);
    }
    if (id === 2 && institutionHasSkilltags) {
      history.push(`${baseURL}/skills`);
    }
    if (id === 3) {
      history.push(`${baseURL}/cohorts`);
    }
  };



  const tabs: NvTab[] = [
    {
      text: t.COURSE_ANALYTICS.OVERVIEW(),
      onClick: () => selectTab(0),
    }, {
      text: t.COURSE_ANALYTICS.ACTIVITIES(),
      onClick: () => selectTab(1),
    },
  ];
  if (institutionHasSkilltags) {
    tabs.push({
      text: t.COURSE_ANALYTICS.SKILLS(),
      onClick: () => selectTab(2),
    });
  }
  if (showCohorts) {
    tabs.push({
      text: t.COURSE_ANALYTICS.COHORTS(),
      onClick: () => selectTab(3),
    });
  }

  const tabStyles = {
    ...createGridStyles(1, 2),
    fontSize: `${textSmallFontSize}px`,
  };
  const styles = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: calc(((100vw - 1024px)/2) - 10px);
    background-color: ${white};
  `;
  return (
    <>
      <div css={styles} className='analytics-header'>
        <NvResponsiveTabsRow
          style={tabStyles}
          defaultTabs={tabs}
          tabType={NvResponsiveTabsDisplayType.TEXT_ONLY}
          tabTextClass='card-title'
          selectedTabIndex={activeTab}
        />
        <div className='button-container'>
          <a
            className='bs4-btn bs4-btn-secondary w-100'
            href={RailsRoutes.analyticsPath(catalogId)}
            data-qa='analytics-link-1'
          >
            {t.COURSE_ANALYTICS.SURVEY_AND_QUIZ()}
          </a>
        </div>
      </div>

      {/* This to conditionals are neccesary to rerender the qrvey instance on click of the tabs */}
      {activeTab === 0
      && <AnalyticsDashboard dashboardType={AnalyticsDashBoardType.COURSE} configKeyName='config_overview' pageIdKey='overview' /> }
      {activeTab === 1
      && <AnalyticsDashboard dashboardType={AnalyticsDashBoardType.COURSE} configKeyName='config_activities' pageIdKey='activities' />}
      {activeTab === 2
      && <AnalyticsDashboard dashboardType={AnalyticsDashBoardType.COURSE} configKeyName='config_skills' pageIdKey='skills' />}
      {activeTab === 3
      && <AnalyticsDashboard dashboardType={AnalyticsDashBoardType.COHORTS} configKeyName='config_cohorts' pageIdKey='cohorts' />}
    </>

  );
};
export default CourseAnalytics;

export const CourseAnalyticsRoute = () => <BrowserRouter forceRefresh><CourseAnalytics /></BrowserRouter>;
