import t from 'react-translate';
import { DeepPartial } from 'utility-types';
import { createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';

import {
  resetLeftPanelNovoAI,
  setBottomBarVisibility,
  setLeftPanelView,
  updateLectureComponent,
} from 'redux/actions/lecture-pages';
import {
  ComponentTrueType,
  LectureComponent,
  TypeFromTrueType,
} from 'redux/schemas/models/lecture-component';
import { LectureComponentAIPayload, NovoAIPayload } from 'redux/schemas/models/activity';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';

import getComponentMetadata from 'lecture_pages/components/data';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import createNewComponent from '../../create-new-component';

export const AIComponentsContext = createContext(null);

type CreateAIComponentProps<C extends ComponentTrueType> = {
  itemTrueType?: ComponentTrueType,
  aiProps?: LectureComponentAIPayload<TypeFromTrueType<C>>,
  modelData?: DeepPartial<LectureComponent>,
};

export const AIComponentsContextProvider = ({
  children,
  ...props
}) => (
  <AIComponentsContext.Provider value={{ ...props }}>
    {children}
  </AIComponentsContext.Provider>
);

const useAIComponentsGenerator = () => {
  const params = useLecturePageParams();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const novoAIProperties = useSelector(state => state.app.lecturePage.novoAI);
  const newComponentIndex = useSelector(state => state.app.lecturePage.newComponentIndex);

  const { itemType, content, itemTrueType: defaultType, lectureComponentToReplace } = novoAIProperties;
  const componentIndex = lectureComponentToReplace ? lectureComponentToReplace.index : newComponentIndex;

  const createAIComponent = ({
    itemTrueType = defaultType,
    aiProps,
    modelData,
  }: CreateAIComponentProps<ComponentTrueType>) => {
    const metadata = getComponentMetadata(itemTrueType);

    const aiProperties: NovoAIPayload<TypeFromTrueType<ComponentTrueType>> = {
      aiAction: itemType,
      aiOriginTarget: {
        id: content.id,
        type: content.type,
      },
      ...aiProps,
    };

    const componentData = { aiProperties, ...modelData };
    dispatch(setLeftPanelView('outline'));
    dispatch(setBottomBarVisibility(true));
    dispatch(resetLeftPanelNovoAI());

    if (lectureComponentToReplace) {
      const componentDataUpdate = {
        catalogId: params.catalogId,
        lecturePageId: params.lecturePageId,
        componentData: {
          ...componentData,
          id: lectureComponentToReplace.id,
          type: lectureComponentToReplace.type,
          index: lectureComponentToReplace.index,
          viewOptions: lectureComponentToReplace.viewOptions,
        },
      };
      return dispatch(updateLectureComponent(componentDataUpdate));
    }

    return handleAIComponentCreation(
      {
        componentIndex,
        catalogId: params.catalogId,
        lecturePageId: params.lecturePageId,
        itemTrueType,
        metadata,
        dispatch,
        history,
        componentData,
      },
    );
  };

  const handleAIComponentCreation = async (props) => {
    createNewComponent(
      props.componentIndex,
      props.catalogId,
      props.lecturePageId,
      props.itemTrueType,
      props.metadata,
      props.dispatch,
      props.history,
      props.componentData,
    ).then(() => {
      dispatch(
        addAlertMessage({
          type: AlertMessageType.SUCCESS,
          header: t.LECTURE_PAGES.LHS.NOVO_AI.ALERT.SUCCESS.HEADER(),
          message: t.LECTURE_PAGES.LHS.NOVO_AI.ALERT.SUCCESS.CONTENT(),
        }),
      );
    }).catch(() => {
      dispatch(
        addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.FORM.ERROR_SOMETHING_WRONG(),
        }),
      );
    });
  };

  return {
    createAIComponent,
  };
};

export default useAIComponentsGenerator;
