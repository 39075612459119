import t from 'react-translate';
import { css } from '@emotion/react';
import React, { useContext, useEffect, useState } from 'react';
import { AngularServicesContext } from 'react-app';
import PusherService from 'shared/services/pusher-service';
import humps from 'humps';
import { useParams } from 'react-router-dom';

// Styles
import { gray2, gray5, primary, warning, white } from 'styles/global_defaults/colors';
import { doubleSpacing, largeSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

// Redux
import { RootState } from 'redux/schemas';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { fetchFolder, reorderLesson } from 'redux/actions/collections';
import { setLecturePageSyncAsCompleted } from 'redux/actions/lecture-pages';

// Components
import LoadingPlaceholder from 'shared/components/loading-placeholder';
import { LecturePageSyncEvent } from 'lecture_pages/components';
import { Container, Draggable } from 'react-smooth-dnd';
import { isEmpty } from 'underscore';
import { CollectionHomeContext } from './collection-home';

import LessonRow from './lesson-row';

interface FolderLectureListProps {
  folderId: number;
}

export const getTableColumnsWidth = (fullList) => (
  fullList ? {
    title: '48%',
    links: '10%',
    editor: '14%',
    editedOn: '14%',
    status: '10%',
    options: '4%',
  } : {
    title: '50%',
    links: '15%',
    editor: '15%',
    editedOn: '20%',
  });

export const folderLessonSortKeys = ['title'];

const FolderLectureList = (props: FolderLectureListProps) => {
  const styles = css`
    .smooth-dnd-container {
      .smooth-dnd-draggable-wrapper {
        display: grid;
        overflow: visible;
      }
    }
    .title-cell {
      padding-left: 78px;
    }
    .smooth-dnd-draggable-wrapper:last-child {
      .lesson-row {
        border-bottom: 0.5px solid ${gray5};
      }
    }
    .smooth-dnd-draggable-wrapper {
      &.smooth-dnd-ghost {
        .lesson-row {
          border: 2px solid ${primary};
        }
      }
    }

    .options {
        display: none;
      }

      .lesson-row:not(.disabled) {
        &:hover, &.options-open {
          .options {
            display: flex;
            background-color: ${gray5};
            .icon {
              color: ${gray2}
            }
          }
        }

        .options {
          &:hover {
            background-color: ${primary};
            .icon {
              color: ${white}
            }
          }

          .nv-dropdown {
            height: 100%;
            width: 100%;

            .bs4-dropdown {
              height: 100%;
            }
          }
        }
        &.highlight {
          box-shadow: inset ${quarterSpacing}px 0px ${warning};
          background-color: ${white}
        }
      }
      .reorder-lesson-icon {
        opacity: 0;
      }
      .lesson-row:hover .reorder-lesson-icon {
        opacity: 1;
        }
  `;

  const noResultStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${doubleSpacing}px;

    icon:not(.icon-smallest) {
    font-size: ${largeSpacing}px;
    margin-bottom: ${standardSpacing}px;
    }
  `;

  const { CurrentPermissionsManager } = useContext(AngularServicesContext);
  const { collectionId } = useParams<{ collectionId: string, catalogId: string }>();
  const dispatch = useAppDispatch();

  const folderLessonIds = useSelector((state: RootState) => state.models.collectionFolders[props.folderId].lecturePages);
  const [lectureData, setLectureData] = useState([]);
  const [loadingLessons, setLoadingLessons] = useState(false);
  const [isLessonEntered, setIsLessonEntered] = useState(false);
  const {
    setReorderLessonData,
    reorderLessonData,
    isDragging,
    setIsDragging,
    setIsLessonDragging,
  } = useContext(CollectionHomeContext);

  const isCollectionBuilder = CurrentPermissionsManager.hasCollectionBuilderPermissions();
  const tableColumnsWidth = getTableColumnsWidth(isCollectionBuilder);

  const tableColumns = [
    {
      name: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.LESSONS(),
      className: 'title-cell text-gray-2 text-small py-2 pr-4 m-auto',
      columnWidth: tableColumnsWidth.title,
    },
    {
      name: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.LINKS(),
      className: 'links-cell py-2 justify-content-end text-gray-2 text-small text-right pr-7 m-auto',
      columnWidth: tableColumnsWidth.links,
    },
    {
      name: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.EDITOR(),
      className: 'editor-cell py-2 text-gray-2 text-small m-auto',
      columnWidth: tableColumnsWidth.editor,
    },
    {
      name: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.EDITED_ON(),
      className: 'edited-on-cell py-2 text-gray-2 text-small m-auto',
      columnWidth: tableColumnsWidth.editedOn,
    },
  ];

  if (isCollectionBuilder) {
    tableColumns.push(
      {
        name: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.STATUS(),
        className: 'status-cell py-2 pr-4 text-gray-2 text-small m-auto',
        columnWidth: tableColumnsWidth.status,
      },
      {
        name: '',
        className: 'options-cell',
        columnWidth: tableColumnsWidth.options,
      },
    );
  }

  useEffect(() => {
    const pusherChannel = PusherService.initializeCourseChannel(collectionId);
    pusherChannel.bind('lecture_page_sync_completed', (data) => {
      const pusherData = humps.camelizeKeys(data) as unknown as LecturePageSyncEvent;

      dispatch(setLecturePageSyncAsCompleted(pusherData.lecturePageId));
    });

    return () => {
      pusherChannel.unbind('lecture_page_sync_completed');
    };
  }, [collectionId, dispatch]);

  useEffect(() => {
    setLoadingLessons(true);
    dispatch(fetchFolder({
      folderId: props.folderId,
      pageIndex: 0,
    })).then((res) => {
      const LectureIds = res.payload.response.map((eachLesson) => eachLesson.id);
      setLectureData([...LectureIds]);
      setLoadingLessons(false);
    });
  }, [dispatch, props.folderId]);

  // useEffect to roleback the reorder changes when request failed
  useEffect(() => {
    if (reorderLessonData.roleBackSourceFolder && reorderLessonData.sourceFolderId === props.folderId) {
      setLectureData([...folderLessonIds]);
      setReorderLessonData({ ...reorderLessonData,
        sourceFolderId: null,
        roleBackSourceFolder: false });
    } else if (reorderLessonData.roleBackDestinationFolder && reorderLessonData.destinationFolderId === props.folderId) {
      setLectureData([...folderLessonIds]);
      setReorderLessonData({ ...reorderLessonData,
        destinationFolderId: null,
        roleBackDestinationFolder: false });
    }
  }, [folderLessonIds,
    props.folderId,
    reorderLessonData,
    reorderLessonData.roleBackSourceFolder,
    reorderLessonData.sourceFolderId,
    setReorderLessonData]);


  const NoResultsTextComponent = () => (
    <div className='text-gray-3 text-regular bold'>
      {t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.NO_LESSONS()}
    </div>
  );

  const handleDragStart = (e) => {
    if (e.isSource) {
      setReorderLessonData({ ...reorderLessonData, lessonId: lectureData[e.payload] });
    }
  };

  const handleDrop = (e) => {
    const { removedIndex, addedIndex } = e;
    if (removedIndex !== null && addedIndex !== null) {
      setLectureData((prevState) => {
        const previousLectureData = [...prevState];
        const [movedItem] = previousLectureData.splice(removedIndex, 1);
        previousLectureData.splice(addedIndex, 0, movedItem);
        return previousLectureData;
      });
      dispatch(reorderLesson({
        addedIndex,
        removedIndex,
        lessonId: reorderLessonData.lessonId,
        destinationFolderId: props.folderId,
        sourceFolderId: props.folderId,
      })).then((res) => {
        setReorderLessonData({
          addedIndex: null,
          removedIndex: null,
          destinationFolderId: null,
          sourceFolderId: null,
          roleBackSourceFolder: false,
          roleBackDestinationFolder: false,
        });
        if (!isEmpty(res.error)) {
          setLectureData([...folderLessonIds]);
        }
      });
    } else if (removedIndex !== null || addedIndex !== null) {
      setIsLessonEntered(false);
      if (removedIndex !== null) {
        setLectureData((prevState) => {
          const previousLectureData = [...prevState];
          previousLectureData.splice(removedIndex, 1);
          setReorderLessonData({ ...reorderLessonData, sourceFolderId: props.folderId, removedIndex });
          return previousLectureData;
        });
      } else {
        setLectureData((prevState) => {
          const previousLectureData = [...prevState];
          previousLectureData.splice(addedIndex, 0, reorderLessonData.lessonId);
          setReorderLessonData({ ...reorderLessonData, destinationFolderId: props.folderId, addedIndex });
          return previousLectureData;
        });
      }
    }
  };

  const handleDragEnd = (e) => {
    setIsDragging(false);
    setIsLessonDragging(false);
  };

  return (
    <div css={styles}>
      <div className='d-flex non-draggable'>
        {
         tableColumns.map((eachColumn) => (
           <div className={`${eachColumn.className}`} style={{ width: eachColumn.columnWidth }} key={eachColumn.name}>
             {eachColumn.name}
           </div>
         ))
        }
      </div>
      <hr className='m-0' />
      <Container
        lockAxis='y'
        dragHandleSelector='.drag'
        nonDragAreaSelector='.non-draggable'
        getChildPayload={(i) => i}
        dragClass='drag-lesson'
        onDragStart={handleDragStart}
        onDrop={handleDrop}
        onDragEnd={handleDragEnd}
        groupName='each-container'
        onDragEnter={() => setIsLessonEntered(true)}
        onDragLeave={() => setIsLessonEntered(false)}
      >
        {loadingLessons && <div className='p-4'><LoadingPlaceholder /></div>}
        {!!lectureData.length && lectureData.map((lessonId, i) => (
          <Draggable key={i}>
            <LessonRow
              data={lessonId}
              rowIndex={i}
              canShowFullList={isCollectionBuilder}
              isDragging={isDragging}
              setLectureData={setLectureData}
              folderId={props.folderId}
              setIsLessonDragging={setIsLessonDragging}
            />
          </Draggable>
        ))}
        {!loadingLessons && !lectureData.length && (
        <div className='non-draggable mb-6'>
          { !isLessonEntered && (
            <div css={noResultStyle}>
              <div className='icon icon-medium mb-4 icon-files text-gray-5' />
              {NoResultsTextComponent()}
            </div>
          )}

        </div>
        )}
      </Container>
    </div>
  );
};

export default FolderLectureList;
