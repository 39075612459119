import { css } from '@emotion/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { Button } from 'react-bootstrap';

import { AngularServicesContext } from 'react-app';
import { RootState } from 'redux/schemas';
import { VideoPracticeFeedbackActivity, VideoPracticeFeedbackLectureComponentType } from 'redux/schemas/models/video-practice-feedback';
import { RecordingFormat, VideoPracticeSubmission } from 'redux/schemas/models/video-practice';
import { ActivityType } from 'redux/schemas/models/activity';
import { getCourseAliases, getCurrentCourse } from 'redux/selectors/course';
import { feedbackMapping, getFeedbackGallerySubmissions } from 'redux/selectors/video-practice-feedback';
import { updateLectureComponent } from 'redux/actions/lecture-pages';
import {
  getAvailablePracticeActivities,
  setFeedbackActivity,
  setRequirementSubmitted,
  setShowFeedbackInstruction,
  setShowPracticeFeedbackModal,
} from 'redux/actions/video-practice-feedback';

import ActivityStatus from 'shared/components/activity/activity-status';
import ActivityTitle from 'shared/components/activity/activity-title';
import ActivityPoints from 'shared/components/activity/activity-points';
import ForActivityTitle from 'shared/components/activity/for-activity-title';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import LectureComponentHeaderInput from 'shared/components/lecture-component-header-input';
import NvIcon from 'shared/components/nv-icon';
import LectureComponentDeadline from 'components/lecture-component-deadline';
import LectureComponentProvider, { useLectureComponentContext } from 'components/lecture-component-provider';
import { FroalaViewMode } from 'froala/helpers/nv-froala-constants';
import NvFroala from 'froala/components/nv-froala';
import BaseLectureComponentContext from 'lecture_pages/directives/components/base-lecture-component/context';
import NvSubmissionGallery from 'shared/components/submission/nv-submission-gallery';
import Portal from 'shared/components/portal';
import PracticeFeedbackOverview from 'lecture_pages/components/video-practice-evaluation/feedback-overview/practice-feedback-overview';
import { LectureComponentProps, LecturePageMode } from 'lecture_pages/components';
import VideoPracticeSpecialGalleryCard from 'lecture_pages/components/video-practice/video-practice-special-gallery-card';
import VideoPracticeGalleryCard from 'lecture_pages/components/video-practice/video-practice-gallery-card';

import { danger, hexToRgbaString } from 'styles/global_defaults/colors';
import { halfSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { notHandheld } from 'styles/global_defaults/media-queries';
import { ComponentType, NLectureComponent } from 'redux/schemas/models/lecture-component';
import { NotAvailableGreyContainer } from 'shared/components/activity/not-available-container';
import { clearNewNotification, setFilteredComments } from 'redux/actions/video-practice';
import _ from 'lodash';
import { config } from '../../../../config/pendo.config.json';

const modalStyles = css`
  overflow-y: hidden !important;
`;

const styles = css`
  .activity-container {
    max-width: 800px;
  }
  .unavailable {
    background-color: ${hexToRgbaString(danger, 0.05)};
    border: 1px dashed ${danger};
  }
  .for-activity {
    white-space: nowrap;
  }
  &.video-practice-gallery {
    margin-left: -${standardSpacing}px;
    margin-right: -${standardSpacing}px;

    .nv-submission-gallery {
      padding-top: ${standardSpacing}px;

      .carousel-next, .carousel-prev {
        margin-top: ${standardSpacing}px;
        ${notHandheld(css`
          height: 240px;
        `)}
      }
    }
  }
  .skill-tag {
    width: fit-content;
    border-radius: ${threeQuartersSpacing}px;
    padding: 3px ${halfSpacing}px;
  }
`;

const useGetVideoPracticeFeedbackLectureComponent = (passedLectureComponentId?: number) => {
  const contextLectureComponentId = useLectureComponentContext().lectureComponentId;

  const lectureComponentId = String(passedLectureComponentId ?? contextLectureComponentId);
  return useSelector<RootState, VideoPracticeFeedbackLectureComponentType>((state: RootState) => state.models.lectureComponents[lectureComponentId] as NLectureComponent<ComponentType.VIDEO_PRACTICE_FEEDBACK>);
};

const useUpdatePracticeFeedbackActivity = () => {
  const dispatch = useAppDispatch();

  const { catalogId, lecturePageId } = useLectureComponentContext();
  const videoPracticeFeedbackLectureComponent = useGetVideoPracticeFeedbackLectureComponent();
  const { id, type, index } = videoPracticeFeedbackLectureComponent;
  const mapping = feedbackMapping[videoPracticeFeedbackLectureComponent.type];
  const practiceFeedbackActivityId = videoPracticeFeedbackLectureComponent[mapping.activityKey];
  const activity = useSelector<RootState, VideoPracticeFeedbackActivity>((state) => (
    state.models[mapping.activitiesKey][practiceFeedbackActivityId]
  ));

  return useCallback(
    (newPracticeFeedbacActivity: Partial<VideoPracticeFeedbackActivity>) => {
      const name = type === ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK
        ? 'skillsRating'
        : 'publicFeedback';
      const data = {
        [name]: {
          ...activity,
          activityType: ActivityType.VIDEO_PRACTICE,
          ...newPracticeFeedbacActivity,
        },
      };
      const updatedComponent = {
        catalogId,
        lecturePageId,
        componentData: {
          id,
          type,
          index,
          ...data,
        },
      };

      return dispatch(updateLectureComponent(updatedComponent)).then(() => {
        dispatch(getAvailablePracticeActivities({ catalogId }));
      });
    },
    [dispatch, catalogId, index, id, lecturePageId, type, activity],
  );
};

const VideoPracticeEvaluationComponentContextWrapper = (props: LectureComponentProps<ComponentType.VIDEO_PRACTICE_FEEDBACK>) => {
  const isEdit = props.mode === LecturePageMode.EDIT;

  const catalogId = useSelector((state) => state.app.currentCatalogId);

  return (
    <LectureComponentProvider
      catalogId={catalogId}
      lectureComponentId={props.lectureComponent.id}
      lecturePageId={props.currentLecture.id}
      isEdit={isEdit}
      angularComponent={props.angularComponent}
    >
      <VideoPracticeEvaluationComponent {...props} />
    </LectureComponentProvider>
  );
};

const VideoPracticeEvaluationComponent = ({
  currentLecture,
}: LectureComponentProps<ComponentType.VIDEO_PRACTICE_FEEDBACK>) => {
  const { $state, $uibModal } = useContext(AngularServicesContext);
  const { sharedProps, setSharedProps, extraContentAreaRef } = useContext(BaseLectureComponentContext);

  const dispatch = useAppDispatch();
  const aliases = useSelector((state: RootState) => getCourseAliases(state));

  const { isEdit, catalogId, lectureComponentId } = useLectureComponentContext();
  const activityUpdater = useUpdatePracticeFeedbackActivity();

  const lectureComponent = useSelector(state => state.models.lectureComponents[lectureComponentId]);
  const isSkillsFeedbackActivity = lectureComponent.type === ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK;
  const activity = useSelector<RootState, VideoPracticeFeedbackActivity>(state => {
    const [model, feedbackActivity] = isSkillsFeedbackActivity
      ? ['exerciseSkillsRatingActivities', 'exerciseSkillsRatingActivity']
      : ['practiceFeedbackActivities', 'practiceFeedbackActivity'];
    return state.models[model][lectureComponent[feedbackActivity]];
  });
  const practiceActivityFromActivity = activity?.activity ?? null;
  const practiceActivityFromRedux = useSelector((state: RootState) => state.models.practiceActivities[activity?.activity?.id]);
  const practiceActivity = { ...practiceActivityFromRedux, ...practiceActivityFromActivity };
  const skillTags = practiceActivity?.scenario?.skillTaggings?.map(skillTagging => skillTagging.skillTag) ?? [];
  const currentCourse = useSelector((state) => getCurrentCourse(state));
  const feedbackGallery: VideoPracticeSubmission[] = useSelector(state => (
    getFeedbackGallerySubmissions(state, activity.id)
  ));
  const showModal: boolean = useSelector(state => state.app.videoPracticeFeedback?.showPracticeFeedbackModal);
  const userHasViewdInstruction: boolean = useSelector(state => state.app.videoPracticeFeedback?.userHasViewdInstruction?.[activity.id]);
  const { newNotification } = useSelector((state) => state.app.practiceRoom.params);

  const [activityInfo, setActivityInfo] = useState(activity?.description);
  const [modalState, setModalState] = useState<{ submissionId: number, scenarioId: number }>(null);
  const [activityTitle, setactivityTitle] = useState(activity.title);

  const pendingEvaluationCount = activity.requiredToComplete - activity.numReviewesCompleted;

  const isPracticeActivityUnreleased = moment(practiceActivity?.releaseDate).isAfter(moment());
  const showSelectSkills = activity && (!skillTags || skillTags.length === 0) && isSkillsFeedbackActivity && isEdit && !_.isEmpty(practiceActivity);
  const icon = isSkillsFeedbackActivity ? 'skills-feedback-activity' : 'peer-feedback';

  useEffect(() => {
    const extraOptions = [];
    const deleteConfirmation = isSkillsFeedbackActivity
      ? {
        deleteConfirmationTitle: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.DELETE.TITLE(),
        deleteConfirmationMessage: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.DELETE.DESCRIPTION(),
      } : {
        deleteConfirmationTitle: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.DELETE_CONFIRMATION.TITLE(),
        deleteConfirmationMessage: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.DELETE_CONFIRMATION.DESCRIPTION(),
      };

    extraOptions.push({ type: 'text', text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.EDIT_BASICS(), callback: () => openEditModal() });
    if (!currentCourse.isSelfPaced) {
      extraOptions.push({
        type: 'text',
        text: activity.deadline ? t.LECTURE_PAGES.COMPONENTS.DROPDOWN.REMOVE_DEADLINE() : t.LECTURE_PAGES.COMPONENTS.DROPDOWN.ADD_DEADLINE(),
        callback: () => {
          activityUpdater({
            deadline: activity.deadline
              ? null
              : moment(currentLecture.releaseDate).add(1, 'weeks').endOf('day').toISOString(),
          });
        },
      });
    }
    extraOptions.push({
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.VIEW_PRACTICE(),
      callback: () => gotoVideoPractice(true),
    });

    setSharedProps({
      ...sharedProps,
      ...deleteConfirmation,
      extraOptions: {
        mode: 'prepend',
        options: extraOptions,
      },
    });
  }, [activity]);

  useEffect(() => {
    if (modalState !== null) {
      dispatch(setShowPracticeFeedbackModal(true));
    }
  }, [dispatch, modalState]);

  // Updating the activityTitle state when the activity's title updated in store
  useEffect(() => {
    if (activityTitle !== activity.title) {
      setactivityTitle(activity.title);
    }
  }, [activity.title]);

  useEffect(() => {
    if (practiceActivity?.progress === 'completed' && !activity?.hasSubmittedRequirement) {
      dispatch(setRequirementSubmitted(activity?.id));
    }
  }, [activity?.hasSubmittedRequirement, activity?.id, dispatch, practiceActivity?.progress]);

  const onSelectSubmission = useCallback((submissionId: number, scenarioId: number) => {
    setModalState({
      submissionId,
      scenarioId,
    });
    dispatch(setFeedbackActivity(activity.id));
    if (!userHasViewdInstruction && activity?.progress === 'not_started') {
      dispatch(setShowFeedbackInstruction({
        show: true,
        practiceFeedbackCriteriaId: activity.id,
      }));
    } else {
      dispatch(setShowFeedbackInstruction({
        show: false,
        practiceFeedbackCriteriaId: activity.id,
      }));
    }
  }, [activity.id, activity?.progress, dispatch, userHasViewdInstruction]);

  const getFeedbackGalleryCards = useCallback(() => {
    if (feedbackGallery?.length > 0 && !isEdit) {
      const gallerycards = feedbackGallery.map(submission => (
        <VideoPracticeGalleryCard
          key={submission.id}
          {...submission}
          onClick={() => onSelectSubmission(submission.id, submission.scenario.id)}
          featured={false} // Not to display highlight
          isFeedbackGallery
          dataQa={`practice-feedback-view-practice-${submission.id}`}
          dataQaPendo={config.pendo.practiceFeedback.viewPractice}
          isAudio={activity.activity.scenario?.recordingFormat === RecordingFormat.AUDIO}
        />
      ));
      if (feedbackGallery.length < activity?.requiredToComplete) {
        gallerycards.push(
          <VideoPracticeSpecialGalleryCard
            key='no-submissions'
            title={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.GALLERY.NO_SUBMISSIONS()}
          />,
        );
      }
      return gallerycards;
    }
    return null;
  }, [activity?.requiredToComplete, feedbackGallery, isEdit, onSelectSubmission]);

  const openEditModal = () => {
    const modalInstance = $uibModal.open({
      templateUrl: 'lecture_pages/templates/components/video-practice-evaluation-modal.html',
      controller: 'VideoPracticeEvaluationCtrl',
      controllerAs: 'vm',
      resolve: {
        vmResolves: {
          formData: {
            activity: practiceActivity,
            activityId: activity?.activityId,
            activityType: ActivityType.VIDEO_PRACTICE,
            requiredToComplete: activity.requiredToComplete,
            title: activityTitle,
            description: activityInfo,
            hardDeadline: activity.hardDeadline,
            isTodo: activity.isTodo,
          },
          isEdit: true,
          lectureComponent,
        },
      },
    });

    modalInstance.result.then((res) => {
      activityUpdater(res);
    });
  };

  const gotoVideoPractice = (isEditMode?: boolean) => {
    const stateParams = {
      page: 'lecture-page',
      catalogId,
      id: practiceActivity?.lecturePageId,
      lectureActivityId: practiceActivity?.lectureComponentId,
    };
    if (isEditMode) {
      stateParams.page = 'lecture-page-edit';
    }
    const { page, ...restParams } = stateParams;
    /**
     * doing an angular-ui-router transition via $state.go() doesn't seem to update the template,
     * so we're using the same alternative through 'document.location.hash'
     */
    document.location.hash = $state.href(page, restParams);
  };

  const galleryContent = (
    <div css={styles} className='video-practice-gallery'>
      <NvSubmissionGallery
        spacing={30}
        arrowsHidden={!feedbackGallery.length}
        bottomPadding={40}
      >
        {getFeedbackGalleryCards()}
      </NvSubmissionGallery>
    </div>
  );

  const hasNotAvailableContent = ((isPracticeActivityUnreleased && isEdit) || (activity.hasSubmittedRequirement && !feedbackGallery.length && !isEdit));
  const notAvailableContent = useCallback(() => {
    if (isPracticeActivityUnreleased && isEdit && practiceActivity?.releaseDate) {
      return (
        <NotAvailableGreyContainer
          icon='practice'
          text={t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.NOT_AVAILABLE.BEFORE_ASSIGNMENT_RELEASE_DATE(
            moment(practiceActivity?.releaseDate).format('LLL'),
          )}
        />
      );
    }
    if (activity.hasSubmittedRequirement && !feedbackGallery.length && !isEdit) {
      return (
        <NotAvailableGreyContainer
          icon='practice'
          text={t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.NOT_AVAILABLE.EMPTY()}
        />
      );
    }
    return null;
  }, [
    activity.hasSubmittedRequirement, feedbackGallery.length, isEdit,
    isPracticeActivityUnreleased, practiceActivity?.releaseDate,
  ]);

  const onClosePracticeModal = useCallback(() => {
    if (newNotification?.commentId) {
      dispatch(setFilteredComments({
        submissionId: newNotification?.submissionId,
        filteredComments: [],
      }));
      dispatch(clearNewNotification({}));
    }
    dispatch(setShowPracticeFeedbackModal(false));
    setModalState(null);
    dispatch(setFeedbackActivity(null));
  }, [dispatch, newNotification?.commentId, newNotification?.submissionId]);

  useEffect(() => {
    // To track the outside click event of the modal and close it.
    const closeAll = (e) => {
      if (e.target.classList.contains('bs4-modal-content')) {
        onClosePracticeModal();
      }
    };

    if (showModal) {
      document.addEventListener('click', closeAll);
    }

    return () => {
      if (showModal) {
        document.removeEventListener('click', closeAll);
      }
    };
  }, [onClosePracticeModal, showModal]);

  const hasSkills = (isSkillsFeedbackActivity && skillTags.length > 0);
  const isValidActivity = hasSkills || !isSkillsFeedbackActivity;

  const showNoCompletedPracticeActivity = (
    !activity.hasSubmittedRequirement
    && !isEdit
    && !_.isEmpty(practiceActivity)
    && !isPracticeActivityUnreleased
    && isValidActivity
  );

  const showNotAvailableYet = (
    !isEdit
    && (
      _.isEmpty(practiceActivity)
      || isPracticeActivityUnreleased
      || !isValidActivity
    )
  );

  return (
    <div
      className='d-flex flex-column'
      id={`lecture-component-${lectureComponentId}`}
      css={styles}
    >
      {activity && (
        <div className='d-flex flex-column activity-container mx-auto justify-content-center align-items-center w-100'>
          <ActivityStatus icon={icon} status={activity.progress ?? 'not_started'} />
          <div className='text-small d-flex flex-column align-items-center mt-2'>
            <ActivityTitle
              status={activity.progress ?? 'not_started'}
              statusesTexts={{
                not_started: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.PROVIDE_FEEDBACK_DESCRIPTION.NOT_STARTED(activity.requiredToComplete ?? 0),
                completed: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.PROVIDE_FEEDBACK_DESCRIPTION.COMPLETED(activity.numReviewesCompleted ?? 0),
                missed: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.PROVIDE_FEEDBACK_DESCRIPTION.MISSED(pendingEvaluationCount ?? 0, activity.requiredToComplete ?? 0),
              }}
            />
            {activity.progress === 'in_progress' && (
              <span className='font-weight-bold mt-2'>
                {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.PROVIDE_FEEDBACK_DESCRIPTION.IN_PROGRESS(pendingEvaluationCount, activity.numReviewesCompleted)}
              </span>
            )}
          </div>
          <LectureComponentHeaderInput
            value={activityTitle}
            editable={isEdit}
            onBlur={() => {
              activityUpdater({ title: activityTitle });
            }}
            onChange={(newTitle) => setactivityTitle(newTitle)}
            required
          />
          {!_.isEmpty(practiceActivity) && (
            <ForActivityTitle
              title={t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.FOR_ACTIVITY()}
              activity={practiceActivity.title}
              titleclassName='text-small font-weight-bolder for-activity'
              activityClassName='d-block text-small font-weight-bold text-primary ml-1 ellipsis'
              gotoActivty={() => gotoVideoPractice(isEdit)}
              dataQa={config.pendo.practiceFeedback.forActivity}
            />
          )}
          <div className='my-2'>
            <LectureComponentDeadline
              componentName='video-practice-feedback-deadline'
              expirationDate={activity.deadline}
              lecturePageId={currentLecture.id}
              catalogId={catalogId}
              isEdit={isEdit}
              hasHardDeadlines={activity.hardDeadline}
              onHardDeadlineChange={(hardDeadline) => {
                activityUpdater({ hardDeadline });
              }}
              onDeadlineChange={(date) => {
                activityUpdater({ deadline: date?.toISOString() });
              }}
              isDeadlineEditable={isEdit}
            />
          </div>
          {activity.pointsConfiguration && (
            <div className='mb-2'>
              {activity.progress === 'in_progress' || (activity.progress === 'missed' && activity.pointsReceived) ? (
                <div className='d-flex text-small font-weight-bold'>
                  <NvIcon icon='highlight' size='xss-smallest' className='text-success mr-2' />
                  <span>
                    <span className='text-success'>{activity.pointsReceived}</span>
                    <span className='text-gray-2'>
                      /
                      {activity.totalPoints?.[0]}
                    </span>
                  </span>
                </div>
              ) : (
                <ActivityPoints
                  pointsReceived={activity.pointsReceived}
                  totalPoints={activity.totalPoints?.[0]}
                  pointsText={(activity.progress === 'not_started' || isEdit
                  || (activity.progress === 'missed' && !activity.pointsReceived)) ? t.LECTURE_PAGES.COMPONENTS.ACTIVITY.X_POINTS_EACH({
                      ...aliases.pointsAliases,
                      pointsCount: activity.pointsConfiguration?.points,
                      totalPoints: activity.totalPoints?.[0],
                    }) : undefined}
                />
              )}

            </div>
          )}
          <div className='w-100'>
            {isEdit && (
              <NvFroala
                value={activityInfo}
                onChange={setActivityInfo}
                preset={FroalaViewMode.INLINE}
                immediateReactModelUpdate
                onBlur={() => {
                  activityUpdater({ description: activityInfo });
                }}
              />
            )}
            {(!isEdit && (activity.hasSubmittedRequirement || _.isEmpty(practiceActivity))) && (
              <div
                className='text-body'
                dangerouslySetInnerHTML={{ __html: activity.description }}
              />
            )}
          </div>
          {activity && skillTags.length > 0 && (
            <div className='d-flex flex-column align-items-center'>
              <div className='d-flex align-items-center my-4'>
                {isEdit ? (
                  t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.SKILLS_FOR(practiceActivity.title)
                ) : (
                  <>
                    {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.VIDEO_PRACTICE_SKILLS_INFO()}
                  </>
                )}
              </div>
              <div className='d-flex flex-wrap'>
                {_.sortBy(skillTags, 'name')?.map(tag => (
                  <SkillTags tag={tag.name} key={tag.id} />
                ))}
              </div>
            </div>
          )}
          {/* practice activity removed - admin view */}
          {_.isEmpty(practiceActivity) && isEdit && (
            <div className='unavailable d-flex flex-column align-items-center justify-content-center p-2 my-5 w-100'>
              <div className='font-weight-bolder gray-1'>
                {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.NOT_AVAILABLE.PICK_ACTIVITY()}
              </div>
              <div className='gray-1 my-1'>
                {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.NOT_AVAILABLE.DESCRIPTION()}
              </div>
              <Button variant='primary mt-2' onClick={openEditModal}>
                {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.NOT_AVAILABLE.PICK()}
              </Button>
            </div>
          )}
          {showNotAvailableYet && (
            <NotAvailableGreyContainer icon='ban' text={t.LECTURE_PAGES.COMPONENTS.NOT_AVAILABLE_YET()} />
          )}
          {showNoCompletedPracticeActivity && (
            <div className='d-flex flex-column align-items-center'>
              {activity.progress !== 'missed' && (
                <div className='text-small gray-3 text-center'>
                  {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.NOT_AVAILABLE.LEANER_NOT_COMPLETED_ASSIGNMENT(practiceActivity.title)}
                </div>
              )}
              <Button
                variant='primary'
                onClick={() => gotoVideoPractice()}
                className='my-2'
                data-qa={config.pendo.practiceFeedback.gotoPractice}
              >
                {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.GO_TO_PRACTICE()}
              </Button>
            </div>
          )}
          {showSelectSkills && (
            <div className='unavailable d-flex flex-column align-items-center justify-content-center p-2 mt-5 w-100 body-text-wrapper'>
              <div className='font-weight-bolder gray-1'>
                {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.NOT_AVAILABLE.VIDEO_PRACTICE_FEEDBACK()}
              </div>
              <div className='gray-1 my-1'>
                {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.NOT_AVAILABLE.DESCRIPTION()}
              </div>
              <Button
                variant='primary mt-2'
                onClick={() => gotoVideoPractice(true)}
                data-qa={config.pendo.activities.assignmentSkillRating.gotoAssignment}
              >
                {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.NOT_AVAILABLE.VIDEO_PRACTICE_FEEDBACK_BUTTON()}
              </Button>
            </div>
          )}
        </div>
      )}
      {(((feedbackGallery.length > 0 && !isEdit) || hasNotAvailableContent) && !showSelectSkills && !showNotAvailableYet) && (
        <Portal ref={extraContentAreaRef}>
          {notAvailableContent()}
          {!isPracticeActivityUnreleased && galleryContent}
        </Portal>
      )}
      {modalState && (
        <NvModal
          backdrop
          type={ModalType.NO_DIALOG}
          show={showModal}
          onClose={onClosePracticeModal}
          closeDataQa={config.pendo.practiceFeedback.closeOverviewModal}
          modalStyles={modalStyles}
          body={(
            <PracticeFeedbackOverview
              submissionId={modalState.submissionId}
              scenarioId={modalState.scenarioId}
              lectureComponentId={lectureComponent.id}
              isSkillsFeedbackActivity={isSkillsFeedbackActivity}
              practiceActivity={practiceActivity}
              onClose={onClosePracticeModal}
            />
          )}
        />
      )}
    </div>
  );
};

const SkillTags = (props: { tag: string }) => (
  <div
    className='border border-gray-5 skill-tag align-items-center label mr-2 bg-gray-7 mb-2 text-black'
  >
    {props.tag}
  </div>
);

export default VideoPracticeEvaluationComponentContextWrapper;
