import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';

// Translations
import t from 'react-translate';

// Types
import { SessionTypes, LiveEventSession as LiveEventSessionType, ZoomSession } from 'redux/schemas/models/live-event';
import { FroalaViewMode, UploadType } from 'froala/helpers/nv-froala-constants';

// Styles
import { css } from '@emotion/react';
import { gray4, gray7 } from 'styles/global_defaults/colors';
import { iconSmallSize } from 'styles/global_defaults/icons';
import { getSanitizedStyles } from 'shared/utils';

// Components
import NvFroala from 'froala/components/nv-froala';
import NvDatePicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import { FormNvClickToEdit, InputType } from 'components/nv-click-to-edit';
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';
import TimezoneDropdown from './timezone-dropdown';
import ZoomHostValidator from './zoom-host-validator';

// Pendo
import { config } from '../../../config/pendo.config.json';

const styles = css`
  background-color: ${gray7};

  .badge-index {
    width: ${iconSmallSize}px;
    height: ${iconSmallSize}px;
    border-radius: 10px;
    color: white;
  }

  .fr-wrapper {
    .fr-element {
      max-height: 55px;
      min-height: unset !important;
      outline: none;
    }
  }

  .timing {
    gap: 10px;
    .timing-start-time {
      flex-grow: 1;
      .react-datepicker-wrapper {
        width: 100%;
      }
    }
    .timing-timezone {
      width: 150px;
    }
    .timing-duration {
      width: 130px;
    }
    .timing-timezone, .timing-duration {
      &.disabled {
        .bs4-dropdown {
          pointer-events: none;
          background-color: transparent;
          border-color: ${gray4};
          color: inherit;
        }
      }
    }
  }
  .bs4-dropdown-menu {
    max-height: 250px;
    overflow-y: scroll;
  }
  .live-event-topic {
    word-break: break-all;
  }
`;

type LiveEventSessionProps = {
  session: LiveEventSessionType
  sessionType: SessionTypes
  showIndex: boolean
  canDelete: boolean
  isContentManagementCollection: boolean
  isLinked: boolean
  durations: number[]
  delete: () => void
  getDurationText: (duration: number) => string
  validateHost?: (email: string) => void
  showZoomHostValidator: () => boolean
  onHostValidation?: (isValid: boolean, host?: string) => void
};

const labelClass = 'text-small gray-3 m-0';

const LiveEventSession = (props: LiveEventSessionProps) => {
  const {
    session,
    sessionType,
    showIndex,
    canDelete,
    isContentManagementCollection,
    isLinked = false,
    durations,
    delete: deleteSession,
    getDurationText,
    validateHost: validate,
    showZoomHostValidator,
    onHostValidation,
  } = props;

  const [isValidHost, setIsValidHost] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const validHost = useRef((session as ZoomSession).authenticationEmail);

  const name = `sessions.${session.index}`;
  const descriptionStyles = session.id ? css`border: 1px solid ${gray4};` : '';

  const { watch, formState } = useFormContext() || {};
  const startTime = watch?.(`${name}.startTime`);
  const zoomHost = watch?.(`${name}.authenticationEmail`);

  const shouldShowZoomHostValidator = showZoomHostValidator() || isValidHost;

  const inputStyles = css`
    .bs4-form-control, .live-session-description {
      background-color: ${disabled || isContentManagementCollection ? 'unset' : 'white'};
    }
    .react-datepicker-wrapper {
      input {
        pointer-events: ${disabled || isContentManagementCollection ? 'none' : 'unset'};
      }
    }
    .bs4-input-group {
      .bs4-input-group-append {
        background-color: transparent;
        .bs4-input-group-text {
          background-color: white;
        }
      }
    }
  `;

  useEffect(() => {
    if (sessionType === SessionTypes.ZOOM && (session as ZoomSession).authenticationEmail) {
      setIsValidHost(true);
    }
  }, [sessionType]);

  useEffect(() => {
    const newDisabled = startTime && session.id && !formState.isDirty
      ? moment().isAfter(moment(startTime).subtract(10, 'minutes'))
      : false;
    setDisabled(newDisabled);
  }, [startTime]);

  useEffect(() => {
    onHostValidation?.(isValidHost, zoomHost);
  }, [isValidHost]);

  const shouldDisableLessonLibrary = isContentManagementCollection && !!session.id;

  return (
    <div css={getSanitizedStyles([styles, inputStyles])} className='p-4 mb-4'>
      <div className='d-flex align-items-center mb-4'>
        {showIndex && (
          <div className='bg-gray-2 text-align-center bold badge-index mr-2'>{session.index + 1}</div>
        )}
        <NvTooltip
          text={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.SETUP_IN_COURSE_TOOLTIP()}
          placement='top'
          enabled={shouldDisableLessonLibrary}
        >
          <div className='w-100'>
            <FormNvClickToEdit
              className={`live-event-topic w-100 ${disabled ? 'disabled' : ''}`}
              name={`${name}.title`}
              type={InputType.SHORT_TEXT}
              showInputWhenEmpty
              text={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.ADD_TITLE()}
              replaceText
              editable={!disabled && !shouldDisableLessonLibrary}
              textContainerClassName='text-large-body bold'
              dataQa={config.pendo.liveEvent.liveEventSession.title}
              withForm
            />
          </div>
        </NvTooltip>
      </div>
      <NvTooltip
        text={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.SETUP_IN_COURSE_TOOLTIP()}
        placement='top'
        enabled={shouldDisableLessonLibrary}
      >
        <div className='w-100'>
          <NvFroala
            withForm
            className='mb-4 live-session-description'
            css={descriptionStyles}
            preset={FroalaViewMode.NORMAL}
            uploadType={UploadType.NONE}
            name={`${name}.description`}
            max-length={sessionType === SessionTypes.ZOOM ? 2000 : -1}
            ariaLabel={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.ADD_DESCRIPTION()}
            placeholder={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.ADD_DESCRIPTION()}
            isDisabled={disabled || shouldDisableLessonLibrary}
            dataQa={config.pendo.liveEvent.liveEventSession.description}
          />
        </div>
      </NvTooltip>
      <div className='timing mb-4 d-flex w-100 justify-content-between'>
        <div className='timing-start-time'>
          <label className={labelClass}>{t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.START_TIME()}</label>
          <NvTooltip
            text={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.SETUP_IN_COURSE_TOOLTIP()}
            placement='top'
            enabled={isContentManagementCollection}
          >
            <div>
              <NvDatePicker
                required
                withForm
                placement='right'
                min={moment()}
                disabled={disabled || isContentManagementCollection}
                name={`${name}.startTime`}
                type={DatePickerType.DATETIME}
                placeholder={isContentManagementCollection
                  ? t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.SETUP_IN_COURSE_TOOLTIP()
                  : t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.START_TIME()}
                className='w-100'
                dataQa={config.pendo.liveEvent.liveEventSession.date}
              />
            </div>
          </NvTooltip>
        </div>
        <div className={`timing-timezone${disabled || isContentManagementCollection ? ' disabled' : ''}`}>
          <label className={labelClass}>{t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.TIME_ZONE()}</label>
          <NvTooltip
            text={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.SETUP_IN_COURSE_TOOLTIP()}
            placement='top'
            enabled={isContentManagementCollection}
          >
            <div>
              <TimezoneDropdown
                name={`${name}.timeZone`}
                disabled={disabled || isContentManagementCollection}
                placeholder={isContentManagementCollection
                  ? t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.SETUP_IN_COURSE_TOOLTIP()
                  : ''}
              />
            </div>
          </NvTooltip>
        </div>
        <div className={`timing-duration${disabled || isContentManagementCollection ? ' disabled' : ''}`}>
          <label className={labelClass}>{t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.DURATION()}</label>
          <NvTooltip
            text={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.SETUP_IN_COURSE_TOOLTIP()}
            placement='top'
            enabled={isContentManagementCollection}
          >
            <div>
              <NvFormDropdown
                name={`${name}.duration`}
                items={durations.map((duration) => ({
                  id: duration,
                  type: 'text',
                  text: getDurationText(duration),
                  dataQa: `${config.pendo.liveEvent.liveEventSession.duration}-${duration}`,
                }))}
                isSmallSize
                disabled={disabled || isContentManagementCollection}
                title={isContentManagementCollection
                  ? t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.SETUP_IN_COURSE_TOOLTIP()
                  : ''}
              />
            </div>
          </NvTooltip>
        </div>
      </div>
      {sessionType === SessionTypes.ZOOM && (
        <React.Fragment>
          {shouldShowZoomHostValidator && (
            <ZoomHostValidator
              name={name}
              validate={validate}
              onValidHost={(isValid) => setIsValidHost(isValid)}
              disabled={disabled}
              isContentManagementCollection={isContentManagementCollection}
              host={validHost.current}
            />
          )}
          <div>
            <label className={labelClass}>
              {t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.ALTERNATIVE_HOSTS()}
            </label>
            <NvTextInput
              withForm
              type='email'
              autoComplete='off'
              disabled={disabled}
              className='alternative-hosts'
              name={`${name}.alternativeHosts`}
              data-qa={config.pendo.liveEvent.liveEventSession.zoom.alternativeHost}
              ariaLabel={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.ALTERNATIVE_HOSTS()}
              placeholder={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.ALTERNATIVE_HOSTS_EXAMPLE()}
            />
          </div>
        </React.Fragment>
      )}
      {sessionType === SessionTypes.EXTERNAL && (
        <div>
          <label className={labelClass}>{t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.LINK_TO_JOIN()}</label>
          <NvTooltip
            text={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.SETUP_IN_COURSE_TOOLTIP()}
            placement='top'
            enabled={isContentManagementCollection}
          >
            <div>
              <NvTextInput
                withForm
                required
                autoComplete='off'
                errorOnTouching={false}
                className='link-to-join'
                name={`${name}.joinUrl`}
                disabled={disabled || isContentManagementCollection}
                data-qa={config.pendo.liveEvent.liveEventSession.external.joinUrl}
                ariaLabel={isContentManagementCollection
                  ? t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.SETUP_IN_COURSE_TOOLTIP()
                  : t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.LINK_TO_JOIN()}
                placeholder={isContentManagementCollection
                  ? t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.SETUP_IN_COURSE_TOOLTIP()
                  : t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.LINK_TO_JOIN_PLACEHOLDER()}
              />
            </div>
          </NvTooltip>
        </div>
      )}
      {canDelete && (
        <div className='d-flex mt-4 justify-content-end'>
          <span
            className='d-flex text-small gray-3 cursor-pointer'
            onClick={deleteSession}
            data-qa={config.pendo.liveEvent.liveEventSession.removeSession}
          >
            {t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.REMOVE_SESSION()}
            <NvIcon icon='trash' size='smallest' className='cursor-pointer ml-2' />
          </span>
        </div>
      )}
    </div>
  );
};

export default LiveEventSession;
