import React, { useContext } from 'react';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { useAppDispatch } from 'redux/store';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import DegreedIntegration from './degreed-integration';
import MsIntegration from './ms-integration';
import ZoomIntegration from './zoom-integration';
import HcmIntegration from './hcm-integration';
import MsCalendarIntegration from './ms-calendar-integration';
import LmsIntegration from './lms-integration';
import EdCastIntegration from './ed-cast-integration';
import { config } from '../../../../../config/pendo.config.json';
import CredlyIntegration from './credly-integration';
import WelcomeEmail from './welcome-email';

export type DisableProps = {
  action: (any?) => Promise<any>
  onSuccess: (any?) => void
  title: string
  bodyText: string
  successMessage: string
  cancelDataQa?: string
  confirmDataQa?: string
};

const Integrations = () => {
  const { InstitutionsManager } = useContext(AngularServicesContext);

  const dispatch = useAppDispatch();

  const onDisable = ({
    action,
    onSuccess,
    successMessage,
    title,
    bodyText,
    confirmDataQa,
    cancelDataQa,
  }: DisableProps) => {
    dispatch(openConfirmationDialog({
      onConfirm: () => action()
        .then((isValid) => {
          if (isValid) {
            onSuccess(isValid);
            dispatch(
              addAlertMessage({
                type: AlertMessageType.SUCCESS,
                header: t.FORM.SUCCESS_BANG(),
                message: successMessage,
              }),
            );
          } else {
            dispatch(
              addAlertMessage({
                type: AlertMessageType.ERROR,
                header: t.FORM.OOPS(),
                message: t.FORM.ERROR_SOMETHING_WRONG(),
              }),
            );
          }
        }),
      cancelText: t.FORM.CANCEL(),
      confirmText: t.FORM.YES_SURE(),
      title,
      bodyText,
      confirmDataQa,
      cancelDataQa,
    }));
  };

  return (
    <div>
      <div className='border-bottom border-gray-5 pb-6'>
        <ZoomIntegration onDisable={onDisable} />
      </div>
      <div className='border-bottom border-gray-5 py-6'>
        <MsIntegration onDisable={onDisable} />
      </div>
      <div className='border-bottom border-gray-5 py-6'>
        <MsCalendarIntegration onDisable={onDisable} />
      </div>
      <div className='border-bottom border-gray-5 py-6'>
        <LmsIntegration onDisable={onDisable} />
      </div>
      <div className='border-bottom border-gray-5 py-6'>
        <HcmIntegration />
      </div>
      <div className='border-bottom border-gray-5 py-6'>
        <DegreedIntegration onDisable={onDisable} />
      </div>
      <div
        className='border-bottom border-gray-5 py-6'
        data-qa={config.pendo.edcast.integration}
      >
        <EdCastIntegration />
      </div>
      <div
        className='border-bottom border-gray-5 py-6'
        data-qa={config.pendo.credly.integration}
      >
        <CredlyIntegration onDisable={onDisable} />
      </div>
      {InstitutionsManager.institution?.identityProviders?.length ? (
        <div className='border-bottom border-gray-5 py-6'>
          <WelcomeEmail />
        </div>
      ) : null}
    </div>
  );
};

export default Integrations;
