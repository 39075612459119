import React, { useEffect, useRef, useState } from 'react';
/* eslint-disable react/no-danger */
import moment from 'moment';
import t from 'react-translate';

// Hooks
import useSessionStates from 'shared/hooks/use-session-states';

// Styles
import { css } from '@emotion/react';
import { quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { mobile } from 'styles/global_defaults/media-queries';
import { gray3, gray4, gray6 } from 'styles/global_defaults/colors';

// Hooks
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { useLectureComponentContext } from 'components/lecture-component-provider';

// Components & Types
import { AttendeeInfoStatus } from 'redux/schemas/models/live-event';
import NvVideoPreview, { NvVideoPreviewImperativeInterface } from 'shared/components/nv-video-preview';
import NvFileUploadProgress from 'shared/components/nv-file-upload-progress';
import { FileUploading } from 'shared/hooks/use-upload-file';
import NvIcon from 'shared/components/nv-icon';
import LiveSessionCardAction, { LiveSessionState } from './live-session-card-action';
import LiveSessionCardState, { CardStatus } from './live-session-card-state';
import LiveSessionCardTitle, { CardBadge } from './live-session-card-title';
import LiveSessionCardEditOptions from './live-session-card-edit-options';

// Pendo
import { config } from '../../../../config/pendo.config.json';

const DEFAULT_PROGRESS = 20;

const styles = css`
  justify-content: space-between;
  border: 1px solid ${gray4};
  border-radius: ${quarterSpacing}px;
  flex-direction: row;
  gap: ${standardSpacing}px;

  &.video-expanded {
    flex-direction: column;
    width: 100%;
    .session-recording {
      flex-direction: column;
    }
  }

  &.video-compact {
    flex-direction: row;
    .session-recording {
      width: 240px;
      height: 150px;
      &.uploader {
        width: 240px;
        .upload-text {
          justify-content: space-between;
          display: flex;
        }
      }
      .nv-video-preview {
        height: 100% !important;
        .unavailable {
          height: 100% !important;
        }

        video {
          height: 100%;
        }
      }
      .recording-header {
        display: none !important;
      }
    }
  }

  .session-recording.uploader {
    max-height: 150px;
    width: 240px;
    .file-upload-progress {
      height: calc(100% - 40px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      .bs4-progress {
        width: 100%;
      }
    }
    .upload-text {
      justify-content: center;
      display: flex;
      margin-top: 10px;
      .file-name {
        margin-top: 0;
        margin-right: 10px;
      }
    }
  }

  .session-body {
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;
    .session-description {
      .froala-style-regular {
        line-height: ${standardSpacing}px;
      }
    }
    .session-title {
      .text-large-body {
        word-break: break-all;
      }
    }
  }

  .session-actions {
    flex-direction: column;
    min-width: 140px;
  }

  ${mobile(css`
    flex-direction: column !important;
    .session-body {
      .session-title {
        flex-direction: column;
        > div {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }
    .session-recording {
      max-width: unset !important;
      margin-right: 0 !important;
      .nv-video-preview {
        > div {
          max-height: 200px;
        }
      }
      .recording-header {
        display: none !important;
      }
    }

    a.disabled {
      background-color: ${gray6};
      border-color: ${gray6};
      color: ${gray3};
      pointer-events: none;
    }
  `)};
`;

const getSessionStatus = (state): CardStatus => {
  const { isRegistered, inProgress, startsSoon, ended } = state;
  if (inProgress) {
    return CardStatus.IN_PROGRESS;
  } if (startsSoon) {
    return CardStatus.STARTS_SOON;
  } if (isRegistered && !ended) {
    return CardStatus.UNREGISTER;
  } return CardStatus.REGISTER;
};

const getSessionAction = (state) => {
  const { isRegistered, inProgress, startsSoon, ended } = state;
  let disabled = true;
  let status: LiveSessionState = null;

  if (isRegistered) {
    status = LiveSessionState.JOIN_NOW;
    disabled = !(inProgress || startsSoon);
  } else if (!ended && !inProgress) {
    status = LiveSessionState.REGISTER;
  }

  return { disabled, status };
};

const getCardBadge = (state): CardBadge => {
  const { isRegistered, hasViewed, hasAttended } = state;

  if (hasViewed) return CardBadge.VIEWED;
  if (hasAttended) return CardBadge.ATTENDED;
  if (isRegistered) return CardBadge.REGISTERED;
  return null;
};

type LiveSessionTimingProps = {
  duration: any
  startTime: string
};

const LiveSessionTiming = (props: LiveSessionTimingProps) => {
  const { duration, startTime } = props;
  const formattedStartTime = moment(startTime).format('LLL');
  return (
    <div className='session-timing d-flex gray-2'>
      <div className='session-start-time'>{formattedStartTime}</div>
      <div className='ml-1 mr-1'>|</div>
      <div className='session-duration'>{t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.DURATION(duration)}</div>
    </div>
  );
};

export type UploadEvents = {
  onFileSelected: (file: FileUploading) => void
  onFileLoaded: (file: FileUploading) => void
  onSuccess: (recording: any) => void
  onCloseModal?: () => void
};

export enum RecordingEvent {
  PLAY = 'play',
  PAUSE = 'pause',
  ENDED = 'ended',
  READY = 'ready',
  UNLOAD = 'unload',
}

type LiveSessionCardProps = {
  session: any
  isEdit: boolean
  allowUserRegistration: boolean
  register: () => void
  joinNow: () => void
  unregister: () => void
  uploadAttendeeList: () => void
  uploadRecording: (sessionId: number, events: UploadEvents) => void
  editRecording: (sessionId: number, file: any, onSuccess: (recording: any) => void) => void
  removeRecording: (sessionId: number, onSuccess: () => void) => void
  delete: () => void
  recordingEventCallback: (
    event: RecordingEvent,
    second: number,
    totalLength: number,
    lecturePageId: number,
    lectureComponentId: number,
    sessionId: number,
  ) => void
};

const LiveSessionCard = (props: LiveSessionCardProps) => {
  const {
    session,
    isEdit,
    allowUserRegistration,
    register,
    joinNow,
    unregister,
    uploadAttendeeList,
    uploadRecording,
    editRecording,
    removeRecording,
    delete: deleteSession,
    recordingEventCallback,
  } = props;

  const {
    id,
    title,
    description,
    startTime,
    duration,
    timeZone,
    attendeeInfo,
    recording: sessionRecording,
  } = session;

  const [isExpanded, setIsExpanded] = useState(false);
  const [videoPlayerClass, setVideoPlayerClass] = useState('');
  const [recording, setRecording] = useState(sessionRecording?.file || null);
  const [uploadingRecording, setUploadingRecording] = useState(false);
  const [selectedFile, setSelectedFile] = useState<FileUploading>(null);
  const playerInstanceRef = useRef<NvVideoPreviewImperativeInterface>(null);
  const { status } = attendeeInfo || {};

  const onCardActionClicked = (state: LiveSessionState) => {
    if (state === LiveSessionState.REGISTER) register();
    else joinNow();
  };

  const hasAttended = status === AttendeeInfoStatus.ATTENDED_AUTO || status === AttendeeInfoStatus.ATTENDED_MANUAL;
  const hasViewed = status === AttendeeInfoStatus.WATCHED_RECORDING;
  const isRegistered = true; // TODO: Map properly when this feature is enabled

  const sessionSettings = useSessionStates({ startTime, duration, timezone: timeZone });
  const sessionStatus: CardStatus = getSessionStatus({ ...sessionSettings, isRegistered });
  const sessionAction = getSessionAction({ ...sessionSettings, isRegistered });
  const cardBadge: CardBadge = getCardBadge({ hasAttended, hasViewed, isRegistered });
  const shouldShowSessionStatus = sessionStatus !== CardStatus.REGISTER && sessionAction.status && !isEdit;
  const { lecturePageId } = useLecturePageParams();
  const { lectureComponentId } = useLectureComponentContext();

  const onRecordingEvent = (event: RecordingEvent) => {
    if (playerInstanceRef.current) {
      const total = playerInstanceRef.current.getDuration();
      const currentTime = playerInstanceRef.current.getPosition();
      if (event === RecordingEvent.PLAY) setIsExpanded(true);
      recordingEventCallback(event, currentTime, total, lecturePageId, lectureComponentId, id);
    }
  };

  const eventHandlers = {
    onPlay: onRecordingEvent.bind(null, RecordingEvent.PLAY),
    onPause: onRecordingEvent.bind(null, RecordingEvent.PAUSE),
    onEnded: onRecordingEvent.bind(null, RecordingEvent.ENDED),
  };

  const onCloseVideo = () => {
    setIsExpanded(false);
  };

  const onSuccessUploading = (record = null) => {
    setRecording(record?.file || null);
    if (record) setUploadingRecording(false);
  };

  const uploadEvents: UploadEvents = {
    onFileSelected: (file) => {
      setSelectedFile(file);
      setUploadingRecording(true);
    },
    onFileLoaded: setSelectedFile,
    onSuccess: onSuccessUploading,
    onCloseModal: () => setUploadingRecording(false),
  };

  const onUploadRecording = () => {
    uploadRecording(id, uploadEvents);
  };

  const onEditRecording = () => {
    const record = sessionRecording?.file ?? sessionRecording ?? selectedFile;
    editRecording(id, record, onSuccessUploading);
  };

  const onRemoveRecording = () => {
    removeRecording(id, onSuccessUploading);
  };

  const onAbortUpload = () => {
    setUploadingRecording(false);
    selectedFile?.abort?.();
  };

  const getProgress = () => {
    if (!selectedFile) return DEFAULT_PROGRESS;
    const { progress = 0 } = selectedFile;
    return progress + DEFAULT_PROGRESS;
  };

  useEffect(() => {
    if (recording) {
      setVideoPlayerClass(isExpanded ? 'video-expanded' : 'video-compact');
    }
  }, [recording, isExpanded]);

  useEffect(() => {
    if (sessionRecording?.uploading) {
      setUploadingRecording(true);
      setSelectedFile(sessionRecording.file);
    }
  }, [sessionRecording]);

  return (
    <div className={`session-card d-flex p-4 mt-5 ${videoPlayerClass}`} css={styles}>
      {recording && (
        <div className='session-recording d-flex align-items-center'>
          <div className='d-flex recording-header justify-content-between mb-2 w-100'>
            <div className='gray-2'>{recording.name}</div>
            <div onClick={onCloseVideo} data-qa={config.pendo.liveEvent.liveSesssionCard.closeRecording}>
              <i className='icon-close' />
            </div>
          </div>
          <NvVideoPreview
            ref={playerInstanceRef}
            file={{ ...recording, hasBeenTranscoded: true }}
            autoStart={isExpanded}
            {...eventHandlers}
          />
        </div>
      )}
      {uploadingRecording && (
        <div className='session-recording uploader d-flex align-items-center'>
          <NvFileUploadProgress
            hasUploadPercentage
            uploadInProgress={selectedFile?.uploadInProgress ?? true}
            showFileName={false}
            fileUploading={{ ...selectedFile, progress: getProgress() }}
            abortUpload={onAbortUpload}
            className='w-100 file-upload-progress'
          />
        </div>
      )}
      <div className='session-body d-flex'>
        <LiveSessionCardTitle
          title={title}
          status={cardBadge}
          allowUserRegistration={allowUserRegistration}
        />
        <div
          className='session-description gray-1 mt-2 mb-2'
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        <LiveSessionTiming duration={duration} startTime={startTime} />
      </div>
      {recording && recording?.isDownloadable && (
        <a className='h-100' href={recording.url} rel='noreferrer' target='_blank' download>
          <NvIcon icon='download' size='small' className='gray-1' />
        </a>
      )}
      {shouldShowSessionStatus && (
        <div className='session-actions d-flex'>
          <LiveSessionCardAction
            status={sessionAction.status}
            disabled={sessionAction.disabled}
            joinUrl={session.joinUrl}
            onClick={onCardActionClicked}
          />
          <LiveSessionCardState
            status={sessionStatus}
            allowUserRegistration={allowUserRegistration}
            onClick={unregister}
          />
        </div>
      )}
      {isEdit && (
        <LiveSessionCardEditOptions
          disabled={!sessionSettings.ended}
          hasRecording={!!recording}
          uploadAttendeeList={uploadAttendeeList}
          uploadRecording={onUploadRecording}
          editRecording={onEditRecording}
          removeRecording={onRemoveRecording}
          delete={deleteSession}
        />
      )}
    </div>
  );
};

export default LiveSessionCard;
