import { RolesService } from 'institutions/services/roles-service';
import { LhsSectionMenuItem, LhsSectionProps } from 'lhs/components/lhs-menu-section';
import { config } from '../../../config/pendo.config.json';
import enUS from '../../../languages/en_US';
import {
  AdminToolsPaths,
  adminToolsRelatedPaths,
} from './admin-tools-flyout-config';
import { FlyoutNames, flyoutConfig } from './flyout-config';

enum LhsSectionsIds {
  FIRST = 'lhs-section-1',
  SECOND = 'lhs-section-2',
  THIRD = 'lhs-section-3',
  FOURTH = 'lhs-section-4',
}

/* @ngInject */
export function getLhsSections(
  t: typeof enUS,
  $scope,
  $state,
  $timeout,
  isExpandedLHS: boolean,
  currentStateName: string,
  currentFlyoutName: string,
  FlyoutModalManager,
  CurrentPermissionsManager,
  CurrentUserManager,
  currentUserMentoringInfo,
  InteroperabilityRoutes,
  allCourses,
  currentUserId,
  hasMemberships,
  discoveryEnabled,
) {
  // Getting logged user roles and permissions
  const isOrgAdmin = CurrentPermissionsManager.hasOrgAdminPermissions()
    || CurrentPermissionsManager.hasCourseManagerPermissions();
  const isLearnerInAtLeastOneCourse = CurrentUserManager.user.enrollments.some(enrollment => RolesService.isLearner(enrollment.roles));
  const isMentor = currentUserMentoringInfo?.isMentor;
  const isOrgMentor = CurrentPermissionsManager.hasOrgMentorPermissions();
  const isCourseAdmin = CurrentUserManager.isCourseAdminInAtLeastOneCourse();

  const courseRelatedPaths = [
    'course-home',
    'lecture-page',
    'submissions-gallery',
    'learner-directory',
    'teaching-team-directory',
  ];

  // Setting the logic to show the LHS menu items
  const showAdminTools = isOrgAdmin;
  const showCourseMentees = isMentor;
  const showDashboards = isCourseAdmin || isOrgMentor || isLearnerInAtLeastOneCourse || isMentor || discoveryEnabled;
  const showMyLearning = isLearnerInAtLeastOneCourse || isCourseAdmin;
  const showTeamsGroups = hasMemberships;
  const showVideoPractice = $scope.$parent.MainGridCtrl.practiceRoomEnabled;
  const showContentLibraryCollection = !isOrgAdmin && CurrentUserManager.user.hasEnrolledInAnyCollection;
  const isContentManagementCollection = $scope.$parent.MainGridCtrl?.CurrentCourseManager?.course?.isContentManagementCollection ?? false;

  function isLessonLibraryLecturePage() {
    const lecturePageRegExp = new RegExp(/^lecture-page.*/);
    return isContentManagementCollection && lecturePageRegExp.test(currentStateName);
  }

  function isLessonLibraryPath() {
    const lessonLibraryRegExp = new RegExp(/^content-library-collection.*/);

    return lessonLibraryRegExp.test(currentStateName) || isLessonLibraryLecturePage();
  }

  // Logic to get the highlighted 'Admin Tools' behavior
  function highlighAdminTools() {
    return (
      ((adminToolsRelatedPaths.includes(currentStateName as AdminToolsPaths)
        || isLessonLibraryPath()
      ) && currentFlyoutName === '')
      || currentFlyoutName === FlyoutNames.ADMIN_TOOLS
    );
  }

  // Logic to get the highlighted 'Course Mentees' behavior
  function highlighCourseMentees() {
    return (
      ((
        currentStateName === 'mentees' || (courseRelatedPaths.includes(currentStateName) && !showMyLearning)
      ) && currentFlyoutName === '')
      || currentFlyoutName === FlyoutNames.COURSE_MENTEES
    );
  }

  // Logic to get the highlighted 'Dashboards' behavior
  function highlighDashboards() {
    const relatedPaths = [
      'dashboard',
      'mentor-dashboard',
      'course-admin-dashboard',
    ];

    return relatedPaths.includes(currentStateName) && currentFlyoutName === '';
  }

  // Logic to get the highlighted 'My Learning' behavior
  function highlighMyLearning() {
    return (
      !isContentManagementCollection && (
        (courseRelatedPaths.includes(currentStateName) && currentFlyoutName === '')
        || currentFlyoutName === FlyoutNames.MY_LEARNING)
    );
  }

  // Logic to get the highlighted 'content management' behavior
  function highlighContentManagement() {
    return currentFlyoutName === '' && isLessonLibraryPath();
  }

  // Logic to handle the 'Dashboards' onClick
  function dashboardsOnClick() {
    if (CurrentUserManager.isCourseAdminInAtLeastOneCourse()) {
      $state.go('course-admin-dashboard', null, { replace: true });
    } else if (CurrentPermissionsManager.hasOrgMentorPermissions()) {
      $state.go('mentor-dashboard', null, { replace: true });
    } else {
      $state.go('dashboard', null, { replace: true });
    }
  }

  // Logic to handle the 'Course Mentees' onClick
  function courseMenteesOnClick() {
    // If the user is mentor to only one course, it will directly take the user to the mentee dashboard, otherwise it should open the flyout
    if (currentUserMentoringInfo?.totalOfMentoringCourses === 1) {
      window.location.href = InteroperabilityRoutes.myMentees(
        allCourses[currentUserMentoringInfo?.catalogId],
        currentUserId,
      );
    } else {
      toggleFlyout(FlyoutNames.COURSE_MENTEES);
    }
  }

  // Config and logic for flyout behavior
  function toggleFlyout(id: FlyoutNames) {
    if (id === currentFlyoutName && FlyoutModalManager.isVisible()) {
      $timeout(() => {
        FlyoutModalManager.closeFlyout();
      });
      return;
    }

    FlyoutModalManager.openFlyout({
      ...flyoutConfig[id],
      name: id,
    });
  }

  // Logic to handle the 'Dashboards' onClick
  function contentLibraryOnClick() {
    $state.go('content-library-collections', null, { replace: true });
  }

  // Defining the initial structure of the LHS Sections
  const lhsSections: LhsSectionProps[] = [
    {
      id: LhsSectionsIds.FIRST,
      borderTop: true,
      menuItems: [],
    },
    {
      id: LhsSectionsIds.SECOND,
      borderTop: true,
      menuItems: [],
    },
    {
      id: LhsSectionsIds.THIRD,
      borderTop: true,
      menuItems: [],
    },
    {
      id: LhsSectionsIds.FOURTH,
      borderTop: true,
      menuItems: [
        {
          show: true,
          label: t.LHS.BOOKMARKS.OPEN_BUTTON_LABEL(),
          icon: 'bookmark-and-notes',
          highlighted: currentFlyoutName === FlyoutNames.BOOKMARKS,
          expanded: isExpandedLHS,
          onClick: () => toggleFlyout(FlyoutNames.BOOKMARKS),
          dataQa: config.pendo.lhs.bookmarks,
        },
        {
          show: true,
          label: t.LHS.CONTENT_SEARCH.OPEN_BUTTON_LABEL(),
          icon: 'search',
          highlighted: currentFlyoutName === FlyoutNames.SEARCH,
          expanded: isExpandedLHS,
          onClick: () => toggleFlyout(FlyoutNames.SEARCH),
          dataQa: config.pendo.lhs.contentSearch,
        },
      ],
    },
  ];

  // Helper funtion to add a LHS Menu Item to the LHS Sections list
  function addLhsMenuItem(lhsSecionId: LhsSectionsIds, lhsMenuItem: LhsSectionMenuItem) {
    lhsSections.find(lhsSection => lhsSection.id === lhsSecionId).menuItems.push(lhsMenuItem);
  }

  // Logic to determine if the LHS Menu Item needs to be added to the LHS Sections list
  if (showAdminTools) {
    addLhsMenuItem(LhsSectionsIds.FIRST, {
      show: showAdminTools,
      label: t.LHS.ADMIN_TOOLS(),
      icon: 'admin-tools',
      highlighted: highlighAdminTools(),
      expanded: isExpandedLHS,
      onClick: () => toggleFlyout(FlyoutNames.ADMIN_TOOLS),
      dataQa: config.pendo.lhs.adminTools.main,
    });
  }

  if (showCourseMentees) {
    addLhsMenuItem(LhsSectionsIds.FIRST, {
      show: showCourseMentees,
      label: currentUserMentoringInfo?.totalOfMentoringCourses === 1 ? `${currentUserMentoringInfo?.courseAlias?.singularizedTitleized} ${currentUserMentoringInfo?.menteeAlias?.pluralizedTitleized}` : t.LHS.COURSE_MENTEES(),
      icon: 'course-mentees',
      highlighted: highlighCourseMentees(),
      expanded: isExpandedLHS,
      onClick: () => courseMenteesOnClick(),
      dataQa: config.pendo.lhs.courseMentees,
    });
  }

  if (showDashboards) {
    addLhsMenuItem(LhsSectionsIds.SECOND, {
      show: showDashboards,
      label: t.LHS.DASHBOARDS(),
      icon: 'dashboard',
      highlighted: highlighDashboards(),
      expanded: isExpandedLHS,
      onClick: dashboardsOnClick,
      dataQa: config.pendo.lhs.dashboards,
    });
  }

  if (showMyLearning) {
    addLhsMenuItem(LhsSectionsIds.SECOND, {
      show: showMyLearning,
      label: t.LHS.MY_LEARNING(),
      icon: 'course',
      highlighted: highlighMyLearning(),
      expanded: isExpandedLHS,
      onClick: () => toggleFlyout(FlyoutNames.MY_LEARNING),
      dataQa: config.pendo.lhs.myLearning,
    });
  }

  if (showTeamsGroups) {
    addLhsMenuItem(LhsSectionsIds.SECOND, {
      show: showTeamsGroups,
      label: t.LHS.TEAMS_AND_GROUPS(),
      icon: 'groups-lhs',
      highlighted: currentFlyoutName === FlyoutNames.TEAMS_GROUPS,
      expanded: isExpandedLHS,
      onClick: () => toggleFlyout(FlyoutNames.TEAMS_GROUPS),
      dataQa: config.pendo.lhs.teamsGroups,
    });
  }

  if (showVideoPractice) {
    addLhsMenuItem(LhsSectionsIds.SECOND, {
      show: showVideoPractice,
      label: t.LHS.PRACTICE(),
      icon: 'practice',
      highlighted: currentFlyoutName === FlyoutNames.PRACTICE_ROOM,
      expanded: isExpandedLHS,
      onClick: () => toggleFlyout(FlyoutNames.PRACTICE_ROOM),
      dataQa: config.pendo.lhs.videoPractice,
    });
  }

  if (showContentLibraryCollection) {
    addLhsMenuItem(LhsSectionsIds.THIRD, {
      show: showContentLibraryCollection,
      label: t.INSTITUTIONS.ADMIN_CONTROLS.CONTENT_LIBRARY(),
      icon: 'content',
      highlighted: highlighContentManagement(),
      expanded: isExpandedLHS,
      onClick: contentLibraryOnClick,
      dataQa: config.pendo.lhs.adminTools.contentLibrary,
    });
  }

  // Returns data for LHS Sections that have menu items and the menu item to be displayed
  return lhsSections.map(section => ({
    ...section,
    menuItems: section.menuItems.filter(item => item.show),
  })).filter(section => section.menuItems.length > 0);
}

export default getLhsSections;
