import React, { useCallback, useContext, useState } from 'react';
import t from 'react-translate';
import { each, isEmpty, isNumber, map, max, reduce, sortBy } from 'underscore';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import uuid from 'react-uuid';
import ClickableContainer from 'components/clickable-container';
import { threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { highTide } from 'styles/global_defaults/colors';
import { getSubmission } from 'redux/selectors/video-practice';
import { roundBasedOnDecimal } from 'shared/utils';
import NvTruncatedTextWithTooltip from 'shared/components/nv-truncated-text-with-tooltip';
import StatusBar from './status-bar';
import { PracticeSubmissionContext } from '../../utils';
import { config } from '../../../../../../config/pendo.config.json';

const styles = css`
  .legend {
    height: ${threeQuartersSpacing}px;
    width: ${threeQuartersSpacing}px;
    border-radius: 4px;
  }
  .bg-prior {
    background: #CEDBF3;
  }
`;

const PriorAttempt = () => {
  const { submissionId } = useContext(PracticeSubmissionContext);

  const [showAll, setShowAll] = useState(false);

  const {
    fillerPercentage,
    fillerWords,
    priorAverage,
    vocalizedPausesCount,
    priorVocalizedPausesCount,
    priorFillerWords,
    userPracticeNumber,
  } = useSelector((state) => getSubmission(state, submissionId));

  const fillerPercentageDiff = fillerPercentage - priorAverage;
  let comparisonKey = '';
  if (!priorAverage && userPracticeNumber === 1) {
    comparisonKey = 'NO_PRIOR_ATTEMPT';
  } else if (fillerPercentageDiff < 0) {
    comparisonKey = 'BELOW';
  } else if (fillerPercentageDiff === 0) {
    comparisonKey = 'EQUAL';
  } else if (fillerPercentageDiff > 0) {
    comparisonKey = 'ABOVE';
  }

  const processArray = useCallback(() => {
    const mergedData: {
      [key: string]: { label: string, current: number, prev: number }
    } = reduce([fillerWords, priorFillerWords], (result, array) => {
      each(array, ({ label, count }) => {
        if (!result[label]) {
          result[label] = { label, current: 0, prev: 0 };
        }

        // Determine whether to set the count as 'current' or 'prev'
        if (array === fillerWords) {
          result[label].current = count;
        } else {
          result[label].prev = count;
        }
      });
      return result;
    }, {});

    if (vocalizedPausesCount || priorVocalizedPausesCount) {
      mergedData.vocalizedPausesCount = {
        label: `*${t.PRACTICE_ROOM.INSIGHTS.FILLER_WORDS.VOCALIZED_PAUSES()}`,
        current: vocalizedPausesCount,
        prev: priorVocalizedPausesCount,
      };
    }

    const resultArray = sortBy(map(mergedData, (value) => value), 'current').reverse();
    // Finding the largest value from the array for calculating width of each status bar
    // The iteratee for the max function will be the largest of current/prev from each item
    const largest = max(resultArray, item => Math.max(item.current, item.prev));
    const maxValue = isNumber(largest) ? largest : Math.max(largest.current, largest.prev);

    return { resultArray, maxValue };
  }, [
    fillerWords, priorFillerWords,
    priorVocalizedPausesCount, vocalizedPausesCount,
  ]);

  const { maxValue, resultArray } = processArray();
  const fillerWordsToDisplay = resultArray.length > 5 && !showAll
    ? resultArray?.slice(0, 5) : resultArray;
  const fillerPercentageDiffRounded = roundBasedOnDecimal(Math.abs(fillerPercentageDiff));

  return (
    <div css={styles}>
      {fillerPercentage === 0 && (
        <React.Fragment>
          <div className='my-4 text-gray-1'>
            {t.PRACTICE_ROOM.INSIGHTS.FILLER_WORDS.NO_FILLER_WORDS()}
          </div>
          {priorAverage > 0 && (
            <div className='my-4 text-gray-1'>
              {t.PRACTICE_ROOM.INSIGHTS.FILLER_WORDS.PRIOR_ATTEMPT_COMPARISON.BELOW(fillerPercentageDiffRounded.toString())}
            </div>
          )}
        </React.Fragment>
      )}
      {fillerPercentage > 0 && (
        <div className='my-4 text-gray-1'>
          {t.PRACTICE_ROOM.INSIGHTS.FILLER_WORDS.PRIOR_ATTEMPT_COMPARISON[comparisonKey](fillerPercentageDiffRounded)}
        </div>
      )}
      {fillerWordsToDisplay?.map(fillerWord => (
        <div className='d-flex mt-2' key={uuid()}>
          <NvTruncatedTextWithTooltip
            text={fillerWord.label}
            className='text-gray-2 label text-right mr-2'
            textWidth={100}
          />
          <div className='d-flex flex-column w-100'>
            <div className='d-flex align-items-center'>
              <StatusBar
                maxValue={maxValue}
                activeColor={highTide}
                currentValue={fillerWord.current}
              />
              <div className='text-black label ml-1'>
                {fillerWord.current}
              </div>
            </div>
            {userPracticeNumber > 1 ? (
              <div className='d-flex align-items-center'>
                <StatusBar
                  maxValue={maxValue}
                  activeColor='#CEDBF3'
                  currentValue={fillerWord.prev}
                />
                <div className='text-black label ml-1'>
                  {fillerWord.prev}
                </div>
              </div>
            ) : ''}
          </div>
        </div>
      ))}
      {resultArray?.length > 5 ? (
        <ClickableContainer
          className='text-primary label mt-4'
          onClick={() => setShowAll(!showAll)}
          data-qa={config.pendo.practice.toggleShowFillerWords}
        >
          {showAll ? t.PRACTICE_ROOM.INSIGHTS.FILLER_WORDS.SHOW_LESS() : t.PRACTICE_ROOM.INSIGHTS.FILLER_WORDS.SHOW_ALL()}
        </ClickableContainer>
      ) : ''}
      {!isEmpty(priorFillerWords) && userPracticeNumber > 1 ? (
        <div className='d-flex mb-2 mt-4'>
          {[{ label: t.PRACTICE_ROOM.INSIGHTS.FILLER_WORDS.CURRENT_ATTEMPT(), bgColor: 'bg-high-tide' },
            { label: t.PRACTICE_ROOM.INSIGHTS.FILLER_WORDS.PRIOR_ATTEMPT(), bgColor: 'bg-prior' },
          ].map(item => (
            <div className='d-flex mr-4' key={uuid()}>
              <div className={`legend ${item.bgColor} mr-1`} />
              <div className='label text-gray-2'>{item.label}</div>
            </div>
          ))}
        </div>
      ) : ''}
      {(!!vocalizedPausesCount || !!priorVocalizedPausesCount) && (
        <div className='label text-gray-3 mt-4'>
          {t.PRACTICE_ROOM.INSIGHTS.FILLER_WORDS.FOOT_NOTE()}
        </div>
      )}
    </div>
  );
};

export default PriorAttempt;
