/**
 * This file exists purely because the experimental package we're using to pull in react-fast-refresh with Webpack
 * (https://github.com/pmmmwh/react-refresh-webpack-plugin)
 * has a bug here https://github.com/pmmmwh/react-refresh-webpack-plugin/issues/81 where modules may become
 * `undefined` after a refresh if they have cyclic dependencies
 * I'll look for a better way to support this in the future - Nathan
 */

import { NLecturePage } from 'redux/schemas/models/lecture-page';
import { MutableRefObject } from 'react';
import { ComponentTrueType, NLectureComponent } from 'redux/schemas/models/lecture-component';
import LectureComponentBaseModel from 'lecture_pages/services/components/lecture-component-base-model';

/** The mode of the entire lecture page, used in place of the isViewMode, isEditMode, etc booleans
 * in the Angularjs LecturePageManager */
export enum LecturePageMode {
  VIEW = 'view',
  EDIT = 'edit',
  RESTRICTED_EDIT = 'restrictedEdit',
  REORDER = 'reorder',
  LINKED_EDIT = 'linkedEdit',
}

export type LectureComponentProps<C extends ComponentTrueType = any> = {
  containerRef: MutableRefObject<HTMLDivElement>,
  /* The following values are available on Redux but supplied to all LCs for convenience */
  lectureComponent: NLectureComponent<C>,
  mode: LecturePageMode,
  currentLecture: NLecturePage,
  angularComponent?: InstanceType<ReturnType<typeof LectureComponentBaseModel>>,
};


export interface LecturePageSyncEvent {
  contentManagementCollectionId: number,
  lecturePageId: number,
}
