import { css } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useImperativeHandle, forwardRef, useState, memo, Dispatch, SetStateAction, useRef, Fragment } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import t from 'react-translate';
import useTimer from 'recording/hooks/use-timer';
import NvPopover from 'shared/components/nv-popover';
import {
  VideoPracticeActivity, VideoPracticeScenario,
  VideoPracticeOption, VideoPracticeType, PracticeAgain, RecordingFormat,
} from 'redux/schemas/models/video-practice';
import { largeSpacing, quarterSpacing, halfSpacing, standardSpacing, doubleSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import AudioBlobPreview from 'recording/components/audio-blob-preview';
import VideoBlobPreview from 'recording/components/video-blob-preview';
import NvVideoPreview from 'shared/components/nv-video-preview';
import useUploadFile, { convertBlobToFile } from 'shared/hooks/use-upload-file';
import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import { hexToRgbaString, black, white } from 'styles/global_defaults/colors';
import { textSmallFontSize } from 'styles/global_defaults/fonts';
import { desktop, handheld, largeDesktop, notHandheld, screenXsMax } from 'styles/global_defaults/media-queries';
import { useAppDispatch } from 'redux/store';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import CountdownTimer from './countdown-timer';
import VideoPracticeTitle from './video-practice-title';
import PracticeActivityProgress from './practice-activity-progress';
import { getTimeLimits, roundToMinutes } from './video-practice-utils';
import { config } from '../../../../config/pendo.config.json';


type ReviewPracticeActivityProps = {
  practiceType: VideoPracticeType;
  scenario: VideoPracticeScenario;
  activity?: VideoPracticeActivity;
  modalStepIndex: number;
  onRetry: () => void;
  onSubmit: (e?: any) => void;
  take: number;
  blob: Blob;
  isSubmitting: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  restart: () => void;
  isReviewTimeExpired: boolean;
  setIsReviewTimeExpired: Dispatch<SetStateAction<boolean>>;
  showPrompt: boolean;
  setShowPrompt: Dispatch<SetStateAction<boolean>>;
  promptFontSize: number;
  setPromptFontSize: Dispatch<SetStateAction<number>>;
};

const Prompt = ({ scenario, onlyVideo, increaseFontSize, decreaseFontSize }) => (onlyVideo ? (
  <div className='video-wrapper overflow-hidden'>
    <NvVideoPreview file={scenario.videoPrompt} />
  </div>
) : (
  <div>
    <div className='font-size-change'>
      <div className='font-button' onClick={() => increaseFontSize()}>T+</div>
      <div className='font-button-divider' />
      <div className='font-button' onClick={() => decreaseFontSize()}>T–</div>
    </div>
    <div className='prompt'>
      {/* eslint-disable-next-line react/no-danger */}
      <div className='text-prompt' dangerouslySetInnerHTML={{ __html: scenario.textPrompt }} />
      {scenario.videoPrompt && (
        <div className='video-wrapper'>
          <NvVideoPreview file={scenario.videoPrompt} />
        </div>
      )}
    </div>
  </div>
));

const MemoizedPrompt = memo(Prompt);

const Recording = ({ blob, isAudio }) => (isAudio ? <AudioBlobPreview blob={blob} /> : <VideoBlobPreview blob={blob} width={720} />);
const MemoizedRecording = memo(Recording);

const ReviewPracticeActivity = forwardRef<any, ReviewPracticeActivityProps>(({
  practiceType,
  scenario,
  activity,
  modalStepIndex,
  onRetry,
  onSubmit,
  take,
  blob,
  isSubmitting,
  setIsSubmitting,
  restart,
  isReviewTimeExpired,
  setIsReviewTimeExpired,
  showPrompt,
  setShowPrompt,
  promptFontSize,
  setPromptFontSize,
}, ref) => {
  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const styles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${hexToRgbaString(black, 0.95)};
    flex: 1;
    ${largeDesktop(css`
      min-height: 0;
    `)}

    .practice-activity-progress {
      margin-top: 60px;
      margin-bottom: 50px;
    }

    .course-title-small {
      margin-bottom: ${largeSpacing}px;
    }

    .details {
      display: flex;
      justify-content: space-between;
      width: 720px;
      margin-bottom: ${quarterSpacing}px;

      .video-practice-title {
        margin-right: ${practiceType === VideoPracticeOption.ON_SPOT ? '100px' : 'initial'};
      }
    }

    .prompt {
      overflow-y: auto;
      background-color: ${black};
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      margin-top: ${doubleSpacing}px;

      ${notHandheld(css`
        width: 960px;
        max-height: 480px;
        overflow-y: auto;
        flex: 1;
      `)};
    }

    .text-prompt {
      width: 720px;
      color: white;
      margin-top: ${standardSpacing}px;
      margin-bottom: ${largeSpacing}px;

      &, & > * {
        font-size: ${promptFontSize}px !important;
        line-height: ${Math.round(promptFontSize * 1.5)}px !important;
      }
    }

    .video-wrapper {
      width: 720px;
      max-width: 100%;
      .nv-video-preview, .clearfix, .jwplayer-container, .jwplayer {
        height: 100% !important;
      }
    }

    .font-size-change {
      color: ${white};
      background-color: ${black};
      padding: ${halfSpacing}px;

      display: flex;
      flex-direction: column;
      position: relative;
      float: right;
      top: ${doubleSpacing}px;

      font-weight: bold;
      font-size: 18px;

      .font-button {
        cursor: pointer;
      }

      .font-button-divider {
        background-color: ${white};
        height: 1px;
        width: 100%;
        margin-top: ${halfSpacing}px;
        margin-bottom: ${halfSpacing}px;
      }
    }

    .hr-divider {
      background-color: ${white};
      height: 2px;
      width: 100%;
      margin-top: ${standardSpacing}px;
      margin-bottom: ${standardSpacing}px;
    }

    .prompt-button {
      border-radius: ${halfSpacing}px;
      align-self: center;
    }

    video {
      margin-bottom: ${standardSpacing}px;
      min-height: 0;
    }

    .in-progress {
      width: 720px;
      height: 400px;
      background-color: ${black};
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: ${standardSpacing}px;

      .progress-bar-container {
        width: 600px;

        .bs4-progress {
          border-radius: 0;
        }

        .cancel {
          display: inline;
          cursor: pointer;
          font-size: ${textSmallFontSize}px;
        }
      }
    }

    .details-text {
      max-width: 720px;
    }

    .button-container {
      display: flex;
      margin-top: ${tripleSpacing}px;
      margin-bottom: ${doubleSpacing}px;

      button:first-of-type {
        margin-right: ${halfSpacing}px;
      }
    }

    ${handheld(css`
      .practice-activity-progress {
        margin-bottom: 0;
      }

      .details {
        width: 100%;
        padding-right: ${standardSpacing}px;
        padding-left: ${standardSpacing}px;

        .video-practice-title {
          margin-right: ${standardSpacing}px;
        }
      }

      .prompt {
        width: 100%;
        padding: ${standardSpacing}px;

        .text-prompt {
          width: 100%;
          margin-top: 0;
        }

        .video-wrapper {
          width: 100%;
        }
      }

      video {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
      }

      .details-text {
        width: 100%;
        padding-right: ${standardSpacing}px;
        padding-left: ${standardSpacing}px;
      }

      .button-container {
        width: 100%;
        flex-direction: column-reverse;
        padding-right: ${standardSpacing}px;
        padding-left: ${standardSpacing}px;

        button:first-of-type {
          margin-top: ${halfSpacing}px;
        }
      }
    `)};
  `;

  const dispatch = useAppDispatch();

  const [isPaused, setIsPaused] = useState<boolean>(false);

  const { timeToReview } = getTimeLimits(scenario);
  const timeToReviewRounded = roundToMinutes(timeToReview);

  const [reviewCountdown, startTimer, pauseTimer, resumeTimer] = useTimer();

  const { uploadFiles, isUploading, filesUploading, abortUpload } = useUploadFile();

  const pause = () => {
    pauseTimer();
    setIsPaused(true);
    if (isSubmitting) {
      abortUpload();
    }
    setIsSubmitting(false);
  };

  const resume = () => {
    if (practiceType === VideoPracticeOption.ON_SPOT && isReviewTimeExpired && take < activity.maxTries) {
      dispatch(openConfirmationDialog({
        noCancelButton: true,
        title: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RETRY.CANCEL.TITLE(),
        bodyText: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RETRY.CANCEL.ON_SPOT(activity.maxTries - take),
        confirmText: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RETRY.CANCEL.TAKE_ME_THERE(),
        onConfirm: restart,
        confirmButtonVariant: 'primary',
      }));
    } else {
      resumeTimer();
      setIsPaused(false);
    }
  };

  useImperativeHandle(ref, () => ({
    pause,
    resume,
  }));

  const submit = async () => {
    pause();
    setIsSubmitting(true);
    const file = convertBlobToFile(blob);
    const [novoedFile] = await uploadFiles([file], S3NameSpaces.VIDEO_PRACTICE);
    if (novoedFile) {
      onSubmit(novoedFile);
    }
  };

  const onCancel = () => {
    abortUpload();
    setIsSubmitting(false);

    if (practiceType === VideoPracticeOption.ON_SPOT && isReviewTimeExpired && take < activity.maxTries) {
      dispatch(openConfirmationDialog({
        noCancelButton: true,
        title: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RETRY.CANCEL.TITLE(),
        bodyText: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RETRY.CANCEL.ON_SPOT(activity.maxTries - take),
        confirmText: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RETRY.CANCEL.TAKE_ME_THERE(),
        onConfirm: restart,
        confirmButtonVariant: 'primary',
      }));
    } else {
      resume();
    }
  };

  const timeExpired = () => {
    setIsReviewTimeExpired(true);
    submit();
  };

  useEffect(() => {
    if (practiceType === VideoPracticeOption.ON_SPOT) {
      startTimer(timeToReview, timeExpired);
    }
  }, []);

  const noMoreRetries = practiceType === VideoPracticeOption.ON_SPOT && take >= activity.maxTries;
  const isRetryDisabled = noMoreRetries || isSubmitting;
  const title = practiceType === VideoPracticeOption.ON_SPOT
    ? t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.REVIEW.TITLE.ON_SPOT(timeToReviewRounded)
    : t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.REVIEW.TITLE.WHEN_READY();
  const fileUploading = filesUploading[0];

  const cancelOptionAvailable = (practiceType === PracticeAgain.PRACTICE_AGAIN
    || practiceType === VideoPracticeOption.WHEN_READY
    || (practiceType === VideoPracticeOption.ON_SPOT
      && (take < activity.maxTries || !isReviewTimeExpired)
    ));

  return (
    <div css={styles}>
      {(showPrompt && !isSubmitting) ? (
        <div className='d-flex flex-column align-items-center'>
          <MemoizedPrompt
            scenario={scenario}
            onlyVideo={scenario.videoPrompt && !scenario.textPrompt}
            increaseFontSize={() => setPromptFontSize(size => size + 2)}
            decreaseFontSize={() => setPromptFontSize(size => size - 2)}
          />
          <div className='hr-divider' />
          <Button
            className='prompt-button mb-4'
            onClick={() => setShowPrompt(false)}
          >
            {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORD.HIDE_PROMPT()}
          </Button>
        </div>
      ) : (
        <Fragment>
          <PracticeActivityProgress modalStepIndex={modalStepIndex} />
          <div className='course-title-small text-white'>{title}</div>
          {!isSubmitting && (
            <Button
              className='prompt-button'
              onClick={() => setShowPrompt(true)}
            >
              {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORD.SHOW_PROMPT()}
            </Button>
          )}
        </Fragment>
      )}
      <div className='details'>
        <VideoPracticeTitle
          practiceType={practiceType}
          activityTitle={scenario.title}
          activity={activity}
          take={take}
        />
        {practiceType === VideoPracticeOption.ON_SPOT && (
          <CountdownTimer countdownRemainingSeconds={reviewCountdown || 0} paused={isPaused} />
        )}
      </div>
      {(isUploading && fileUploading) ? (
        <div className='in-progress'>
          <div className='progress-bar-container'>
            <ProgressBar now={fileUploading.uploadPercentage} />
            <div className='text-center mt-1'>
              <span className='text-center text-label text-gray-3'>{t.FILE_UPLOAD.UPLOADING()}</span>
              {cancelOptionAvailable && (
                <span className='ml-2 text-primary cancel' onClick={onCancel}>{t.FORM.CANCEL()}</span>
              )}
            </div>
          </div>
        </div>
      ) : (
        <MemoizedRecording blob={blob} isAudio={scenario.recordingFormat === RecordingFormat.AUDIO} />
      )}
      <div className='details-text'>
        {practiceType === VideoPracticeOption.ON_SPOT && (
          <div className={`${isHandheld ? 'text-regular' : 'text-large-body'} text-gray-4 text-center`}>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.REVIEW.AUTO_SUBMIT_MSG()}</div>
        )}
        <div className={`${isHandheld ? 'text-regular' : 'text-large-body'} text-gray-4 text-center`}>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.REVIEW.VIEWABLE_BY()}</div>
      </div>
      <div className='button-container'>
        <NvPopover
          showOnHover
          enabled={!isRetryDisabled}
          placement='top'
          content={(
            <div>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RETRY.DETAILS()}</div>
          )}
        >
          <Button
            variant='secondary'
            block={isHandheld}
            disabled={isRetryDisabled || isSubmitting}
            onClick={onRetry}
            pendo-tag-name={config.pendo.practice.retry}
            data-qa={`${config.pendo.practiceActivity.learner.retry}_${scenario.recordingFormat}`}
          >
            {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RETRY.TITLE()}
          </Button>
        </NvPopover>
        <Button
          block={isHandheld}
          disabled={isSubmitting}
          onClick={submit}
          pendo-tag-name={config.pendo.practice.submit}
          data-qa={`${config.pendo.practiceActivity.learner.submit}_${scenario.recordingFormat}`}
        >
          {isSubmitting ? t.FORM.SUBMITTING_IN_PROGRESS() : t.FORM.SUBMIT()}
        </Button>
      </div>
    </div>
  );
});

export default ReviewPracticeActivity;
