import React, { useContext } from 'react';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import { getSubmission } from 'redux/selectors/video-practice';
import { useMediaQuery } from 'react-responsive';
import { screenXsMax } from 'styles/global_defaults/media-queries';
import ClickableContainer from 'components/clickable-container';
import { useAppDispatch } from 'redux/store';
import { generateInsights } from 'redux/actions/video-practice';
import PeerComparison from './peer-comparison';
import PriorAttempt from './prior-attempt';
import { PracticeSubmissionContext } from '../../utils';
import InsightsCount from '../insights-count';
import { config } from '../../../../../../config/pendo.config.json';

const FillerWords = () => {
  const { submissionId, scenarioId } = useContext(PracticeSubmissionContext);

  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const {
    fillerPercentage,
    peerAverage,
    user,
    fillerInsightStatus,
    peerSubmissionsCount,
  } = useSelector((state) => getSubmission(state, submissionId));
  const ctaMessage = t.PRACTICE_ROOM.INSIGHTS.FILLER_WORDS.CTA('INPUT').split('INPUT');

  const dispatch = useAppDispatch();

  return (
    <div className='border-top border-gray-4 pt-6'>
      <div className={`d-flex ${isHandheld ? 'flex-column' : 'flex-row'}`}>
        {(fillerInsightStatus === 'not_started' || fillerInsightStatus === 'disabled') ? (
          <React.Fragment>
            <InsightsCount
              showCount={false}
              label={t.PRACTICE_ROOM.INSIGHTS.FILLER_WORDS.LABEL()}
              className='text-high-tide'
            />
            <div className={`d-flex text-gray-1 ${!isHandheld ? 'ml-4' : 'p-2'}`}>
              {ctaMessage[0]}
              <ClickableContainer
                className='text-primary mx-1'
                onClick={() => dispatch(generateInsights({
                  submissionId,
                  scenarioId,
                  generateFillerWords: true,
                }))}
                data-qa={config.pendo.practice.generateFillerWords}
              >
                {t.PRACTICE_ROOM.INSIGHTS.FILLER_WORDS.CLICK_HERE()}
              </ClickableContainer>
              {ctaMessage[1]}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <InsightsCount
              count={`${fillerPercentage}%`}
              label={t.PRACTICE_ROOM.INSIGHTS.FILLER_WORDS.LABEL()}
              className={fillerPercentage > 0 ? 'text-high-tide' : 'text-success'}
            />
            <div className={`d-flex flex-column ${!isHandheld ? 'ml-4 pt-2' : 'p-2'}`}>
              {peerAverage === 0 && peerSubmissionsCount === 0 ? (
                <div className='text-gray-1'>
                  {t.PRACTICE_ROOM.INSIGHTS.FILLER_WORDS.PEER_COMPARISON.NO_PEERS()}
                </div>
              ) : (
                <PeerComparison
                  fillerPercentage={fillerPercentage}
                  peerAverage={peerAverage}
                  user={user}
                />
              )}
              <PriorAttempt />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default FillerWords;
