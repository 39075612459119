import { css } from '@emotion/react';
import moment from 'moment';
import t from 'react-translate';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { contains, difference, every, find, isEmpty, pick, values } from 'underscore';

// Schemas
import {
  PracticeRoomTab, SubmissionTab, PracticeSubmissionComment, RecordingFormat,
} from 'redux/schemas/models/video-practice';
import {
  DeleteCommentParams,
  Post, PostCommentParams, PracticeSubmissionActionParams, UpdateCommentParams,
} from 'redux/schemas/api/video-practice';
import { SkillTag } from 'redux/schemas/models/skill-tag';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { SkillsFeedbackRequest } from 'redux/schemas/api/skills-feedback';

import {
  getVideoPracticeSubmissionLikers,
  likeVideoPracticeSubmission,
  postComment,
  undoLikeVideoPracticeSubmission,
  likeComment,
  undoLikeComment,
  getCommentLikers,
  deleteComment,
  updateComment,
  setFilteredComments,
  postVideoHasViewed,
  getMentionableUsers,
  getComments,
  clearNewNotification,
} from 'redux/actions/video-practice';
import { setShowFeedbackInstruction } from 'redux/actions/video-practice-feedback';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { getAllFeedback, getSummary, deleteFeedback } from 'redux/actions/skills-feedback';

// Selectors
import {
  getBadges, getScenario, getSubmission, getSubmissionComments,
  getMentionableUsersForSubmission,
} from 'redux/selectors/video-practice';
import { getCurrentUserId } from 'redux/selectors/timeline';
import { authorRatingsSorted } from 'redux/selectors/skills-feedback';

// Hooks
import { AngularContext, AngularServicesContext } from 'react-app';
import { NovoEdFile } from 'shared/hooks/use-upload-file';

// Styles
import { gray4, gray7, warning } from 'styles/global_defaults/colors';
import {
  doubleSpacing,
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';

// Components
import NvCommentRow from 'shared/components/nv-comment-row';
import NvIcon from 'shared/components/nv-icon';
import NvVideoPreview, { NvVideoPreviewImperativeInterface } from 'shared/components/nv-video-preview';
import NvLikeButton from 'shared/components/nv-like-button';
import NvCommentButton from 'practice_room/components/shared/nv-comment-button';
import LoadingWrapper, { LoaderType } from 'shared/components/loading-wrapper';
import NvCommentForm from 'shared/components/nv-comment-form';
import { Badge } from 'shared/components/nv-seekbar-badges';
import ClickableContainer from 'components/clickable-container';
import NvTooltip from 'shared/components/nv-tooltip';
import { NvResponsiveTabsDisplayType, NvTab } from 'shared/components/nv-responsive-tabs';
import NvResponsiveTabsRow from 'shared/components/nv-responsive-tabs-row';

import { useTimelineService } from 'timelines/services/react-timeline-service';
import SkillFeedback from 'shared/components/skill-feedback/skill-feedback';
import RatingSummary, { FEEDBACK_PAGE_SIZE } from 'shared/components/skill-feedback/rating-summary';
import AuthorFeedback from 'shared/components/skill-feedback/author-feedback';
import DisplaySkillRating from 'shared/components/skill-feedback/display-skill-ratings';
import { feedbackMapping } from 'redux/selectors/video-practice-feedback';
import { ComponentType, LectureComponent } from 'redux/schemas/models/lecture-component';
import { CombinedCourse, RootState } from 'redux/schemas';
import { getCurrentCourse } from 'redux/selectors/course';
import RecordVideoComment from '../submission/record-video-comment';
import PracticeSubmissionOptions from './practice-submission-options';
import { config } from '../../../../config/pendo.config.json';
import { FeedbackInstructionModal, FeedbackInstructionPopover } from './feedback-instruction';
import InsightsTab from './insights/insights-tab';
import { INITIAL_COMMENTS_PER_PAGE, bgGradient, rtlBgGradient, selectedViewToIndex, usePrevious, Tab, PracticeSubmissionContext } from './utils';

type VideoPracticeSubmissionProps = {
  submissionId: number
  // *2nd* Practice Recorded on 12/18/2020 4:40 PM
  index?: number
  skillTags?: SkillTag[]
  showSubmissionOptions?: boolean
  isPracticeFeedback?: boolean
  scenarioId?: number
  submissionIds?: number[]
  lectureComponentId?: ComponentType
  isNext?: boolean
  onNext?: () => void
  onFirst?: () => void
  onClose?: () => void
};

const styles = (isMyPractice: boolean, isPracticeRoom: boolean, isAudio: boolean) => css`
  .social-actions {
    height: ${tripleSpacing}px;
    background-color: ${gray7};
    .action-item {
      .icon {
        ${isMyPractice && 'pointer-events: none;'}
        margin-right: ${quarterSpacing}px;
        html[dir="rtl"] & {
          margin-left: ${quarterSpacing}px;
        }
      }
      .likes {
        cursor: pointer;
      }
    }
  }

  .featured {
    width: ${doubleSpacing}px;
    height: ${standardSpacing}px;
    background-color: ${warning};
    right: -${quarterSpacing}px;
    top:-${quarterSpacing}px;
    z-index: 1;
    &:after {
      content: '';
      width: 0;
      height: 0;
      background: transparent;
      position: absolute;
      bottom: -${quarterSpacing}px;
      right: 0;
      border-style: solid;
      border-color: transparent transparent transparent ${gray4};
      border-width: 0 0 ${quarterSpacing}px ${quarterSpacing}px;

      html[dir="rtl"] & {
        border-color: ${gray4} transparent transparent transparent;
        border-width: ${quarterSpacing}px 0px 0px ${quarterSpacing}px;
      }
    }
  }

  .player-container {
    ${isAudio && css`
      .nv-video-preview {
        video {
          height: 55px;
        }
      }
    `}

    .comment, .comment-and-feedback {
      position: absolute;
      bottom: 5%;
      /*! @noflip */
      right: 100px;
    }

    .jwplayer-container {
      ${isAudio && css`

        .jwplayer {
          height: 100px !important;

          .jw-controlbar {
            height: 100px !important;
            max-height: 100px !important;

            align-content: center;
          }
        }
      `}

      .nv-seekbar-badge-wrapper {
        width: 100%;
        display: flex;
        margin: 0 12px;
        position: relative;

        .nv-seekbar-badge {
          position: absolute;
          top: -${standardSpacing}px;
          transform: translateX(-${halfSpacing}px);
          cursor: pointer;

          &.highlighted {
            top: -${largeSpacing}px;
            transform: translateX(-${threeQuartersSpacing}px);
          }
        }
      }
    }
  }

  .jwplayer-container {
    .jwplayer:not(.jw-state-playing) {
      .jw-media {
        opacity: 0.5;
      }
    }
  }

  .view-more {
    cursor: pointer;
  }

  .submission-tabs-row {
    .tab {
      height: auto;
      min-height: ${tripleSpacing}px;
      padding-top: ${halfSpacing}px;
      padding-bottom: ${halfSpacing}px;
    }
  }

  .submission-action-option {
    .icon {
      margin-top: 2px;
    }
  }

  .skill-feedback {
    display: flex;
    justify-content: flex-end;
    height: ${tripleSpacing}px;
    width: ${tripleSpacing}px;
    border-radius: ${largeSpacing}px;
    background: ${bgGradient};
    cursor: pointer;
    box-shadow: 0px ${quarterSpacing}px ${standardSpacing}px rgba(0, 154, 192, 0.3), 0px ${quarterSpacing}px ${standardSpacing}px rgba(29, 33, 38, 0.2);

    html[dir="rtl"] & {
      background: ${rtlBgGradient};
    }
  }
  .tag-badge {
    border-radius: ${standardSpacing}px;
  }
  .name-container .first-name, .last-name {
    font-size: 12px;
  }
  .own-feedback-container {
    .own-feedback {
      opacity: 0;
    }
    &:hover, &:focus-within {
      .own-feedback {
        opacity: ${isPracticeRoom ? 0 : 1};
      }
    }
  }
  .insights-loader {
    svg {
      width: 100px;
      height: 100px;
    }
  }
  .insight-count {
    height: 140px;
    min-width: 140px;
  }
  .phrase {
    width: fit-content;
    border-radius: ${threeQuartersSpacing}px;
    padding: 3px ${halfSpacing}px;
  }
`;

const PracticeSubmission = ({
  submissionId,
  index: practiceIndex,
  skillTags,
  showSubmissionOptions = true,
  isPracticeFeedback,
  submissionIds,
  ...restProps
}: VideoPracticeSubmissionProps) => {
  const { $uibModal, TimelinesManager } = useContext(AngularServicesContext);
  const { $scope } = useContext(AngularContext);

  const [pageNo, setPageNo] = useState(1);
  const scenarioParam = useSelector((state) => state.app.practiceRoom.params.scenarioId);
  const scenarioId = scenarioParam ?? restProps.scenarioId;
  const practicesubmission = useSelector((state) => getSubmission(state, submissionId));
  const currentUserId = useSelector(getCurrentUserId);
  const isPracticeRoom = showSubmissionOptions;
  const {
    activity,
    isLikedByCurrentUser,
    likedUsers,
    submissionComments,
    featured,
    videoFile,
    numComments,
    numViews,
    numLikes,
    user,
    completedAt: recordedDate,
    isRatedByCurrentUser,
    isViewerMentor,
    hasInsightsEnabled,
    userPracticeNumber,
    hasReviewed,
  } = practicesubmission;
  const {
    isPracticeAdmin, myPracticeVisitedAt,
    hasCourseAdmin,
    recordingFormat,
  } = useSelector((state) => getScenario(state, scenarioId));
  const badges = useSelector((state) => getBadges(state, submissionId));
  const filteredComments = useSelector((state) => state.app.practiceRoom.filteredComments?.[submissionId]);
  const { newNotification, userInteractedWith } = useSelector((state) => state.app.practiceRoom.params);
  const {
    commentsOnceLoaded,
    feedbackOnceLoaded,
    summary,
    summaryOnceLoaded,
    authorFeedback,
    feedbackLoading,
    feedbackByUserOnceLoaded,
    ratingsByUser,
    mentionables,
  } = useSelector((state) => state.app.videoPracticeSubmissions[submissionId]) ?? {};
  const currentInstitutionId = useSelector(
    (state) => state.app.currentInstitutionId,
  );
  const authorRatings = useSelector(state => authorRatingsSorted(state, submissionId, 'videoPracticeSubmissions'));

  const comments = useSelector((state) => getSubmissionComments(state, submissionComments));
  const notificationComment = useSelector((state) => state.models.comments?.[newNotification?.commentId]) ?? null;
  const mentionableUsers = useSelector((state) => getMentionableUsersForSubmission(state, { submissionId }));
  const practiceSubmissionRef = useRef(null);
  const videoRef = useRef(null);
  const {
    submissionId: selectedSubmissionId,
    selectedTab,
    userId: currentUser,
    selectedSubmissionTab,
    feedbackBy,
  } = useSelector((state) => state.app.practiceRoom.params);
  const ratingIds = (ratingsByUser && feedbackBy && ratingsByUser[feedbackBy]) ? ratingsByUser[feedbackBy] : [];
  const { practiceFeedbackCriteriaId, showFeedbackInstruction } = useSelector(state => state.app.videoPracticeFeedback);
  const lectureComponent: LectureComponent = useSelector((state: RootState) => state.models.lectureComponents[restProps.lectureComponentId]);
  const isSkillsFeedbackActivity = lectureComponent?.type === ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK;
  const practiceFeedbackActivity = useSelector(state => {
    const model = feedbackMapping[lectureComponent?.type ?? ComponentType.VIDEO_PRACTICE_FEEDBACK];
    return state.models[model.activitiesKey]?.[practiceFeedbackCriteriaId];
  });
  const currentLectureId = useSelector(state => state.app.lecturePage?.currentLectureId);
  const hasSkillsRating = useSelector((state: RootState) => {
    if (activity) {
      return activity.hasSkillsRating ?? state.models.practiceActivities[activity.id]?.hasSkillsRating;
    }
    return false;
  });

  const currentCourse: CombinedCourse = useSelector((state: RootState) => getCurrentCourse(state));
  const videoPracticeSubmissions = useSelector((state) => state.app.videoPracticeSubmissions);
  const currentEnrollment = useSelector((state: RootState) => state.models.enrollments[currentCourse?.userCourse]) ?? {};
  const scenarioSubmissions = pick(videoPracticeSubmissions, (value, key) => contains(submissionIds, parseInt(key, 10)));
  const allSubmissionCommentsLoaded = every(scenarioSubmissions, (submission) => submission.commentsOnceLoaded === true);

  const isMyPractice = user?.id === currentUserId;
  const hideTabs = isSkillsFeedbackActivity && !isRatedByCurrentUser;
  const dispatch = useAppDispatch();
  const { isCourseAdmin = false } = currentEnrollment;
  const isAdmin = (isCourseAdmin || isPracticeAdmin) && isSkillsFeedbackActivity;
  const [viewMore, setViewMore] = useState(false);
  const [showFeedbackForm, setShowFeedbackForm] = useState(isSkillsFeedbackActivity && !isRatedByCurrentUser && !isMyPractice && !isPracticeRoom);
  const [showCommentButton, setShowCommentButton] = useState(!hideTabs || isAdmin);
  const [moreCommentsLoaded, setMoreCommentsLoaded] = useState(true);
  const [showRecordVideoComment, setShowRecordVideoComment] = useState(false);
  const [showCommentInputRow, setShowCommentInputRow] = useState(false);
  const [currentTimestamp, setCurrentTimestamp] = useState(null);
  const [isNotifiedCommentsSet, setIsNotifiedComments] = useState(false);
  const [selectedView, setSelectedView] = useState<SubmissionTab>(isSkillsFeedbackActivity ? SubmissionTab.AUTHOR_FEEDBACK : SubmissionTab.COMMENTS);
  const [showFeedbackByUser, setShowFeedbackByUser] = useState(false);
  const [showInstructionPopover, setShowInstructionPopover] = useState(false);
  const [isCommentButtonExpanded, setCommentButtonExpanded] = useState(showFeedbackInstruction && isPracticeFeedback);
  const [showFeedbackInstructionModal, setFeedbackInstructionModal] = useState(false);
  const [practiceFeedbackData, setPracticeFeedbackData] = useState<PracticeSubmissionComment>();

  const prevUser = usePrevious(currentUser);

  const timelineService = useTimelineService();
  const catalogId = useSelector((state) => state.app.currentCatalogId);

  let likeIconClass = isLikedByCurrentUser ? 'text-primary' : 'text-gray-3';
  if (isMyPractice) {
    likeIconClass = 'text-gray-5';
  }

  const playerInstanceRef = useRef<NvVideoPreviewImperativeInterface>(null);

  useEffect(() => {
    if (submissionId === selectedSubmissionId && selectedSubmissionTab) {
      setSelectedView(selectedSubmissionTab);
    }
  }, [selectedSubmissionId, selectedSubmissionTab, submissionId]);

  useEffect(() => {
    setShowFeedbackByUser(Boolean(feedbackBy && (submissionId === selectedSubmissionId)));
  }, [feedbackBy, selectedSubmissionId, submissionId]);

  // If this is same submission selected (submissionId is in url), then try
  // to scroll to it
  useEffect(() => {
    if (commentsOnceLoaded
      && selectedSubmissionId
      && selectedSubmissionId === submissionId
      && !newNotification?.commentId) {
      if (prevUser === currentUser && videoRef?.current) {
        videoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        setTimeout(() => {
          if (videoRef?.current) {
            videoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
          // A timeout of 500 millisec to finish the transition (if any) before scrolling
        }, 500);
      }

      if (playerInstanceRef.current && userInteractedWith) {
        playerInstanceRef.current.play(true);
      }
    }
  }, [commentsOnceLoaded, selectedSubmissionId, submissionId, prevUser, currentUser, userInteractedWith, newNotification?.commentId]);

  useEffect(() => {
    if (!isNotifiedCommentsSet && comments?.length && newNotification?.submissionId && newNotification?.commentId && notificationComment) {
      setIsNotifiedComments(true);

      dispatch(setFilteredComments({
        submissionId: newNotification.submissionId,
        filteredComments: [notificationComment.id],
      }));
    }
  }, [dispatch, comments, newNotification, notificationComment, isNotifiedCommentsSet, setIsNotifiedComments]);

  const onLike = useCallback(() => {
    if (scenarioId && submissionId) {
      const params: PracticeSubmissionActionParams = { scenarioId, submissionId };
      if (isPracticeFeedback) {
        params.practiceFeedbackCriteriaId = practiceFeedbackCriteriaId;
      }
      dispatch(likeVideoPracticeSubmission(params));
    }
  }, [dispatch, practiceFeedbackCriteriaId, isPracticeFeedback, scenarioId, submissionId]);

  const onUndoLike = useCallback(() => {
    if (scenarioId && submissionId) {
      const params: PracticeSubmissionActionParams = { scenarioId, submissionId };
      if (isPracticeFeedback) {
        params.practiceFeedbackCriteriaId = practiceFeedbackCriteriaId;
      }
      dispatch(undoLikeVideoPracticeSubmission(params));
    }
  }, [dispatch, practiceFeedbackCriteriaId, isPracticeFeedback, scenarioId, submissionId]);

  const onGetPracticeSubmissionLikers = useCallback(() => {
    if (scenarioId && submissionId) {
      dispatch(getVideoPracticeSubmissionLikers({ scenarioId, submissionId }));
    }
  }, [dispatch, scenarioId, submissionId]);

  const onGetSkillsFeedback = useCallback((
    skillTaggingId?: number,
    afterId?: number,
  ) => {
    if (submissionId) {
      if (
        selectedView === SubmissionTab.AUTHOR_FEEDBACK
        || skillTaggingId
        || (isPracticeRoom && selectedView !== SubmissionTab.ALL_FEEDBACK)) {
        const params: SkillsFeedbackRequest = {
          institutionId: currentInstitutionId,
          ownerId: submissionId,
          ownerType: 'VideoPracticeSubmission',
          skillTaggingId,
          displayOwnFeedback: selectedView === SubmissionTab.AUTHOR_FEEDBACK,
        };
        if (skillTaggingId) {
          params.pageSize = FEEDBACK_PAGE_SIZE;
          params.afterId = afterId ?? null;
        }
        if (isSkillsFeedbackActivity) {
          params.feedbackCriteriaId = practiceFeedbackCriteriaId;
        }
        dispatch(getAllFeedback(params));
      } else if (selectedView === SubmissionTab.ALL_FEEDBACK) {
        dispatch(getSummary({
          institutionId: currentInstitutionId,
          ownerId: submissionId,
          ownerType: 'VideoPracticeSubmission',
        }));
      }
    }
  }, [currentInstitutionId, dispatch, selectedView, submissionId]);

  const getFeedbackByUser = useCallback(() => {
    const params: SkillsFeedbackRequest = {
      institutionId: currentInstitutionId,
      ownerId: submissionId,
      ownerType: 'VideoPracticeSubmission',
      displayOwnFeedback: true,
      userId: feedbackBy,
      feedbackCriteriaId: isSkillsFeedbackActivity ? practiceFeedbackCriteriaId : null,
    };

    dispatch(getAllFeedback(params));
  }, [
    dispatch,
    currentInstitutionId,
    submissionId,
    feedbackBy,
  ]);

  useEffect(() => {
    if ((submissionId && showFeedbackByUser && selectedView === SubmissionTab.ALL_FEEDBACK) || isSkillsFeedbackActivity) {
      getFeedbackByUser();
    }
  }, [getFeedbackByUser, submissionId, showFeedbackByUser, selectedView]);

  useEffect(() => {
    if ((selectedView === SubmissionTab.ALL_FEEDBACK && !showFeedbackByUser) || hasSkillsRating) {
      onGetSkillsFeedback();
    }
  }, [hasSkillsRating, onGetSkillsFeedback, selectedView, showFeedbackByUser]);

  useEffect(() => {
    if (
      isRatedByCurrentUser
      && (selectedView === SubmissionTab.AUTHOR_FEEDBACK || isPracticeRoom)
      && !authorFeedback?.ratings?.length
      && !feedbackLoading) {
      onGetSkillsFeedback();
    }
  }, [
    authorFeedback?.ratings?.length, feedbackLoading,
    isRatedByCurrentUser, onGetSkillsFeedback, selectedView,
  ]);

  useEffect(() => {
    // close popover when comment button collapsed.
    // also  hide popover for an activity once it is displayed.
    if (!isCommentButtonExpanded) {
      setShowInstructionPopover(false);
      if (showFeedbackInstruction) {
        dispatch(setShowFeedbackInstruction({ show: false, practiceFeedbackCriteriaId }));
      }
    }
  }, [dispatch, practiceFeedbackCriteriaId, isCommentButtonExpanded, showFeedbackInstruction]);

  const onReady = useCallback(() => {
    // show popover initially after a delay to position popover in place
    if (isCommentButtonExpanded && showFeedbackInstruction) {
      setShowInstructionPopover(true);
    }
    if (!showFeedbackInstruction) {
      setShowInstructionPopover(false);
    }
  }, [isCommentButtonExpanded, showFeedbackInstruction]);

  useEffect(() => {
    // close comment button if feedback instruction is false for an activity (eg: while sliding )
    if (!showFeedbackInstruction) {
      setCommentButtonExpanded(false);
    }
  }, [showFeedbackInstruction, submissionId]);

  const pauseVideo = useCallback(() => {
    // Pause the player and get current time stamp
    if (playerInstanceRef.current) {
      const playerState = playerInstanceRef.current.getState();

      if (playerState === 'playing' || playerState === 'paused') {
        if (playerState === 'playing') {
          // Pause the player
          playerInstanceRef.current.play(false);
        }

        const currentTime = playerInstanceRef.current.getPosition();
        if (currentTime >= 1) {
          setCurrentTimestamp(currentTime);
        } else {
          setCurrentTimestamp(null);
        }
      } else {
        setCurrentTimestamp(null);
      }
    }
  }, []);

  const onComment = useCallback(() => {
    if (showFeedbackInstruction) {
      dispatch(setShowFeedbackInstruction({ show: false }));
    }
    if (playerInstanceRef.current.getState() === 'playing'
      || playerInstanceRef.current.getState() === 'paused') {
      pauseVideo();
    }
    setShowCommentButton(false);
    setCommentButtonExpanded(false);
  }, [dispatch, pauseVideo, showFeedbackInstruction]);

  const onTextComment = useCallback(() => {
    onComment();
    setShowCommentInputRow(true);
  }, [onComment]);

  const onVideoComment = useCallback(() => {
    onComment();
    setShowRecordVideoComment(true);
  }, [onComment]);

  const onCloseRecordVideoComment = useCallback((file?: NovoEdFile) => {
    setShowRecordVideoComment(false);
    setShowCommentInputRow(false);
    setShowCommentButton(true);
  }, []);

  const afterSubmit = useCallback(() => {
    setShowCommentInputRow(false);
    setShowRecordVideoComment(false);
    setShowCommentButton(true);
    if (selectedView !== SubmissionTab.COMMENTS) {
      setSelectedView(SubmissionTab.COMMENTS);
    }
  }, [selectedView]);

  useEffect(() => {
    if (practiceFeedbackData) {
      const data = isSkillsFeedbackActivity ? practiceFeedbackData.review : practiceFeedbackData;
      if (data) {
        const {
          pointsReceived,
          leaderboardPoints,
          leaderboardRank,
          priorLeaderboardRank,
        } = data;
        if ((isPracticeFeedback || isSkillsFeedbackActivity) && practiceFeedbackActivity?.progress !== 'missed') {
          const isBeyondRequirements = practiceFeedbackActivity?.numReviewesCompleted > practiceFeedbackActivity?.requiredToComplete;
          if (practiceFeedbackActivity?.numReviewesCompleted >= practiceFeedbackActivity?.requiredToComplete) {
            let message = isSkillsFeedbackActivity
              ? t.PRACTICE_ROOM.SKILL_FEEDBACK.ACTIVITY_COMPLETED(practiceFeedbackActivity.requiredToComplete)
              : t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.MODAL.ACTIVITY_COMPLETED(
                practiceFeedbackActivity.requiredToComplete,
              );
            if (isBeyondRequirements) {
              message = t.PRACTICE_ROOM.SKILL_FEEDBACK.ACTIVITY_COMPLETED_BEYOND();
            }
            dispatch(addAlertMessage({
              type: AlertMessageType.SUCCESS,
              header: t.FORM.SUCCESS_BANG(),
              message,
            }));
            // Update points and progress of timeline after activity has been submitted
            // Update completed
            timelineService.updateTimeline(practiceFeedbackActivity.lecturePageId);
            TimelinesManager.updateComponentPointsAndProgress(
              practiceFeedbackActivity.lecturePageId,
              'practice_feedback_criteria',
              practiceFeedbackActivity.id,
              pointsReceived,
              leaderboardPoints,
              'completed',
            );
            if (isSkillsFeedbackActivity) restProps?.onClose();
          } else {
            const getRemainingVideos = () => (
              practiceFeedbackActivity?.requiredToComplete - practiceFeedbackActivity?.numReviewesCompleted
            );
            const message = isSkillsFeedbackActivity
              ? t.PRACTICE_ROOM.SKILL_FEEDBACK.SUCCESS_ALERT(getRemainingVideos())
              : t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.MODAL.SUCCESS_MSG();

            dispatch(addAlertMessage({
              type: AlertMessageType.SUCCESS,
              header: t.FORM.SUCCESS_BANG(),
              message,
            }));
            // In case of uncompleted activity update progress state if not points received
            if (practiceFeedbackActivity?.progress !== 'completed') {
              if (pointsReceived) {
                timelineService.updateTimeline(practiceFeedbackActivity.lecturePageId);
                TimelinesManager.updateComponentPointsAndProgress(
                  practiceFeedbackActivity.lecturePageId,
                  'practice_feedback_criteria',
                  practiceFeedbackActivity.id,
                  pointsReceived,
                  leaderboardPoints,
                  'in_progress',
                );
              } else {
                timelineService.updateTimeline(practiceFeedbackActivity.lecturePageId);
                TimelinesManager.updateComponentProgress(
                  practiceFeedbackActivity.lecturePageId,
                  'practice_feedback_criteria',
                  practiceFeedbackActivity.id,
                  'in_progress',
                );
              }
            }
            if (isSkillsFeedbackActivity) {
              if (restProps.isNext) restProps?.onNext();
              else restProps?.onFirst();
            }
          }
          const currentPointsReceived = practiceFeedbackActivity?.pointsConfiguration?.points;

          if (pointsReceived && !isBeyondRequirements) {
            $uibModal.open({
              templateUrl: 'shared/templates/points-modal.html',
              windowClass: 'points-modal',
              controller: 'PointsModalCtrl as vm',
              resolve: {
                pointsReceived: currentPointsReceived,
                leaderboardPoints,
                leaderboardRank,
                priorLeaderboardRank,
                extras: null,
              },
            });
          }
        }
        setPracticeFeedbackData(null);
      }
    }
  }, [practiceFeedbackActivity?.numReviewesCompleted, practiceFeedbackData,
    isPracticeFeedback, practiceFeedbackActivity?.progress,
    practiceFeedbackActivity?.pointsReceived, hasReviewed, $uibModal,
  ]);

  const addNewComment = useCallback(async (comment: Post) => {
    const data: PostCommentParams = {
      post: comment,
      submissionId,
    };
    if (filteredComments?.length > 0) {
      getCommentsAndClearFilterComments(1);
    }
    if (practiceFeedbackCriteriaId) {
      data.practiceFeedbackCriteriaId = practiceFeedbackCriteriaId;
    }
    const { payload } = await dispatch(postComment(data));
    if (!payload) {
      return false;
    }
    if (isPracticeFeedback && payload.isUserFirstReview) {
      setPracticeFeedbackData(payload);
    }
    afterSubmit();
    return true;
  }, [afterSubmit, dispatch, practiceFeedbackCriteriaId, isPracticeFeedback, submissionId, filteredComments?.length]);

  const onUpdateComment = useCallback(async (commentId: number, comment: Post) => {
    const data: UpdateCommentParams = { commentId, post: comment };
    if (practiceFeedbackCriteriaId) {
      data.practiceFeedbackCriteriaId = practiceFeedbackCriteriaId;
    }
    const { payload } = await dispatch(updateComment(data));
    if (!payload) {
      return false;
    }
    return true;
  }, [dispatch, practiceFeedbackCriteriaId]);

  const clearFilterComments = useCallback(() => {
    getCommentsAndClearFilterComments(1);
  }, [dispatch, submissionId]);

  const onDeleteComment = useCallback(async (commentId: number, isUserFirstReview?: boolean) => {
    const params: DeleteCommentParams = { commentId, submissionId, isUserFirstReview };

    if (practiceFeedbackCriteriaId) {
      params.practiceFeedbackCriteriaId = practiceFeedbackCriteriaId;
    }
    const { payload } = await dispatch(deleteComment(params));
    if (newNotification?.commentId === commentId) {
      clearFilterComments();
      dispatch(clearNewNotification({}));
    }

    if (isUserFirstReview && payload?.publicFeedback) {
      timelineService.updateTimeline(practiceFeedbackActivity?.lecturePageId ?? currentLectureId);
      TimelinesManager.updateComponentPointsAndProgress(
        practiceFeedbackActivity?.lecturePageId ?? currentLectureId,
        'practice_feedback_criteria',
        practiceFeedbackActivity?.id ?? payload.publicFeedback.id,
        payload.publicFeedback.pointsReceived,
        payload.leaderboardPoints,
        payload.publicFeedback.progress,
      );
      // To trigger a render in the angular component so that points update immediately
      $scope.$apply();
    }
  }, [dispatch, practiceFeedbackCriteriaId, isPracticeFeedback, submissionId]);

  const filterCommentsOfTime = useCallback((badge: Badge) => {
    if (playerInstanceRef.current) {
      playerInstanceRef.current.play(false);
      if (badge.meta.time) {
        // Seek to two seconds before
        const seekTime = badge.meta.time > 2 ? (badge.meta.time - 2) : 0;

        playerInstanceRef.current.seek(seekTime);
      }
    }

    dispatch(setFilteredComments({
      submissionId,
      filteredComments: badge.meta.extras.commentIds,
    }));
  }, [dispatch, submissionId]);
  const getCommentsAndClearFilterComments = (page: number) => {
    dispatch(setFilteredComments({
      submissionId,
      filteredComments: [],
    }));
    dispatch(getComments({ submissionId, page }));
  };

  const onTimestampClick = useCallback((commentId: number) => {
    const badge = find(badges, (b) => b.meta.extras.commentIds.includes(commentId));
    if (badge) {
      filterCommentsOfTime(badge);
    }
  }, [badges, filterCommentsOfTime]);

  const onPlayVideo = useCallback((event) => {
    if (playerInstanceRef.current && !playerInstanceRef.current.hasPlayed && !isMyPractice) {
      dispatch(postVideoHasViewed({ scenarioId, submissionId }));
    }
  }, [dispatch, isMyPractice, scenarioId, submissionId]);

  const loadMentionableUsers = useCallback(() => {
    if (!mentionables?.isLoading && !mentionables?.onceLoaded) {
      dispatch(getMentionableUsers({ scenarioId, submissionId }));
    }
  }, [mentionables?.isLoading, mentionables?.onceLoaded, dispatch, scenarioId, submissionId]);

  const deleteSkillsFeedback = useCallback((userId) => {
    dispatch(deleteFeedback({
      institutionId: currentInstitutionId,
      ownerId: submissionId,
      ownerType: 'VideoPracticeSubmission',
      userId,
      feedbackCriteriaId: practiceFeedbackCriteriaId,
    }));
    const hideForm = userId !== currentUserId && isCourseAdmin;
    if (!hideForm) {
      setShowFeedbackForm(true);
    }
  }, [currentInstitutionId, dispatch, submissionId]);

  const onDeleteFeedback = (userId?: number) => {
    const warningTitle = userId
      ? t.PRACTICE_ROOM.SKILL_FEEDBACK.DELETE_AS_ADMIN.TITLE()
      : t.PRACTICE_ROOM.SKILL_FEEDBACK.DELETE_AS_AUTHOR();
    const desc = userId ? t.PRACTICE_ROOM.SKILL_FEEDBACK.DELETE_AS_ADMIN.DESCRIPTION() : '';
    dispatch(openConfirmationDialog({
      onConfirm: () => deleteSkillsFeedback(userId ?? currentUserId),
      cancelText: t.FORM.CANCEL(),
      confirmText: t.FORM.YES_SURE(),
      title: warningTitle,
      bodyText: desc,
    }));
  };

  const toggleShowFeedbackByUser = () => {
    setShowFeedbackByUser(displayFeedbackByUser => !displayFeedbackByUser);
  };

  const tabs: Tab[] = [
    {
      text: t.PRACTICE_ROOM.SUBMISSION.TABS.COMMENTS(),
      onClick: () => setSelectedView(SubmissionTab.COMMENTS),
      pendoTagName: config.pendo.skillsFeedback.practiceTabComments,
      tabValue: SubmissionTab.COMMENTS,
    },
  ];

  if (!isPracticeFeedback) {
    if (!isMyPractice && skillTags?.length > 0) {
      if ((isPracticeRoom && (authorRatings?.length > 0 || isRatedByCurrentUser))
        || (!isPracticeRoom && (isRatedByCurrentUser || isCourseAdmin))
      ) {
        tabs.push({
          text: t.PRACTICE_ROOM.SUBMISSION.TABS.YOUR_SKILLS_FEEDBACK(),
          onClick: () => setSelectedView(SubmissionTab.AUTHOR_FEEDBACK),
          pendoTagName: config.pendo.skillsFeedback.practiceTabYourSkillsFeedback,
          tabValue: SubmissionTab.AUTHOR_FEEDBACK,
        });
      }
    }
    if (isMyPractice || isCourseAdmin || isViewerMentor) {
      if (skillTags?.length > 0 && hasSkillsRating) {
        tabs.push({
          text: t.PRACTICE_ROOM.SUBMISSION.TABS.ALL_SKILLS_FEEDBACK(),
          onClick: () => setSelectedView(SubmissionTab.ALL_FEEDBACK),
          pendoTagName: config.pendo.skillsFeedback.practiceTabAllSkillsFeedback,
          tabValue: SubmissionTab.ALL_FEEDBACK,
        });
      }

      if (hasInsightsEnabled) {
        tabs.push({
          text: t.PRACTICE_ROOM.SUBMISSION.TABS.INSIGHTS(),
          onClick: () => setSelectedView(SubmissionTab.INSIGHTS),
          dataQA: 'automated-feedback-insights-tab',
          pendoTagName: config.pendo.practice.insightsTab,
          tabValue: SubmissionTab.INSIGHTS,
        });
      }
    }
  }

  const getActiveTabIndex = useCallback(() => {
    const allTabs = values(SubmissionTab);
    const availableTabs = tabs.map(tab => tab.tabValue);
    const missingTabs = difference(allTabs, availableTabs);
    const tabsBeforeActiveTab = missingTabs.filter(
      tab => selectedViewToIndex(tab) < selectedViewToIndex(selectedView),
    );
    return selectedViewToIndex(selectedView) - tabsBeforeActiveTab.length;
  }, [selectedView]);

  const totalComments = submissionComments?.length;

  // Slice the last five comments to display recent five comments
  const slicingIndex = (totalComments - INITIAL_COMMENTS_PER_PAGE) >= 0
    ? (totalComments - INITIAL_COMMENTS_PER_PAGE) : 0;
  let displayComments = viewMore ? submissionComments
    : submissionComments?.slice(
      slicingIndex,
      totalComments,
    ); // To display the recent comments initially
  const commentsLeft = numComments - displayComments?.length;
  const isCommentsFiltered = !isEmpty(filteredComments);
  if (isCommentsFiltered) {
    displayComments = submissionComments?.filter((commentId) => filteredComments.includes(commentId));
  }
  const showFeedbackButton = skillTags?.length > 0 && !isRatedByCurrentUser && !isMyPractice && !isPracticeFeedback && !isSkillsFeedbackActivity && !isPracticeRoom;
  const showTabs = showCommentButton && tabs.length > 1;
  const nextPage = pageNo + 1;
  const skillsFeedbackHeader = showSubmissionOptions ? t.PRACTICE_ROOM.SKILL_FEEDBACK.PRACTICE_ROOM_HEADER() : t.PRACTICE_ROOM.SKILL_FEEDBACK.HEADER();
  const displayMoreComments = () => {
    if (!viewMore && pageNo === 1) {
      setViewMore(true);
    } else {
      setMoreCommentsLoaded(false);
      dispatch(getComments({ submissionId, page: nextPage })).then(() => {
        setPageNo(pageNo + 1);
        setMoreCommentsLoaded(true);
      });
    }
  };

  const isActivityUncompleted = () => (
    isSkillsFeedbackActivity
    && !isRatedByCurrentUser
    && !isMyPractice
    && !isPracticeRoom
  );

  const showForAdmins = () => (
    isCourseAdmin
    && selectedTab === SubmissionTab.AUTHOR_FEEDBACK
    && !isRatedByCurrentUser
  );

  useEffect(() => {
    const show = showForAdmins() || isActivityUncompleted();
    setShowFeedbackForm(show);
  }, [isRatedByCurrentUser, isMyPractice, isPracticeRoom, isSkillsFeedbackActivity]);

  const onSubmitFeedback = (skillsRatingData: any) => {
    setPracticeFeedbackData(skillsRatingData.payload);
    setSelectedView(SubmissionTab.AUTHOR_FEEDBACK);
  };

  return (
    <PracticeSubmissionContext.Provider value={{ submissionId, scenarioId }}>
      <div css={styles(isMyPractice, isPracticeRoom, recordingFormat === RecordingFormat.AUDIO)} className='d-flex flex-column w-100' ref={practiceSubmissionRef}>
        <div className='d-flex flex-column flex-md-row justify-content-between'>
          {userPracticeNumber && (
            <div className='course-title-xs font-weight-bold'>
              {t.PRACTICE_ROOM.SUBMISSION.TITLE(
                moment(recordedDate).format('L hh:mm A'),
                userPracticeNumber,
              )}
            </div>
          )}
          <div className='d-flex justify-content-between mb-1'>
            <div className='d-flex'>
              {activity?.course?.name && (
                <React.Fragment>
                  <div className='card-title-xs mr-1'>
                    {t.PRACTICE_ROOM.SUBMISSION.PRACTICED_IN()}
                  </div>
                  <div className='course-title-xs'>{activity.course.name}</div>
                </React.Fragment>
              )}
            </div>
            {/* PR-TODO : In Phase 2 */}
            {/* <NvIcon
              icon='share'
              size='small'
              className='text-gray-3 ml-2 mb-1'
            /> */}
            {showSubmissionOptions && (
              <PracticeSubmissionOptions
                submissionId={submissionId}
                loadMentionableUsers={loadMentionableUsers}
              />
            )}
          </div>
        </div>
        <div className='d-flex flex-column mt-1 position-relative'>
          {featured && !practiceFeedbackCriteriaId && (
            <div className='featured d-flex flex-column justify-content-center align-items-center position-absolute'>
              <NvIcon
                icon='highlight'
                size='xss-smallest'
                className='text-white'
              />
            </div>
          )}
          <div className='player-container' ref={videoRef}>
            <NvVideoPreview
              ref={playerInstanceRef}
              file={videoFile}
              badges={badges}
              onClickOnBadge={filterCommentsOfTime}
              badgePendoTag={config.pendo.practice.commentFocusFromPracticePlayer}
              onPlay={onPlayVideo}
              onReady={onReady}
              isAudio={recordingFormat === RecordingFormat.AUDIO}
            />
            <div
              className={`${showFeedbackButton ? 'comment-and-feedback' : 'comment'} d-flex`}
              dir='ltr'
            >
              {showFeedbackButton && (
                <NvTooltip
                  text={t.PRACTICE_ROOM.SKILL_FEEDBACK.TOOLTIP()}
                  placement='bottom'
                >
                  <ClickableContainer
                    className='mx-4'
                    onClick={() => setShowFeedbackForm(!showFeedbackForm)}
                    pendo-tag-name={config.pendo.skillsFeedback.practiceGiveSkillsFeedback}
                  >
                    <div className='skill-feedback medium-large d-flex justify-content-center align-items-center text-white'>
                      <NvIcon icon='skills-feedback' size='medium' />
                    </div>
                  </ClickableContainer>
                </NvTooltip>
              )}
              {showCommentButton && (
                <FeedbackInstructionPopover
                  enabled={showInstructionPopover && isCommentButtonExpanded}
                  show={showInstructionPopover}
                  title={practiceFeedbackActivity?.title}
                  setShowModal={setFeedbackInstructionModal}
                >
                  <NvCommentButton
                    onTextComment={onTextComment}
                    onVideoComment={onVideoComment}
                    pauseVideo={pauseVideo}
                    isExpanded={isCommentButtonExpanded}
                    setExpanded={setCommentButtonExpanded}
                  />
                </FeedbackInstructionPopover>
              )}
            </div>
          </div>
          <div className='social-actions d-flex align-items-center pl-1' dir='ltr'>
            <div
              className='action-item d-flex align-items-center m-3'
              pendo-tag-name={config.pendo.practice.likePractice}
            >
              <NvLikeButton
                isLiked={isLikedByCurrentUser}
                likedCount={numLikes}
                likedUsers={likedUsers}
                isDisabled={isMyPractice}
                iconSize='small'
                iconClassName={likeIconClass}
                textClassName='likes text-primary'
                onLike={onLike}
                onUndoLike={onUndoLike}
                showLikersInfo
                getLikersInfo={onGetPracticeSubmissionLikers}
              />
            </div>
            {numComments > 0 && (
              <div className='action-item  d-flex align-items-center m-3'>
                <NvIcon size='small' icon='comments' className='text-gray-3' />
                <span className='text-gray-2'>{numComments}</span>
              </div>
            )}
            {numViews > 0 && (
              <div className='action-item  d-flex align-items-center m-3'>
                <NvIcon size='medium' icon='view' className='text-gray-3' />
                <span className='text-gray-2'>{numViews}</span>
              </div>
            )}
          </div>
        </div>
        {showFeedbackForm && !isAdmin && (
          <div className='d-flex flex-column m-4'>
            {(!showTabs && !isAdmin) && (
              <div className='d-flex justify-content-between text-large-body font-weight-bolder mb-4'>
                {skillsFeedbackHeader}
                {!isSkillsFeedbackActivity ?? (
                  <ClickableContainer
                    className='scenarios-close'
                    onClick={() => setShowFeedbackForm(false)}
                    aria-label={t.NOVOED.CLOSE()}
                  >
                    <NvIcon size='small' icon='close' className='text-gray-1' />
                  </ClickableContainer>
                )}
              </div>
            )}
            <span>{t.PRACTICE_ROOM.SKILL_FEEDBACK.DESCRIPTION()}</span>
            <SkillFeedback
              skillTags={skillTags}
              onSubmit={onSubmitFeedback}
              onCancel={restProps.onClose}
              ownerId={submissionId}
              ownerType='VideoPracticeSubmission'
              feedbackCriteriaId={practiceFeedbackCriteriaId}
              catalogId={catalogId}
              highlightMode
            />
          </div>
        )}
        {showCommentInputRow && (
          <NvCommentForm
            onSubmit={addNewComment}
            onCancel={() => {
              setShowCommentInputRow(false);
              setShowRecordVideoComment(false);
              setShowCommentButton(true);
            }}
            timestamp={currentTimestamp}
            timestampInfo={t.PRACTICE_ROOM.COMMENTS.TIMESTAMP_POPOVER()}
            mentionableUsers={mentionableUsers}
            loadMentionableUsers={loadMentionableUsers}
          />
        )}
        {showRecordVideoComment && (
          <RecordVideoComment
            onClose={onCloseRecordVideoComment}
            onSubmit={addNewComment}
            timestamp={currentTimestamp}
          />
        )}
        {(!showFeedbackForm || isAdmin) && !showCommentInputRow && (
          <React.Fragment>
            <div className='submission-tabs-row border-bottom border-gray-6 mb-4'>
              {(showTabs || isAdmin) && (
                <NvResponsiveTabsRow
                  defaultTabs={tabs}
                  tabTextClass='card-title'
                  tabType={NvResponsiveTabsDisplayType.TEXT_ONLY}
                  revertActiveTab={getActiveTabIndex()}
                />
              )}
            </div>
            <div>
              {selectedView === SubmissionTab.COMMENTS ? (
                <LoadingWrapper
                  isLoaded={commentsOnceLoaded}
                  loaderType={LoaderType.PLACEHOLDER}
                >
                  <React.Fragment>
                    {totalComments > 0
                      ? (
                        <React.Fragment>
                          {isCommentsFiltered ? (
                            (
                              <React.Fragment>
                                {totalComments > filteredComments.length && (
                                  <ClickableContainer
                                    className='view-more text-regular text-primary my-4'
                                    onClick={clearFilterComments}
                                  >
                                    {t.PRACTICE_ROOM.COMMENTS.ALL_COMMENTS()}
                                  </ClickableContainer>
                                )}
                              </React.Fragment>
                            )
                          ) : (
                            <React.Fragment>
                              {commentsLeft > 0 && (
                                <ClickableContainer
                                  className='view-more text-regular text-primary my-4'
                                  onClick={displayMoreComments}
                                >
                                  {t.PRACTICE_ROOM.COMMENTS.VIEW_MORE(commentsLeft)}
                                </ClickableContainer>
                              )}
                              <LoadingWrapper
                                isLoaded={moreCommentsLoaded}
                                loaderType={LoaderType.PLACEHOLDER}
                              >
                                <div />
                              </LoadingWrapper>
                            </React.Fragment>
                          )}
                          {displayComments.map(commentId => (
                            <React.Fragment key={commentId}>
                              <NvCommentRow
                                commentId={commentId}
                                isHighlighted={filteredComments?.includes(commentId)}
                                // Filtered comments only contain one comment, so play it on load
                                playOnLoad={filteredComments?.includes(commentId) && filteredComments.length === 1}
                                onLike={() => dispatch(likeComment({ commentId, practiceFeedbackCriteriaId }))}
                                onUndoLike={() => dispatch(undoLikeComment({ commentId, practiceFeedbackCriteriaId }))}
                                getLikersInfo={() => dispatch(getCommentLikers({ commentId }))}
                                onReply={addNewComment}
                                onUpdate={(comment) => onUpdateComment(commentId, comment)}
                                canDelete={hasCourseAdmin}
                                onDelete={(isUserFirstReview) => onDeleteComment(commentId, isUserFirstReview)}
                                onTimestampClick={onTimestampClick}
                                lastVisitedAt={selectedTab === PracticeRoomTab.MY_PRACTICE ? myPracticeVisitedAt : ''}
                                mentionableUsers={mentionableUsers}
                                loadMentionableUsers={loadMentionableUsers}
                                allSubmissionCommentsLoaded={allSubmissionCommentsLoaded}
                              />
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ) : (
                        <div
                          className='d-flex justify-content-center text-medium gray-2 mt-5'
                        >
                          {isMyPractice
                            ? t.PRACTICE_ROOM.COMMENTS.NO_COMMENTS.MY_PRACTICE()
                            : t.PRACTICE_ROOM.COMMENTS.NO_COMMENTS.DEFAULT()}
                        </div>
                      )}
                  </React.Fragment>
                </LoadingWrapper>
              ) : (
                <div className={`${showFeedbackByUser ? 'my-3' : 'my-6'}`}>
                  {selectedView === SubmissionTab.AUTHOR_FEEDBACK && (
                    <LoadingWrapper
                      isLoaded={feedbackOnceLoaded || !isRatedByCurrentUser}
                      loaderType={LoaderType.PLACEHOLDER}
                    >
                      <React.Fragment>
                        {authorRatings?.length > 0 ? (
                          <AuthorFeedback
                            ownerId={submissionId}
                            ownerKey='videoPracticeSubmissions'
                            submittedUserName={user.firstName}
                            createdAt={authorFeedback?.createdAt}
                            onDelete={onDeleteFeedback}
                            showTag
                          />
                        ) : (
                          <React.Fragment>
                            {(!isRatedByCurrentUser || (!isMyPractice && !feedbackLoading)) && (
                              <React.Fragment>
                                {isAdmin && (
                                  <div className='d-flex flex-column m-4'>
                                    {(!showTabs && !isAdmin) && (
                                      <div className='d-flex justify-content-between text-large-body font-weight-bolder mb-4'>
                                        {skillsFeedbackHeader}
                                        {!isSkillsFeedbackActivity ?? (
                                          <ClickableContainer
                                            className='scenarios-close'
                                            onClick={() => setShowFeedbackForm(false)}
                                            aria-label={t.NOVOED.CLOSE()}
                                          >
                                            <NvIcon size='small' icon='close' className='text-gray-1' />
                                          </ClickableContainer>
                                        )}
                                      </div>
                                    )}
                                    <span>{t.PRACTICE_ROOM.SKILL_FEEDBACK.DESCRIPTION()}</span>
                                    <SkillFeedback
                                      skillTags={skillTags}
                                      onSubmit={onSubmitFeedback}
                                      onCancel={restProps.onClose}
                                      ownerId={submissionId}
                                      ownerType='VideoPracticeSubmission'
                                      feedbackCriteriaId={practiceFeedbackCriteriaId}
                                      catalogId={catalogId}
                                      highlightMode
                                    />
                                  </div>
                                )}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    </LoadingWrapper>
                  )}
                  {selectedView === SubmissionTab.ALL_FEEDBACK && (showFeedbackByUser && submissionId === selectedSubmissionId ? (
                    <DisplaySkillRating
                      isLoaded={feedbackByUserOnceLoaded}
                      ratingIds={ratingIds}
                      onToggle={toggleShowFeedbackByUser}
                    />
                  ) : (
                    <LoadingWrapper
                      isLoaded={summaryOnceLoaded}
                      loaderType={LoaderType.PLACEHOLDER}
                    >
                      {summary?.length > 0 && (
                        <React.Fragment>
                          {summary?.map(skill => (
                            <RatingSummary
                              key={skill.skillTagId}
                              skillTagId={skill.skillTagId}
                              skillRating={skill.avg}
                              totalRatings={skill.totalRatings}
                              submissionId={submissionId}
                              onGetSkillsFeedback={(afterId) => onGetSkillsFeedback(
                                skill.skillTaggingId, afterId,
                              )}
                              skillTaggingId={skill.skillTaggingId}
                              onDeleteFeedback={isPracticeRoom ? null : onDeleteFeedback}
                              canDelete={hasCourseAdmin}
                              ownerKey='videoPracticeSubmissions'
                            />
                          ))}
                          {!isPracticeAdmin && (
                            <div className='text-small gray-3 ml-2 mt-6 '>
                              {t.SKILLS_FEEDBACK.FEEDBACK_VISIBILITY.ALL()}
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </LoadingWrapper>
                  ))}
                  {selectedView === SubmissionTab.INSIGHTS && (
                    <InsightsTab />
                  )}
                </div>
              )}
            </div>
          </React.Fragment>
        )}
        {practiceFeedbackActivity && (
          <FeedbackInstructionModal
            title={practiceFeedbackActivity.title}
            setShowModal={setFeedbackInstructionModal}
            showModal={showFeedbackInstructionModal && !!videoRef?.current}
          />
        )}
      </div>
    </PracticeSubmissionContext.Provider>
  );
};

export default PracticeSubmission;
