import React, { useCallback, useRef } from 'react';
import _ from 'underscore';
import { css } from '@emotion/core';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import t from 'react-translate';
import { RootState } from 'redux/schemas';
import { AngularContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import NvIcon from 'shared/components/nv-icon';
import { Course } from 'redux/schemas/models/course';
import NvPopover from 'shared/components/nv-popover';
import QuizImage from 'quizzes/components/quiz-image';
import QuizHeader from 'quizzes/components/quiz-header';
import { getLecturePage } from 'redux/selectors/timeline';
import { Institution } from 'redux/schemas/models/courseFull';
import { ProgressiveQuizMode } from 'quizzes/components/mode';
import CorrectAnswer from 'quizzes/components/correct-answer';
import FailedQuizModal from 'quizzes/components/failed-quiz-modal';
import NavigationArrows from 'quizzes/components/navigation-arrows';
import {
  largeSpacing,
  quarterSpacing,
  threeQuartersSpacing,
  tripleSpacing,
  doubleSpacing,
} from 'styles/global_defaults/scaffolding';
import { gray3, gray5, gray7 } from 'styles/global_defaults/colors';
import useForceUpdate from 'shared/hooks/use-force-update';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { getLectureComponent } from 'redux/selectors/lecture-components';
import ProgressiveFeedback from 'quizzes/components/progressive-feedback';
import QuizQuestionContent from 'quizzes/components/quiz-question-content';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { QuestionPopoverProvider } from 'quizzes/components/question-popover';
import { getPointsConfiguration } from 'redux/selectors/points-configurations';
import { getCurrentCourse, getFlatCourseAliases } from 'redux/selectors/course';
import ProgressiveQuizContext, { QuestionContext } from 'quizzes/components/context';
import QuizQuestionMultipleOptions from 'quizzes/components/quiz-question-multiple-options';
import useSavingRegistry, { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import {
  getQuizQuestion,
  GetQuestionParams,
  submitQuizAttempt,
  reviewPreviousAttempt,
  unsetQuizQuestionResponse,
  createQuizQuestionSubmission,
} from 'redux/actions/quizzes';
import {
  getProgressiveQuiz,
  getQuizQuestionOptions,
  getQuizQuestionResponse,
  getProgressiveQuizQuestion,
  getProgressiveQuizQuestions,
} from 'redux/selectors/quizzes';
import {
  NQuizQuestion,
  QuizSubmission,
  QuizQuestionType,
  MultipleChoiceResponseOption,
  QuizQuestion as TQuizQuestion,
} from 'redux/schemas/models/progressive-quiz';
import { useTimelineService } from 'timelines/services/react-timeline-service';
import StatementQuizQuestion from './statement-quiz-question';
import ShortAnswerQuizQuestion from './short-answer-quiz-question';
import NumberAnswerQuizQuestion from './number-answer-quiz-question';
import { config } from '../../../config/pendo.config.json';

type Props = {
  reveal: boolean;
  mode: ProgressiveQuizMode;
  lectureComponentId: number;
  closeModal: (skipCheck?: boolean) => void;
  forwardOnModalClose: (fn: (e: Event) => void) => () => void;
};

const ProgressiveQuizModal = (props: Props) => {
  const {
    mode,
    reveal,
    closeModal,
    lectureComponentId,
    forwardOnModalClose,
  } = props;

  const dispatch = useAppDispatch();
  const savingRegistry = useSavingRegistry();
  const { isSaving } = savingRegistry;
  const scrollRef = React.useRef<HTMLDivElement>();
  const timeoutRef = React.useRef<null | NodeJS.Timeout>(null);
  const quizContainerRef = React.useRef(null);
  const isEditMode = mode === ProgressiveQuizMode.EDIT;
  const courseAliases = useSelector(getFlatCourseAliases);
  const isReviewMode = mode === ProgressiveQuizMode.REVIEW;
  const isAnswerMode = mode === ProgressiveQuizMode.ANSWER;
  const [fetchedData, setFetchedData] = React.useState(false);
  const [showSavingContainer, setShowSavingContainer] = React.useState(false);
  const [isQuizSaving, setIsQuizSaving] = React.useState(false);
  const [isSaved, setIsSaved] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { injectServices } = React.useContext(AngularContext);
  const [StateManager] = injectServices(['StateManager']);

  const lectureComponent = useSelector((state: RootState) => getLectureComponent(state, lectureComponentId));
  const ownerLecturePage = useSelector((state: RootState) => getLecturePage(state, lectureComponent.lecturePageId));

  const progressiveQuizId = lectureComponent.progressiveQuiz;

  const progressiveQuiz = useSelector(
    (state: RootState) => getProgressiveQuiz(state, progressiveQuizId),
  );

  const pointsConfiguration = useSelector((state) => (
    getPointsConfiguration(state, progressiveQuiz.pointsConfiguration || 0)
  ));

  const questions: NQuizQuestion[] = useSelector(
    (state: RootState) => getProgressiveQuizQuestions(state, progressiveQuizId),
  );

  const [
    currentQuestionId,
    setCurrentQuestionId,
  ] = React.useState<number | null>(null);

  const questionByCurrentId: NQuizQuestion = useSelector((state: RootState) => getProgressiveQuizQuestion(state, currentQuestionId));

  const [
    currentQuestionIndex,
    setCurrentQuestionIndex,
  ] = React.useState<number>(isAnswerMode ? null : 0);

  let currentQuestion = (currentQuestionId ? questionByCurrentId : questions?.[currentQuestionIndex]);

  // For modes that are not EDIT we need to wait till data is fetched to
  // consider the redux question a reliable source
  currentQuestion = (fetchedData || isEditMode) ? currentQuestion : undefined;

  const currentCourse = useSelector(getCurrentCourse);
  const currentQuestionResponse = useSelector(
    (state: RootState) => getQuizQuestionResponse(state, currentQuestion?.responses),
  );

  const failedQuestion = (currentQuestion?.completedQuestionAttempts === progressiveQuiz.questionMaximumAttempts) && !currentQuestionResponse?.isCorrect;

  const canMakeStructuralChanges = currentCourse.isContentManagementCollection ? !progressiveQuiz.learnerStartedSubmission : !ownerLecturePage.released || !progressiveQuiz.learnerStartedSubmission;

  const isRequirementEnabled = pointsConfiguration && !pointsConfiguration.rewardsPointsProportionally;

  const requiredCorrectQuestionsCount = isRequirementEnabled ? Math.ceil(progressiveQuiz.answerableQuestionsCount * pointsConfiguration.threshold) : 0;

  const notMetRequirement = useCallback(() => {
    if (questionByCurrentId) {
      if (questionByCurrentId?.type === QuizQuestionType.STATEMENT) {
        return false;
      }
      const questionsLeftCount = progressiveQuiz.answerableQuestionsCount - (questionByCurrentId?.answerableQuestionPosition - 1 + (failedQuestion ? 1 : 0));
      return isRequirementEnabled ? ((progressiveQuiz.correctAnswersCount + questionsLeftCount) < requiredCorrectQuestionsCount) : false;
    }
    return false;
  }, [
    failedQuestion,
    isRequirementEnabled,
    progressiveQuiz.answerableQuestionsCount,
    progressiveQuiz.correctAnswersCount,
    questionByCurrentId,
    requiredCorrectQuestionsCount,
  ]);

  React.useEffect(() => StateManager.registerStateChangeStart(
    isSaving,
    'shared/templates/modal-navigate-away.html',
    'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY',
  ),
  [isSaving, StateManager]);

  React.useEffect(() => forwardOnModalClose((event) => {
    if (isSaving()) {
      event.preventDefault();

      dispatch(openConfirmationDialog({
        cancelText: t.FORM.CANCEL(),
        confirmText: t.FORM.YES_SURE(),
        onConfirm: () => closeModal(true),
        title: t.FORM.UNSAVED_CHANGES.CLOSE_WINDOW.TITLE(),
        bodyText: t.FORM.UNSAVED_CHANGES.CLOSE_WINDOW.DESCRIPTION(),
      }));
    }
  }), [isSaving, dispatch, closeModal, forwardOnModalClose]);

  const fetchQuestion = React.useCallback((params?: Omit<GetQuestionParams, 'questionSetId'>) => wrapThunkAction(
    dispatch(getQuizQuestion({
      questionSetId: progressiveQuizId,
      ...(params ?? {}),
    })),
  ).then((action) => {
    const fetchedQuestion = action.payload as TQuizQuestion;

    setCurrentQuestionId(fetchedQuestion.id);

    setCurrentQuestionIndex(fetchedQuestion.questionIndex);

    return fetchedQuestion;
  }), [dispatch, progressiveQuizId]);

  // Answer mode get question
  React.useEffect(() => {
    if (isAnswerMode) {
      fetchQuestion().then(() => {
        setFetchedData(true);
      });
    }
  }, [isAnswerMode, fetchQuestion]);

  // Review mode get questions
  React.useEffect(() => {
    if (isReviewMode) {
      wrapThunkAction(dispatch(reviewPreviousAttempt({
        reveal,
        questionSetId: progressiveQuizId,
      }))).then(() => {
        setFetchedData(true);
      });
    }
  }, [
    reveal,
    dispatch,
    isReviewMode,
    progressiveQuizId,
  ]);

  React.useEffect(() => {
    if (currentQuestionIndex !== null) {
      if (quizContainerRef.current) {
        quizContainerRef.current.focus();
      }
      scrollRef.current.scrollTop = 0;
    }
  }, [currentQuestionIndex]);

  const { headerColor } = useSelector<RootState, Course>(getCurrentCourse);
  const { brandColor } = useSelector<RootState, Institution>(getCurrentInstitution);

  const accentColor = headerColor ?? brandColor ?? gray3;

  const styles = css`
    z-index: 0;
    height: 100%;
    border-top: ${quarterSpacing}px solid ${accentColor};

    .scroll {
      height: 100%;
      overflow-y: auto;
      padding-left: 70px;
      padding-right: 70px;

      .saving-container {
        z-index: 1;
        position: fixed;
        top: calc(100% - ${tripleSpacing}px);
        left: calc(50% - 37.5px);
        height: ${largeSpacing}px;
        width: 75px;
        border-radius: ${threeQuartersSpacing}px;
        box-shadow: 0px ${quarterSpacing}px ${quarterSpacing}px rgba(0, 0, 0, 0.15)
      }

      .quiz-header {
        top: 0;
        z-index: 2;
      }

      .content-container {
        width: 100%;
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;


        .quiz-question {
          z-index: 1;
        }

        .released-container {
          padding: 12px;
          background-color: ${gray7};
        }

        .question-image {
          flex-shrink: 0;
        }
      }
    }
  `;

  const contextValue = {
    mode,
    reveal,
    questions,
    scrollRef,
    closeModal,
    fetchQuestion,
    progressiveQuiz,
    currentQuestion,
    lectureComponent,
    notMetRequirement,
    forwardOnModalClose,
    isRequirementEnabled,
    currentQuestionIndex,
    currentQuestionResponse,
    setCurrentQuestionIndex,
    canMakeStructuralChanges,
    requiredCorrectQuestionsCount,
    setShowSavingContainer,
    setIsQuizSaving,
    setIsSaved,
    timeoutRef,
    isSubmitting,
    setIsSubmitting,
  };

  const quizQuestion = useCallback(() => {
    switch (currentQuestion.type) {
      case QuizQuestionType.MULTIPLE_CHOICE:
      case QuizQuestionType.MULTIPLE_CHOICE_MULTIPLE_ANSWER:
        return (
          <MultipleChoiceQuizQuestion
            currentQuestion={currentQuestion}
            className='quiz-question position-relative'
          />
        );
      case QuizQuestionType.STATEMENT:
        return (
          <StatementQuizQuestion
            currentQuestion={currentQuestion}
            className='quiz-question position-relative'
          />
        );
      case QuizQuestionType.SHORT_TEXT_QUESTION:
        return (
          <ShortAnswerQuizQuestion
            currentQuestion={currentQuestion}
            className='quiz-question position-relative'
          />
        );
      case QuizQuestionType.NUMBER_QUESTION:
        return (
          <NumberAnswerQuizQuestion
            currentQuestion={currentQuestion}
            className='quiz-question position-relative'
          />
        );
      default:
        return (
          <MultipleChoiceQuizQuestion
            currentQuestion={currentQuestion}
            className='quiz-question position-relative'
          />
        );
    }
  }, [currentQuestion]);

  return (
    <SavingRegistryContext.Provider value={savingRegistry}>
      <ProgressiveQuizContext.Provider value={contextValue}>
        <QuestionPopoverProvider>
          <div css={styles} tabIndex={-1} ref={quizContainerRef} className='position-relative'>
            <div className='scroll pb-6 position-relative' ref={scrollRef}>
              <div className='content-container'>
                {currentQuestion && (
                  <>
                    <QuizHeader className='quiz-header mb-2 position-sticky' />
                    {!canMakeStructuralChanges && isEditMode && (
                      <div className='released-container text-danger d-flex flex-row align-items-center mb-5'>
                        <NvIcon icon='warning' size='small' className='mr-2' />
                        <div className='label text-danger'>
                          {t.QUIZZES.ALREADY_RELEASED.DESCRIPTION(courseAliases)}
                        </div>
                      </div>
                    )}
                    {quizQuestion()}
                  </>
                )}
              </div>
              {showSavingContainer && (
                <React.Fragment>
                  { isQuizSaving ? (
                    <div className='d-flex text-gray-2 bg-gray-7 saving-container align-items-center justify-content-center m-auto'>
                      <i className='icon-refresh spin icon-xss-smallest d-flex align-items-center' />
                      <div className='ml-1 semi-bold text-small'>{t.QUIZZES.SAVING()}</div>
                    </div>
                  ) : (
                    <div className='d-flex bg-gray-7 saving-container align-items-center justify-content-center m-auto'>
                      <i className={`${isSaved ? 'icon-check text-success' : 'icon-warning text-danger'} icon-xss-smallest d-flex align-items-center`} />
                      <div className='ml-1 semi-bold text-small'>{isSaved ? t.QUIZZES.QUIZ_SAVED() : t.QUIZZES.ERROR()}</div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
            {currentQuestion && (
              <NavigationArrows />
            )}
          </div>
        </QuestionPopoverProvider>
        {isAnswerMode && (
          <FailedQuizModal />
        )}
      </ProgressiveQuizContext.Provider>
    </SavingRegistryContext.Provider>
  );
};

type QuizQuestionProps = {
  currentQuestion: NQuizQuestion;
  className?: string;
};

const MultipleChoiceQuizQuestion = (props: QuizQuestionProps) => {
  const { className, currentQuestion } = props;

  const dispatch = useAppDispatch();
  const courseAliases = useSelector(getFlatCourseAliases);
  const areAttemptsLeft = currentQuestion.completedQuestionAttempts
    < currentQuestion.totalQuestionAttempts;

  const { injectServices } = React.useContext(AngularContext);

  const [
    $uibModal,
    TimelinesManager,
  ] = injectServices([
    '$uibModal',
    'TimelinesManager',
  ]);

  const [isCurrentFeedbackShown, setIsCurrentFeedbackShown] = React.useState(false);
  const currentLectureId = useSelector(state => state.app.lecturePage?.currentLectureId);
  const timelineService = useTimelineService();
  const forceUpdate = useForceUpdate();

  const {
    mode,
    reveal,
    scrollRef,
    closeModal,
    fetchQuestion,
    progressiveQuiz,
    lectureComponent,
    notMetRequirement,
    forwardOnModalClose,
    currentQuestionIndex,
    currentQuestionResponse,
    isSubmitting,
    setIsSubmitting,
  } = React.useContext(ProgressiveQuizContext);

  // This state update is to trigger the recalculation of the CTA button position when image is loaded
  const handleImageLoad = () => {
    setTimeout(() => {
      forceUpdate();
    }, 0);
  };

  const areQuestionsLeft = (currentQuestionIndex + 1) < progressiveQuiz.totalQuestions;

  const isEditMode = mode === ProgressiveQuizMode.EDIT;
  const isAnswerMode = mode === ProgressiveQuizMode.ANSWER;
  const isReviewMode = mode === ProgressiveQuizMode.REVIEW;

  const isMultipleAnswer = currentQuestion.type === QuizQuestionType.MULTIPLE_CHOICE_MULTIPLE_ANSWER;

  const [optionsState, setOptionsState] = React.useState({});

  const currentQuestionId = currentQuestion.id;

  const pointsConfiguration = useSelector((state) => (
    getPointsConfiguration(state, progressiveQuiz.pointsConfiguration || 0)
  ));

  const unsetResponse = React.useCallback(() => {
    setOptionsState({});
  }, []);

  React.useLayoutEffect(() => {
    unsetResponse();
  }, [currentQuestionId, unsetResponse]);

  React.useLayoutEffect(() => {
    if (currentQuestionResponse) {
      setOptionsState(currentQuestionResponse.feedback.reduce((acc, curr) => ({ ...acc, [curr]: true }), {}));
    }
  }, [currentQuestionResponse]);

  const responseOptions: MultipleChoiceResponseOption[] = useSelector(
    (state: RootState) => getQuizQuestionOptions(state, currentQuestion?.id),
  );

  const areAllOptionsCorrect = responseOptions?.every((responseOption) => !responseOption.isCorrect) ?? false;

  const setOptionState = (optionId: number, selected: boolean) => {
    if (isMultipleAnswer) {
      setOptionsState((prev) => ({
        ...prev,
        [optionId]: selected,
      }));
    } else {
      setOptionsState((prev) => ({
        [optionId]: true,
      }));
    }
  };

  const questionContextValue = {
    optionsState,
    setOptionState,
    responseOptions,
    currentQuestion,
    areAllOptionsCorrect,
    currentQuestionResponse,
  };

  const hasSelectedAtLeastOneOptions = Object.values(optionsState).some((value) => value);

  const showCorrectAnswer = isReviewMode && reveal;

  const isAtLeastOneBottomSectionElementShown = isCurrentFeedbackShown || showCorrectAnswer;
  const quizQuestionRef = useRef(null);
  const buttonContainerRef = useRef(null);
  const quizQuestionRect = quizQuestionRef?.current?.getBoundingClientRect();
  const buttonContainerRect = buttonContainerRef?.current?.getBoundingClientRect();

  const getPosition = () => {
    // used to indicate that the bottom margin of the button has become greater than 40px.
    const isButtonBottomMarginExceeds = (scrollRef?.current?.getBoundingClientRect()?.bottom - buttonContainerRect?.bottom) > 40;
    // used to indicate that the top margin has become less than 60px.
    const isRequiredButtonTopMarginReduced = (buttonContainerRect?.top - (quizQuestionRect?.height + quizQuestionRect?.top)) < 60;
    if (!isButtonBottomMarginExceeds && isRequiredButtonTopMarginReduced) {
      return 'static';
    }
    return 'fixed';
  };

  const button = buttonContainerRef?.current?.querySelector('button');
  const styles = css`
    .bottom-section {
      ${!isEditMode && isAtLeastOneBottomSectionElementShown && css`
        border-top: 4px solid ${gray5};
      `};
    }
    .button-container {
      position: ${getPosition()};
      left: calc(50% - ${button?.offsetWidth / 2}px);
      margin-top: ${tripleSpacing}px;
      bottom: ${window.innerHeight - scrollRef?.current?.getBoundingClientRect()?.bottom + doubleSpacing}px;
    }
  `;

  const unsubmittedAnswer = hasSelectedAtLeastOneOptions && !currentQuestionResponse;

  React.useEffect(() => forwardOnModalClose((event) => {
    if (isAnswerMode && unsubmittedAnswer) {
      event.preventDefault();

      dispatch(openConfirmationDialog({
        cancelText: t.FORM.CANCEL(),
        confirmText: t.FORM.YES_SURE(),
        onConfirm: () => closeModal(true),
        title: t.QUIZZES.ARE_YOU_SURE_TO_EXIT(),
      }));
    }
  }), [
    dispatch,
    closeModal,
    isAnswerMode,
    unsubmittedAnswer,
    forwardOnModalClose,
  ]);

  const handleSubmitAnswer = async () => {
    const userResponse = Object.entries(optionsState).filter(([key, value]) => value).map(([key]) => Number(key));

    setIsSubmitting(true);
    await dispatch(createQuizQuestionSubmission({
      questionId: currentQuestion!.id,
      userResponse: isMultipleAnswer ? userResponse : userResponse[0],
    }));
    setIsSubmitting(false);
  };

  const handleRetryQuestion = () => {
    unsetResponse();
    scrollRef.current.scrollTop = 0;
    dispatch(unsetQuizQuestionResponse(currentQuestionId));
  };

  const handleNext = () => {
    const nextQuestionIndex = currentQuestionIndex + 1;
    setIsSubmitting(true);
    fetchQuestion({
      page: nextQuestionIndex + 1,
    }).then(() => {
      setIsSubmitting(false);
    });
  };

  const handleFinish = () => {
    setIsSubmitting(true);
    wrapThunkAction(dispatch(submitQuizAttempt(progressiveQuiz.id))).then(({ payload }) => {
      const {
        pointsReceived,
        leaderboardRank,
        leaderboardPoints,
        priorLeaderboardRank,
        quiz: { progress },
      } = (payload as QuizSubmission);

      closeModal(true);

      timelineService.updateTimeline(currentLectureId);
      if (pointsReceived) {
        TimelinesManager.updateComponentPointsAndProgress(
          currentLectureId,
          lectureComponent.type,
          lectureComponent.id,
          pointsReceived,
          null,
          progress,
        );

        $uibModal.open({
          templateUrl: 'shared/templates/points-modal.html',
          windowClass: 'points-modal',
          controller: 'PointsModalCtrl as vm',
          resolve: {
            extras: null,
            pointsReceived,
            leaderboardRank,
            leaderboardPoints,
            priorLeaderboardRank,
          },
        });
      } else {
        TimelinesManager.updateComponentProgress(
          currentLectureId,
          lectureComponent.type,
          lectureComponent.id,
          progress,
        );
      }
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  const renderNextButton = () => (
    <Button
      size='sm'
      onClick={handleNext}
      data-qa={config.pendo.activities.progressiveQuiz.questionModal.next}
    >
      {t.SHARED.NEXT()}
    </Button>
  );

  const renderNextAction = () => (
    <>
      {(!areQuestionsLeft || (notMetRequirement() && pointsConfiguration?.passingScoreRequired)) ? (
        <Button
          size='sm'
          onClick={handleFinish}
          disabled={isSubmitting}
          data-qa={config.pendo.activities.progressiveQuiz.questionModal.finishQuiz}
        >
          {t.QUIZZES.FINISH_QUIZ()}
        </Button>
      ) : renderNextButton()}
    </>
  );

  return (
    <QuestionContext.Provider value={questionContextValue} key={currentQuestion.id}>
      <div ref={quizQuestionRef} css={styles} className={className}>
        <QuizQuestionContent className='mb-5' />
        {(isEditMode ? true : !!currentQuestion.picture?.cdnUrl) && (
          <div className='d-flex justify-content-center mb-5'>
            <QuizImage
              handleImageLoad={handleImageLoad}
              className='question-image'
            />
          </div>
        )}
        <QuizQuestionMultipleOptions className={isEditMode ? 'mb-6' : 'mb-5'} />
        {isEditMode ? (
          <div className='d-flex align-items-center mb-2'>
            <div className='label text-black mr-2'>
              {t.QUIZZES.HINTS_LABEL()}
            </div>
            <NvPopover
              showOnHover
              placement='top'
              content={(
                <div className=''>
                  {t.QUIZZES.HINTS_INFO(courseAliases)}
                </div>
              )}
            >
              <NvIcon icon='info' size='xs-smallest text-primary' className='d-flex' />
            </NvPopover>
          </div>
        ) : (
          <>
            {isCurrentFeedbackShown && (
              <div className='label text-black mb-2'>
                {t.QUIZZES.HINT()}
              </div>
            )}
          </>
        )}
        <div className='bottom-section'>
          <ProgressiveFeedback onVisibleChange={setIsCurrentFeedbackShown} />
          {showCorrectAnswer && (
            <CorrectAnswer className='mt-1' />
          )}
        </div>
        {isAnswerMode && (
          <div ref={buttonContainerRef} className='d-flex justify-content-center button-container'>
            {currentQuestionResponse ? (
              <>
                {currentQuestionResponse.isCorrect ? renderNextAction() : (
                  <>
                    {areAttemptsLeft ? (
                      <Button
                        size='sm'
                        onClick={handleRetryQuestion}
                        data-qa={config.pendo.activities.progressiveQuiz.questionModal.retry}
                        data-qa-id={`${config.pendo.activities.progressiveQuiz.questionModal.retry}_${currentQuestion.id}`}
                      >
                        {t.QUIZZES.RETRY_QUESTION()}
                      </Button>
                    ) : renderNextAction()}
                  </>
                )}
              </>
            ) : (
              <Button
                size='sm'
                onClick={handleSubmitAnswer}
                disabled={!hasSelectedAtLeastOneOptions || isSubmitting}
                data-qa={config.pendo.activities.progressiveQuiz.questionModal.submitAnswer}
                data-qa-id={`${config.pendo.activities.progressiveQuiz.questionModal.submitAnswer}_${currentQuestion.id}`}
              >
                {t.QUIZZES.SUBMIT_ANSWER()}
              </Button>
            )}
          </div>
        )}
      </div>
    </QuestionContext.Provider>
  );
};

export default ProgressiveQuizModal;
