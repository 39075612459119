import { css } from '@emotion/react';
import { useContext, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, Prompt, useParams, useRouteMatch } from 'react-router-dom';
import {
  getCourseExercises, getLecturePage, markLecturePageAsViewed,
  markLecturePageExited,
  setBottomBarVisibility, setLectureAppStateFromRouteParams, setLecturePageAsCached,
  setLeftPanelView, setLeftPanelVisibility, setTopBarVisibility, unsetLecturePageAsCached,
} from 'redux/actions/lecture-pages';
import {
  getFullCourseTimelineWithProgress, getFullCourseTimelineContent,
  getFullCourseTimelineProgress, resetIsTimelineContentLoaded,
} from 'redux/actions/timeline';
import { useAppDispatch } from 'redux/store';
import useWindowResize from 'shared/hooks/use-window-resize';
import useNavigationHandle from 'shared/hooks/use-navigation-handle';
import { isNotDesktop } from 'styles/global_defaults/media-queries';
import { AngularContext, AngularServicesContext } from 'react-app';
import { useQuery } from 'shared/hooks/use-query';
import t from 'react-translate';
import { getAllSkillTags } from 'redux/actions/skill-tags';
import { useLecturePageLink } from 'lecture_pages/hooks/lecture-routing';
import { isEmpty } from 'underscore';
import { LecturePage, PreventNavigationReason } from 'redux/schemas/models/lecture-page';
import { getAvailablePracticeActivities } from 'redux/actions/video-practice-feedback';
import { getCurrentUser } from 'redux/selectors/users';
import { translateLecturePage, translateOutline, unsetTranslationPreference } from 'redux/actions/users';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { getCurrentCourse } from 'redux/selectors/course';
import { getCollectionLecturePage, setHighlightedLessonId } from 'redux/actions/collections';
import { loadLecturePageModel } from './content-area/angular-lecture-component';
import LecturePageBottomPanel from './lecture-page-bottom-panel';
import LecturePageContent from './lecture-page-content';
import LecturePageLeftPanel from './left-panel/lecture-page-left-panel';
import { LecturePageUrlParams, courseLectureRouteString } from './lecture-page-routes';
import LecturePageTopPanel from './lecture-page-top-panel';
import { LecturePageMode } from '.';
import LecturePageContext, { FilesToUploadMapping, LecturePageContextType } from './lecture-page-context';
import CollectionCourseTopHeader from '../../content-library/components/lecture-page/top-header';

/** The url params as given by react-router. All other components should use `useLecturePageParams()` */
type LecturePageStringUrlParams = Record<keyof LecturePageUrlParams, string>;

const styles = css`
  position: relative;
  display: flex;

  // TODO: Track down the origin of this '5px'
  height: calc(100vh - 65px + 5px);
`;

/** The root UI container for the lecture page layout. Controls hiding/showing core lecture page components based on app state
 * & due to mobile responsiveness */
export const LecturePageContainer = () => {
  // We don't do useLecturePageParams here since that pull sfrom Redux and those values don't exist
  // until after the useEffect below. The standard LecturePageUrlParams type doesn't work where
  // since lecturePageId is not a string
  const urlParams = useParams<LecturePageStringUrlParams>();
  const query = useQuery();
  const params: LecturePageUrlParams = useMemo(() => ({
    catalogId: urlParams.catalogId,
    lecturePageId: parseInt(urlParams.lecturePageId, 10),
    mode: urlParams.mode as LecturePageMode,
    lectureActivityId: parseInt(query?.lectureActivityId, 10) ?? null,
  }), [urlParams.catalogId, urlParams.lecturePageId, urlParams.mode, query?.lectureActivityId]);

  const lecturePageModelRef = useRef<{ [id: string]: any }>({});
  const angularComponentsModelsRef = useRef<{ [id: string]: any }>({});

  const mode = params.mode ?? LecturePageMode.VIEW;
  const routeMatch = useRouteMatch();
  const dispatch = useAppDispatch();
  const lectureLink = useLecturePageLink();
  const { injectServices } = useContext(AngularContext);
  const [
    CurrentUserManager,
    ReactLecturePageContext,
  ] = injectServices([
    'CurrentUserManager',
    'ReactLecturePageContext',
  ]);
  const { $state, CurrentPermissionsManager, $injector } = useContext(AngularServicesContext);
  const { cachedLecturePageLookup, isTimelineContentLoaded } = useSelector(state => state.app.lecturePage);

  const [filesToUpload, setFilesToUpload] = useState<FilesToUploadMapping>({});
  const [isLastScreenNotDesktop, setIsLastScreenNotDesktop] = useState<boolean>(isNotDesktop());

  const currentUser = useSelector(getCurrentUser);

  const { id: currentUserId, translationPreferenceLanguage } = currentUser;

  const {
    id: currentInstitutionId,
    automatedTranslationEnabled: institutionTranslationEnabled,
  } = useSelector(getCurrentInstitution);

  const { automatedTranslationEnabled: courseTranslationEnabled, isContentManagementCollection, id: courseId } = useSelector(getCurrentCourse);

  const [floatLeftPanel, setFloatLeftPanel] = useState(false);

  const { preventPageNavigation } = useSelector(state => state.app.lecturePage);
  useNavigationHandle(preventPageNavigation);

  const lecturePage = useSelector(state => state.models.lecturePages[params.lecturePageId]);
  const lecturePageRef = useRef<LecturePage>();
  lecturePageRef.current = lecturePage;

  const redirectToCourseHome = () => {
    // $state is used here because this page is still managed by Angularjs
    $state.go('course-home', { catalogId: params.catalogId });
  };

  const fetchLecturePage = useCallback(() => dispatch(isContentManagementCollection ? getCollectionLecturePage({ lecturePageId: params.lecturePageId }) : getLecturePage(params)), [dispatch, isContentManagementCollection, params]);

  const setMarkLecturePageAsViewed = (lp: LecturePage) => {
    if (!isContentManagementCollection && mode === LecturePageMode.VIEW && !lp.viewed && isEmpty(lp.prerequisite)) {
      dispatch(markLecturePageAsViewed({
        lecturePageId: lp.id,
        catalogId: urlParams.catalogId,
      }));
    }
  };

  const lecturePageId = lecturePage?.id;

  useEffect(() => {
    const currentLecturePage = lecturePageRef.current;

    return () => {
      if (lecturePageId && mode === LecturePageMode.VIEW) {
        dispatch(markLecturePageExited(currentLecturePage.id));
      }
    };
  }, [dispatch, mode, lecturePageId]);

  // Setting the lesson as highlighted when accessing from a collection
  useEffect(() => {
    if (isContentManagementCollection) {
      dispatch(setHighlightedLessonId(lecturePageId));
    }
  }, [dispatch, isContentManagementCollection, lecturePageId]);

  useEffect(() => {
    lecturePageModelRef.current = loadLecturePageModel($injector, lecturePage);
  }, [lecturePage, $injector]);

  // Load the lecture page & timeline model data
  // TODO: Consider moving this into some `root-component.tsx` equivalent for the lecture page
  // I don't think having this here impacts us negatively, but it does seem weird
  useEffect(() => {
    let newPageMode = mode ?? LecturePageMode.VIEW;

    // Force redirect the user to the View mode version of this page if they're not authorized to view
    // the current page mode.
    // TODO: Verify you can't force your way around this.
    if (newPageMode === LecturePageMode.EDIT
      && !(CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isCourseBuilder() || CurrentPermissionsManager.isConfigAndRegistrationRole())) {
      lectureLink({
        ...params,
        mode: LecturePageMode.VIEW,
      });
    }

    // Set the mode to 'RestrictedEdit' if you're attempting to edit but not a CourseBuilder
    // RestrictedEdit shows the same url as Edit, but has a reduced set of features
    if (newPageMode === LecturePageMode.EDIT) {
      if (!CurrentPermissionsManager.isCourseBuilder()) {
        newPageMode = LecturePageMode.RESTRICTED_EDIT;
      } else if (lecturePage?.isLinked) {
        newPageMode = LecturePageMode.LINKED_EDIT;
      }
    }

    const isAdminMode = newPageMode === LecturePageMode.EDIT
      || newPageMode === LecturePageMode.REORDER
      || newPageMode === LecturePageMode.RESTRICTED_EDIT
      || newPageMode === LecturePageMode.LINKED_EDIT;

    dispatch(setLectureAppStateFromRouteParams({
      ...params,
      mode: newPageMode,
      isAdminMode,
      lectureActivityId: params.lectureActivityId,
    }));

    // Avoid re-fetching if cached
    const pageCached = cachedLecturePageLookup[params.lecturePageId];

    // Admins always refetch pages
    if (isAdminMode || !pageCached) {
      let beforePromise = null;

      if (
        translationPreferenceLanguage
        && !isAdminMode
        && institutionTranslationEnabled
        && courseTranslationEnabled
      ) {
        beforePromise = dispatch(translateLecturePage({
          lecturePageId: params.lecturePageId,
          catalogId: params.catalogId,
          language: translationPreferenceLanguage,
        })).then(() => {
          CurrentUserManager.user.updateFromReact({
            translationPreferenceLanguage,
            lastTranslationPreferenceLanguage: translationPreferenceLanguage,
          });
        });
      }

      const requestLecturePage = () => fetchLecturePage().then(resultAction => {
        // Navigate to the home page if the page can't be loaded, such as if a learner attempts to access an
        // unreleased lecture
        if (getLecturePage.rejected.match(resultAction)) {
          redirectToCourseHome();
          return;
        }

        // Mark unviewed lecture pages as viewed after a successful data load
        setMarkLecturePageAsViewed(resultAction.payload);

        if (isAdminMode || !isEmpty(resultAction.payload?.prerequisite)) {
          /**
           * If its in admin mode unset lecture page and caching is only needed
           * in view mode. And also unset the caching if the lecture page has any
           * enabled prerequisite.
           */
          dispatch(unsetLecturePageAsCached(params.lecturePageId));
        } else {
          dispatch(setLecturePageAsCached(params.lecturePageId));
        }
      });

      if (beforePromise) {
        beforePromise.then(requestLecturePage);
      } else {
        requestLecturePage();
      }
    } else if (!isEmpty(lecturePage)) {
      setMarkLecturePageAsViewed(lecturePage);
    }

    /** Refetch the list of exercises used to enable/disable the new component add buttons.
     * Doing this on every admin-mode page load seems excessive, but it matches prod behavior prior to the React lecture rewrite
     */
    if (!isContentManagementCollection && isAdminMode) {
      dispatch(getCourseExercises(params));
      dispatch(getAvailablePracticeActivities({ catalogId: params.catalogId }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params.catalogId,
    params.lecturePageId,
    params.mode,
    params.lectureActivityId,
    translationPreferenceLanguage,
    institutionTranslationEnabled,
    courseTranslationEnabled,
    lecturePage?.isLinked,
  ]);

  useEffect(() => {
    const fetchTimeline = () => {
      if (!isContentManagementCollection) {
        if (mode === LecturePageMode.VIEW) {
          // For non course builders we are using a different API which is an
          // optimization
          dispatch(getFullCourseTimelineContent({
            catalogId: params.catalogId,
          }));
        } else {
          dispatch(getFullCourseTimelineWithProgress({
            catalogId: params.catalogId,
            userId: currentUserId,
            outlineOnly: false,
            // So far, we only pass true for the case when this is dispatched by
            // entering the lecture page. Given lecture page cache is always cleared
            // in this case, it's safe to replace incoming lecture components from
            // course timeline API because as soon as we move to another lecture page,
            // the lecture page will get fetched and correct lecture components will
            // show up.
            updateLectureComponentData: true,
            editMode: true,
          }));
        }
      }
    };

    if (
      translationPreferenceLanguage
      && mode === LecturePageMode.VIEW
      && institutionTranslationEnabled
      && courseTranslationEnabled
    ) {
      dispatch(translateOutline({
        language: translationPreferenceLanguage,
        catalogId: params.catalogId,
      })).then(fetchTimeline);
    } else {
      fetchTimeline();
    }

    dispatch(getAllSkillTags({
      institutionId: currentInstitutionId,
    }));
  }, [
    params.catalogId,
    translationPreferenceLanguage,
    institutionTranslationEnabled,
    courseTranslationEnabled,
    mode,
    dispatch,
    currentInstitutionId,
    isContentManagementCollection,
    currentUserId,
  ]);

  useEffect(() => {
    let fullCourseTimelineProgressPromise;
    if (isTimelineContentLoaded) {
      const sectionId = lecturePageRef.current.lectureSectionId;

      if (mode === LecturePageMode.VIEW) {
        fullCourseTimelineProgressPromise = dispatch(getFullCourseTimelineProgress({
          catalogId: params.catalogId,
          sectionId,
        }));
      }
    }

    return () => {
      if (fullCourseTimelineProgressPromise) {
        fullCourseTimelineProgressPromise.abort();
      }
    };
  }, [isTimelineContentLoaded, dispatch, params.catalogId, mode]);

  // Reset the isTimelineContentLoaded, when exiting
  useEffect(() => () => {
    dispatch(resetIsTimelineContentLoaded());
  }, [dispatch]);

  // Get the page layout visibility. Note that using destructuring syntax
  // to select all visibility values at once will cause several unnecessary rerenders
  // due to the lecturePage object changing
  const isLeftPanelVisible = useSelector(state => state.app.lecturePage.isLeftPanelVisible);
  const isTopBarVisible = useSelector(state => state.app.lecturePage.isTopBarVisible);
  const isBottomBarVisible = useSelector(state => state.app.lecturePage.isBottomBarVisible);
  const leftPanelVisibilityPreference = useSelector(state => state.app.lecturePage.leftPanelVisibilityPreference);
  const shouldHideBottomBar = isContentManagementCollection && !CurrentPermissionsManager.hasCollectionViewerPermissions();

  // Show/hide the layout panels to fit the current mode
  // NOTE: Doing these dispatches on page load is causing one extra re-render
  // of this component due to the useSelectors above
  useEffect(() => {
    switch (mode) {
      case 'view':
        dispatch(setTopBarVisibility(false));
        dispatch(setBottomBarVisibility(true));
        dispatch(setLeftPanelView('outline'));
        break;
      case 'edit':
        dispatch(setTopBarVisibility(true));
        dispatch(unsetTranslationPreference()).then(() => {
          CurrentUserManager.user.updateFromReact({
            translationPreferenceLanguage: null,
          });
        });
        dispatch(setBottomBarVisibility(true));
        break;
      case 'reorder':
        dispatch(setTopBarVisibility(true));
        dispatch(setBottomBarVisibility(false));
        dispatch(setLeftPanelView('outline'));
        break;
        // TODO: Restricted edit mode
      default:
        break;
    }
  }, [dispatch, mode]);

  const leftPanelView = useSelector(state => state.app.lecturePage.leftPanelView);

  /* Hide the LHS when the window is smaller than a desktop resolution. We do not accomplish this via pure
  CSS because we want to write this value into the app state for use elsewhere */
  // TODO: Keep the left panel open in mobile when a component is being added
  const setLhsVisibility = () => {
    const shouldHideLhs = (isNotDesktop() && leftPanelView === 'outline')
      /**
       * Hiding the collection lesson LHS when redirecting from a course lessons
       * Or when the user does not have collection permissions.
       */
      || (isContentManagementCollection && (
        query.fromLinkedCourse
        || !CurrentPermissionsManager.hasCollectionViewerPermissions()
      ));

    if (isLeftPanelVisible && shouldHideLhs) {
      dispatch(setLeftPanelVisibility(false));
    } else if (leftPanelVisibilityPreference && !isLeftPanelVisible && !shouldHideLhs) {
      // Only toggle the LHS back open if the user has set it to be displayed
      dispatch(setLeftPanelVisibility(true));
    }

    if (isNotDesktop()) {
      setFloatLeftPanel(true);
    } else {
      setFloatLeftPanel(false);
    }
  };

  useEffect(() => {
    setLhsVisibility();
  }, [leftPanelView, mode, params.lecturePageId, query.fromLinkedCourse]);

  useWindowResize(() => {
    /**
     * only need to set the LHS visibility when changing the screen size to or
     * from desktop view. Otherwise, it will be set on each window resize.
     * And which causes NOV-76405 kind of issues.
     */
    if (isLastScreenNotDesktop !== isNotDesktop()) {
      setIsLastScreenNotDesktop(isNotDesktop());
      setLhsVisibility();
    }
  }, 10, false, [isLeftPanelVisible]);

  /** Disallow inexact route matches and redirect to the course home page when they occur.
   * angular-ui-router is already configured to do this redirection when urls do not match any angularjs state. However, if this React app is routed at the time a bad url is given, it will still respond to the new
   * url before angular-ui-router steps in. This requires us to do manual isExact checking here instead of using
   * a react-router <Redirect>. Failure to do this will cause (for instance) the user profile L4 to cause
   * a course home redirect */
  if (!routeMatch.isExact) {
    redirectToCourseHome();
  }

  const updateAngularComponentStatus = useCallback((componentType, componentId, data) => {
    Object.values(angularComponentsModelsRef.current).forEach((model) => {
      model.updateComponentStatus(componentType, componentId, data);
    });
  }, []);

  const lecturePageContextValue: LecturePageContextType = {
    lecturePageModelRef,
    angularComponentsModelsRef,
    filesToUpload,
    setFilesToUpload,
    updateLecturePage: fetchLecturePage,
    updateAngularComponentStatus,
  };

  ReactLecturePageContext.setContext(lecturePageContextValue);

  return (
    <div css={styles}>
      <Prompt
        message={(location) => {
          // Do not prevent nav on non lecture component pages. These are handled by the
          // ConfirmationOverlays service in lecture-page-routes
          if (!matchPath(location.pathname, {
            path: courseLectureRouteString,
          })) {
            return true;
          }

          if (preventPageNavigation === PreventNavigationReason.SAVING_IN_PROGRESS) {
            return `${t.FORM.SAVING_CHANGES.NAVIGATE_AWAY()}`;
          }

          if (preventPageNavigation === PreventNavigationReason.REORDER_IS_DIRTY) {
            return `${t.FORM.UNSAVED_CHANGES.NAVIGATE_AWAY_CHANGES()}`;
          }

          return true;
        }}
      />
      <LecturePageContext.Provider value={lecturePageContextValue}>
        {/**
         * Showing the top panel in normal courses. And also, the collection
         * course's reorder mode uses the same top panel.
         */}
        { isTopBarVisible
          && ((isContentManagementCollection && mode === LecturePageMode.REORDER)
            || !isContentManagementCollection
          ) && <LecturePageTopPanel />}
        { isContentManagementCollection && <CollectionCourseTopHeader /> }
        { (isLeftPanelVisible || leftPanelView !== 'outline')
          && <LecturePageLeftPanel floatAbove={floatLeftPanel} /> }
        <LecturePageContent floatLeftPanel={floatLeftPanel} />
        { (isBottomBarVisible && !shouldHideBottomBar) && <LecturePageBottomPanel /> }
      </LecturePageContext.Provider>
    </div>
  );
};

export default LecturePageContainer;
