import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Contexts
import { AngularServicesContext } from 'react-app';

// Schemas
import { PracticeRoomTab, SubmissionPrivacySetting } from 'redux/schemas/models/video-practice';

// Actions
import { useAppDispatch } from 'redux/store';
import { getScenario as getScenarioAction, setPracticeRoomState } from 'redux/actions/video-practice';

// Selectors
import { getScenario } from 'redux/selectors/video-practice';
import { getCurrentUser } from 'redux/selectors/users';

// Components
import { makeQueryString, useQuery } from 'shared/hooks/use-query';
import ScenarioOverview from './scenario-overview/scenario-overview';

/**
 * The purpose of this component is to push the Angular selected params to
 * history so that ScenarioOverview will be notified
 */


interface PracticeRoomLocationParams {
  user: number;
  submission: number;
  selected: PracticeRoomTab;
  galleryMode: string;
}


const ContentWrapper = () => {
  const dispatch = useAppDispatch();
  const { $state } = useContext(AngularServicesContext);
  const { scenarioId, selected, submissionView, user, submission, commentId, galleryMode = true, feedbackBy } = $state?.params;

  const history = useHistory();

  const scenario = useSelector((state) => getScenario(state, scenarioId));
  const currentUser = useSelector((state) => getCurrentUser(state));
  const scenarioLoaded = useSelector((state) => state.app.practiceRoom.scenarioLoaded[scenarioId]);

  useEffect(() => {
    if (scenarioId) {
      dispatch(setPracticeRoomState({
        scenarioId,
        userId: user,
        submissionId: submission,
        selectedTab: selected,
        selectedSubmissionTab: submissionView,
        newNotification: {
          submissionId: submission,
          commentId,
        },
        // gallery mode should be true except when the param value of gallerymode is 'false'
        // to restrict the user from viewing others submissions in the gallery
        // eg: when user has directed from org profile
        // ie: the the galleryMode will be true by default
        galleryMode: galleryMode !== 'false',
        feedbackBy,
      }));
      dispatch(getScenarioAction({
        scenarioId,
      })).then((res: any) => {
        if (res.payload?.response?.data?.error?.code === 'error.not_found'
          || res.payload?.response?.data?.error?.code === 'error.forbidden'
        ) {
          // If the getScenario request return a 404, may be this scenarioId
          // is invalid or it belongs to another user.
          // or if it returns 403 (instead of displaying blank page)
          // Redirect to dashboard.
          $state.go('dashboard');
        } else if (!(res.payload?.isPracticeAdmin || res.payload?.privacy === SubmissionPrivacySetting.SHARED) && selected === PracticeRoomTab.GALLERY) {
          // when practice room launched via angular route
          // wait till the scenario response to check whether the gallery restricted
          // and redirect accordingly
          history.push(makeQueryString({
            user,
            submission,
            submissionView,
            feedbackBy,
            selected: PracticeRoomTab.MY_PRACTICE,
            galleryMode,
          }));
        }
      });
    }
    if (selected) {
      history.push(makeQueryString({
        user,
        submission,
        submissionView,
        feedbackBy,
        selected,
        commentId,
        galleryMode,
      }));
    }
  }, [scenarioId, selected, submissionView, user, submission, commentId, dispatch, history, $state, galleryMode, feedbackBy]);

  // Check whether any location query params change is there and so
  // set Practice Room state
  const locationParams: PracticeRoomLocationParams = useQuery();
  const {
    user: locationUser,
    submission: locationSubmission,
    selected: locationSelected,
    galleryMode: locationGalleryMode = true,
  } = locationParams;

  // Location selected updates when the user switches the tab
  // If the user has admin or TA permission, the practice room should land on the gallery tab
  // But if the user redirected from any other page eg: from the mini gallery in lecture page,
  // If it's their own submission land on my practice tab
  // If the privacy set to "private" and user does not have required permission,
  // do not redirect them to the gallery (it is forbidden)
  let selectedTabParam = locationSelected ?? (
    (scenario?.isPracticeAdmin || (locationUser && +locationUser !== currentUser.id)) ? PracticeRoomTab.GALLERY : PracticeRoomTab.MY_PRACTICE
  );
  if (!(scenario?.isPracticeAdmin
    || scenario?.privacy === SubmissionPrivacySetting.SHARED
  ) && selectedTabParam === PracticeRoomTab.GALLERY) {
    selectedTabParam = PracticeRoomTab.MY_PRACTICE;
  }

  useEffect(() => {
    if (scenario && scenarioLoaded) {
      dispatch(setPracticeRoomState({
        scenarioId: scenario.id,
        userId: locationUser,
        submissionId: locationSubmission,
        selectedTab: locationSelected,
        galleryMode: locationGalleryMode !== 'false',
        selectedSubmissionTab: submissionView,
        feedbackBy,
      }));
      // set default tab in react route
      if (!selected && !locationSelected) {
        history.push(makeQueryString({ selected: selectedTabParam }));
      }
    }
  }, [
    scenario,
    locationUser,
    locationSubmission,
    locationSelected,
    dispatch,
    selected,
    submissionView,
    history,
    selectedTabParam,
    scenarioLoaded,
    locationGalleryMode,
    feedbackBy,
  ]);

  useEffect(() => {
    if (locationSubmission) {
      dispatch(setPracticeRoomState({
        selectedSubmissionTab: null,
        feedbackBy: null,
      }));
    }
  }, [dispatch, locationSubmission]);

  return (<ScenarioOverview />);
};

export default ContentWrapper;
