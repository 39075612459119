import t from 'react-translate';
import { css } from '@emotion/react';
import {
  quarterSpacing, standardSpacing, threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';

const styles = css`
  .lds-grid {
    position: relative;
    margin-top: 80px;
    flex-direction: column;
  }
  .lds-grid div {
    margin-left: 50%;
    position: absolute;
    animation: lds-grid 2.2s linear infinite;
  }
  .lds-grid div:nth-of-type(1) {
    top: ${threeQuartersSpacing}px;
    left: -35px;
    animation-delay: 0s;
  }
  .lds-grid div:nth-of-type(2) {
    top: ${threeQuartersSpacing}px;
    left: ${quarterSpacing}px;
    animation-delay: -0.4s;
  }
  .lds-grid div:nth-of-type(3) {
    top: 0px;
    left: 0px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-of-type(4) {
    top: 0px;
    left: ${threeQuartersSpacing}px;
    animation-delay: -1.2s;
  }

  @keyframes lds-grid {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
  }
  .text-container {
    margin-top: 80px;
    text-align: center;
    flex-direction: column;
  }
  h1 {
    font-size: ${standardSpacing}px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
  }

  .aniText {
    background: linear-gradient(
      to right,
      #60f3ab 20%,
      #14d1c2 40%,
      #60f3ab 60%,
      #14d1c2 80%
    );
    background-size: 200% auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent; /* For non-webkit browsers, not effective without text-fill-color support */
    animation: shine 3s linear infinite;
  }

  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
  p {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    max-width: 720px;
    margin: auto;
  }
`;

const AiQuestionsModalLoader = () => (
  <div
    className='d-flex flex-column align-items-center justify-content-center'
    css={styles}
  >
    <div className='lds-grid'>
      <div>
        <svg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M24.9937 0.772095L31.9215 18.3379L49.4873 25.2658L31.9215 32.1936L24.9937 49.7594L18.0658 32.1936L0.5 25.2658L18.0658 18.3379L24.9937 0.772095Z' fill='#00CCBC' />
        </svg>
      </div>
      <div>
        <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M11.6265 6.32914L7.54139 7.94027L5.93026 12.0253L4.31913 7.94027L0.234059 6.32914L4.31913 4.71801L5.93026 0.632934L7.54139 4.71801L11.6265 6.32914Z' fill='#60F3AB' />
        </svg>
      </div>
      <div>
        <svg width='9' height='8' viewBox='0 0 9 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M4.43663 0L5.24219 2.9919L8.2341 3.79747L5.24219 4.60303L4.43663 7.59494L3.63106 4.60303L0.63916 3.79747L3.63106 2.9919L4.43663 0Z' fill='#00CCBC' />
        </svg>
      </div>
      <div>
        <svg width='7' height='7' viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M6.5 3.78487L4.05468 4.18765L3.6519 6.63297L3.24912 4.18765L0.803797 3.78487L3.24912 3.38209L3.6519 0.936767L4.05468 3.38209L6.5 3.78487Z' fill='#00CCBC' />
        </svg>
      </div>
    </div>
    <div className='text-container text-center'>
      <h1 className='aniText my-3 text-center'>
        {t.LECTURE_PAGES.COMPONENTS.GENERATING()}
      </h1>
      <p className='text-large-regular text-gray-2 text-center'>
        {t.LECTURE_PAGES.COMPONENTS.QUIZ.AI_QUESTIONS.LOADER_DESCRIPTION()}
      </p>
    </div>
  </div>
);

export default AiQuestionsModalLoader;
