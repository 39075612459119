
import { Dispatch, SetStateAction, useRef, useState, useEffect, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import {
  VideoPracticeActivity,
  VideoPracticeOption,
  VideoPracticeScenario,
  VideoPracticeType,
  VideoPracticeSubmission,
  RecordingFormat,
} from 'redux/schemas/models/video-practice';
import { completeVideoPracticeSubmission, leaveVideoPracticeSubmission, startVideoPracticeSubmission } from 'redux/actions/video-practice';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { NovoEdFile } from 'shared/hooks/use-upload-file';

import { AngularServicesContext } from 'react-app';
import { hasGetDisplayMedia } from 'recording/services/media-stream';
import usePreventLecturePageNavigation from 'lecture_pages/hooks/use-prevent-lecture-page-navigation';
import { css } from '@emotion/core';
import GetReadyConfirmationOverlay from './get-ready-confirmation-overlay';
import ViewPracticeActivity from './view-practice-activity';
import RecordPracticeActivity, { OnStopRecordingParams } from './record-practice-activity';
import ReviewPracticeActivity from './review-practice-activity';
import { useGetVideoPracticeLectureComponent } from './video-practice-utils';
import SelectRecordingMode from './select-recording-mode';

import { config } from '../../../../config/pendo.config.json';

type PracticeActivityModalProps = {
  practiceType: VideoPracticeType;
  scenario: VideoPracticeScenario;
  activity?: VideoPracticeActivity;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  afterSubmit?: (submission: VideoPracticeSubmission) => void;
  afterLeave?: () => void;
};

export enum PracticeActivityModalSteps {
  GET_READY = 'GET_READY',
  VIEW = 'VIEW',
  SELECT_MODE = 'SELECT_MODE',
  RECORD = 'RECORD',
  REVIEW = 'REVIEW',
}

export enum RecordMode {
  CAMERA = 'camera',
  SCREEN = 'screen',
}

const modalSteps = [
  PracticeActivityModalSteps.GET_READY,
  PracticeActivityModalSteps.VIEW,
  PracticeActivityModalSteps.SELECT_MODE,
  PracticeActivityModalSteps.RECORD,
  PracticeActivityModalSteps.REVIEW,
];

const modalStyle = css`
  overflow-y: auto;
`;

const PracticeActivityModal = ({
  practiceType,
  activity,
  scenario,
  setShowModal,
  afterSubmit,
  afterLeave,
}: PracticeActivityModalProps) => {
  const dispatch = useAppDispatch();

  const { $scope } = useContext(AngularServicesContext);

  const { practiceActivity: practiceActivityId } = useGetVideoPracticeLectureComponent() || {};

  const modalRef = useRef<any>();
  const deregisterStateChangeStartRef = useRef(null);
  const noTimeAndNoTakesRef = useRef(null);

  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const [recordMode, setRecordMode] = useState(RecordMode.CAMERA);

  const [modalStepIndex, setModalStepIndex] = useState<number>(0);
  const [blob, setBlob] = useState<Blob>();
  const [lengthOfRecording, setLengthOfRecording] = useState<number>();
  const [take, setTake] = useState<number>(1);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const submissionId = useSelector((state) => state.app.videoPractice.submissionId);
  const [videoPromptViewed, setVideoPromptViewed] = useState(false);
  const [isReviewTimeExpired, setIsReviewTimeExpired] = useState(false);

  const [showPrompt, setShowPrompt] = useState(scenario.recordingFormat === RecordingFormat.AUDIO);
  const [promptFontSize, setPromptFontSize] = useState(16);

  const screenShareSupported = hasGetDisplayMedia();

  const leaveActivity = useCallback(() => {
    modalRef.current?.stopRecording?.();
    setShowModal(false);
    if (practiceType === VideoPracticeOption.ON_SPOT) {
      dispatch(leaveVideoPracticeSubmission({ activityId: activity?.id }));
      afterLeave?.();
    }
    deregisterStateChangeStartRef.current?.();
  }, [activity?.id, afterLeave, dispatch, practiceType, setShowModal]);

  const onClose = useCallback(() => {
    if (modalSteps[modalStepIndex] === PracticeActivityModalSteps.GET_READY) {
      setShowModal(false);
    } else {
      modalRef.current?.pause();
      dispatch(openConfirmationDialog({
        title: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.LEAVE.ARE_YOU_SURE(),
        bodyText: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.LEAVE[
          practiceType === VideoPracticeOption.ON_SPOT
            ? 'ON_SPOT'
            : 'WHEN_READY'
        ](),
        confirmText: t.FORM.LEAVE_ANYWAY(),
        onCancel: () => {
          modalRef.current?.resume();
        },
        onConfirm: leaveActivity,
      }));
    }
  }, [dispatch, modalStepIndex, practiceType, setShowModal, leaveActivity]);

  const onReady = useCallback(() => {
    dispatch(startVideoPracticeSubmission({
      scenarioId: scenario.id,
      catalogId,
      activityId: activity?.id,
    }))
      .then(res => {
        if (res.payload instanceof Error) {
          onClose();
        } else {
          setModalStepIndex(1);
        }
      });
  }, [dispatch, scenario.id, activity?.id, catalogId, onClose, setModalStepIndex]);

  const onNextStep = (mode?: RecordMode) => {
    if (mode) {
      setRecordMode(mode);
    }
    if (modalStepIndex === 1 && (!screenShareSupported || scenario.recordingFormat === RecordingFormat.AUDIO)) {
      // Skipping third step (Select Mode; note that this is 0-indexed) when the browser is not compatible for screen sharing, or it's Audio Practice
      setModalStepIndex(3);
    } else {
      setModalStepIndex(step => step + 1);
    }
  };

  const onStopRecording = (params: OnStopRecordingParams) => {
    setBlob(params.blob);
    setLengthOfRecording(params.timeElapsed);
    onNextStep();
  };

  useEffect(() => {
    noTimeAndNoTakesRef.current = isReviewTimeExpired && take >= activity?.maxTries;
  }, [isReviewTimeExpired, take, activity?.maxTries]);

  useEffect(() => {
    if (modalStepIndex && !deregisterStateChangeStartRef.current) {
      deregisterStateChangeStartRef.current = $scope.StateManager.registerStateChangeStart(
        () => true,
        'shared/templates/leave-practice-activity.html',
        'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY',
        'LeavePracticeActivityCtrl',
        {
          vmResolves: {
            practiceType,
            resume: () => modalRef.current?.resume(),
            noTimeAndNoTakes: () => noTimeAndNoTakesRef.current,
          },
        },
        leaveActivity,
        () => modalRef.current?.pause(),
      );
    }
  }, [modalStepIndex, take, practiceType, activity?.maxTries, isReviewTimeExpired]);

  usePreventLecturePageNavigation(() => true);

  const onRetry = () => {
    const bodyText = practiceType === VideoPracticeOption.ON_SPOT
      ? t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RETRY.ON_SPOT(activity.maxTries - take)
      : t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RETRY.WHEN_READY();

    modalRef.current?.pause();

    dispatch(openConfirmationDialog({
      title: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RETRY.ARE_YOU_SURE(),
      bodyText,
      confirmText: t.FORM.YES_SURE(),
      onConfirm: () => {
        modalRef.current?.stopRecording?.();
        restart();
      },
      onCancel: () => {
        modalRef.current?.resume();
      },
    }));
  };

  const restart = (stepIndex?: number) => {
    if (practiceType === VideoPracticeOption.ON_SPOT) {
      setTake(retry => retry + 1);
    }
    setModalStepIndex(stepIndex ?? 1);
    setIsSubmitting(false);
    setIsReviewTimeExpired(false);
  };

  const onSubmit = (videoFile: NovoEdFile) => {
    dispatch(completeVideoPracticeSubmission({
      scenarioId: scenario.id,
      activityId: practiceActivityId,
      submissionId,
      videoFile: {
        ...videoFile,
        length: lengthOfRecording,
      },
    })).then(res => {
      deregisterStateChangeStartRef.current?.();
      afterSubmit(res.payload as VideoPracticeSubmission);
    });
  };

  const onCloseHandler = isSubmitting || modalSteps[modalStepIndex] === PracticeActivityModalSteps.GET_READY ? null : onClose;

  const getModalBody = () => {
    switch (modalSteps[modalStepIndex]) {
      case PracticeActivityModalSteps.GET_READY:
        return (
          <GetReadyConfirmationOverlay
            practiceType={practiceType}
            scenario={scenario}
            onCancel={onClose}
            onReady={onReady}
          />
        );
      case PracticeActivityModalSteps.VIEW:
        return (
          <ViewPracticeActivity
            ref={modalRef}
            practiceType={practiceType}
            scenario={scenario}
            activity={activity}
            modalStepIndex={modalStepIndex}
            onNextStep={onNextStep}
            take={take}
            videoPromptViewed={videoPromptViewed}
            setVideoPromptViewed={setVideoPromptViewed}
          />
        );
      case PracticeActivityModalSteps.SELECT_MODE:
        return (
          <SelectRecordingMode
            ref={modalRef}
            practiceType={practiceType}
            scenario={scenario}
            activity={activity}
            modalStepIndex={modalStepIndex}
            onNextStep={onNextStep}
            take={take}
            onRetry={onRetry}
          />
        );
      case PracticeActivityModalSteps.RECORD:
        return (
          <RecordPracticeActivity
            ref={modalRef}
            practiceType={practiceType}
            scenario={scenario}
            activity={activity}
            modalStepIndex={modalStepIndex}
            onStopRecording={onStopRecording}
            onRetry={onRetry}
            take={take}
            mode={recordMode}
            selectRecordingMode={() => setModalStepIndex(2)}
            showPrompt={showPrompt}
            setShowPrompt={setShowPrompt}
            promptFontSize={promptFontSize}
            setPromptFontSize={setPromptFontSize}
          />
        );
      case PracticeActivityModalSteps.REVIEW:
        return (
          <ReviewPracticeActivity
            ref={modalRef}
            practiceType={practiceType}
            modalStepIndex={modalStepIndex}
            blob={blob}
            scenario={scenario}
            activity={activity}
            onRetry={onRetry}
            onSubmit={onSubmit}
            take={take}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
            restart={restart}
            isReviewTimeExpired={isReviewTimeExpired}
            setIsReviewTimeExpired={setIsReviewTimeExpired}
            showPrompt={showPrompt}
            setShowPrompt={setShowPrompt}
            promptFontSize={promptFontSize}
            setPromptFontSize={setPromptFontSize}
          />
        );
      default:
        return null;
    }
  };

  return (
    <NvModal
      type={ModalType.NO_DIALOG}
      backdrop={false}
      onClose={onCloseHandler}
      body={getModalBody()}
      closePendoTagName={config.pendo.practice.closeModal}
      modalStyles={modalStyle}
    />
  );
};

export default PracticeActivityModal;
