import { jsx, css } from '@emotion/react';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';

// Schemas
import { UploadedFile } from 'redux/schemas/models/file-uploads';
import { RootState } from 'redux/schemas';
import { PracticeSubmissionComment } from 'redux/schemas/models/video-practice';
import { getCurrentUserId } from 'redux/selectors/timeline';
import { BaseUser } from 'redux/schemas/models/my-account';

// Actions
import { removeHighlight } from 'redux/actions/video-practice';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';

// Selectors
import { getCurrentInstitution } from 'redux/selectors/institutions';

// Helpers
import { probablySupportsVideoType } from 'recording/services/media-visualizer-helper';

// Styles
import {
  black,
  gray7,
  hexToRgbaString,
  primary,
  warning,
} from 'styles/global_defaults/colors';
import {
  doubleSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { isHandheld, notHandheld } from 'styles/global_defaults/media-queries';

// Components
import { NvUserAvatar } from 'components/nv-user-avatar';
import { forceTwoDigits } from 'recording/components/stopwatch';
import { AngularServicesContext, ExposedAngularServices } from 'react-app';
import ClickableContainer from 'components/clickable-container';
import NvIcon from './nv-icon';
import NvVideoPreview from './nv-video-preview';
import NvLikeButton from './nv-like-button';
import NvCommentForm from './nv-comment-form';
import ResponsivelyEmbeddedAngularHTML from './responsively-embedded-angular-html';
import NvTooltip from './nv-tooltip';

/**
 * This comment row is used in Video Practice. You can reuse this component
 * if your design matches with the Practice Room comments section.
 */

type CommentProps = {
  commentId?: number
  isHighlighted?: boolean
  playOnLoad?: boolean // This is applicable for video comment only
  onLike?: () => void
  onUndoLike?: () => void
  getLikersInfo?: () => void
  onDelete?: (isUserFirstReview: boolean) => void
  onUpdate?: (comment: any) => Promise<boolean>
  onReply?: (any) => Promise<boolean>
  onTimestampClick?: (comment) => void
  lastVisitedAt?: string
  canDelete?: boolean
  displayUserRoleBadge?: boolean
  mentionableUsers?: BaseUser[]
  loadMentionableUsers?: () => void
  allSubmissionCommentsLoaded?: boolean
};

const timestampStyles = css`
  height: ${standardSpacing}px;
  color: ${primary};
  border: 1px solid ${primary};
  border-radius: ${threeQuartersSpacing}px;
  cursor: pointer;

  .icon {
    /* Custom icon size */
    font-size: 10px;
  }
`;

const getTimestamp = (value: number): string => {
  const minutesDisplay = (forceTwoDigits(Math.floor(value / 60)));
  const secondsDisplay = (forceTwoDigits(value % 60));

  return `${minutesDisplay}:${secondsDisplay}`;
};

export const Timestamp = (props: { value: number }) => {
  const timestamp = getTimestamp(props.value);

  return (
    <div
      css={timestampStyles}
      className='d-flex justify-content-around align-items-center py-2'
    >
      <NvIcon icon='timedexam' size='xss-extra-smallest-size' className='px-1' />
      <div className='text-small pr-1'>{timestamp}</div>
    </div>
  );
};

const videoCommentStyles = (thumbnailUrl) => css`
  .video-comment-preview {
    position: relative;
    height: 170px;
    ${notHandheld(css`width: 300px;`)}
    background: ${thumbnailUrl ? `url('${thumbnailUrl}')` : black};
    background-size: cover;

    .mask {
      position: absolute;
      background-color: ${hexToRgbaString(black, 0.3)};
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .duration {
      position: absolute;
      top: ${threeQuartersSpacing}px;
      left: ${threeQuartersSpacing}px;
      z-index: 1;
      border-radius: ${quarterSpacing}px;
      background-color: ${hexToRgbaString(black, 0.4)};
      padding: 2px ${quarterSpacing}px;
    }

    .play-button {
      position: absolute;
      bottom: ${standardSpacing}px;
      right: ${standardSpacing}px;
      height: ${doubleSpacing}px;
      width: ${doubleSpacing}px;
      z-index: 1;
      .icon {
        transform: rotate(-90deg);
      }
    }
  }
  .video-comment-play {
    display: none;
  }
`;

type VideoCommentProps = {
  file: UploadedFile,
  playOnLoad?: boolean,
};

export const VideoComment = (props: VideoCommentProps) => {
  const { file: { length }, playOnLoad } = props;

  const videoDiv = useRef(null);
  const previewDiv = useRef(null);
  const playerInstance = useRef(null);

  useEffect(() => {
    if (playOnLoad && playerInstance.current) {
      onPlay();
    }
  }, [playOnLoad]);

  const onPlay = () => {
    videoDiv.current.style.display = 'block';
    previewDiv.current.className = '';
    previewDiv.current.style.display = 'none';
    if (playerInstance.current) {
      playerInstance.current.play(true);
    }
  };

  const timestamp = getTimestamp(length);

  return (
    <div css={videoCommentStyles(props.file?.thumbnailUrl)}>
      <div className='video-comment-play' ref={videoDiv}>
        <NvVideoPreview file={props.file} ref={playerInstance} />
      </div>
      <div
        className='video-comment-preview d-flex flex-column justify-content-between'
        ref={previewDiv}
      >
        {props.file?.thumbnailUrl ? (
          <div className='mask' />
        ) : (
          <div className='d-flex flex-column justify-content-center align-items-center h-100 text-gray-4 text-small'>
            <div>{t.PRACTICE_ROOM.COMMENTS.TRANSCODING_IN_PROGRESS.FIRST_LINE()}</div>
            <div>{t.PRACTICE_ROOM.COMMENTS.TRANSCODING_IN_PROGRESS.SECOND_LINE()}</div>
          </div>
        )}
        {length && (
          <div className='duration d-flex align-items-center text-white'>
            <NvIcon icon='video-practice-on-cards' size='xss-smallest' />
            <div className='font-weight-bold text-xs ml-1'>
              {timestamp}
            </div>
          </div>
        )}
        {(props.file.hasBeenTranscoded || probablySupportsVideoType(props.file.mimeType || props.file.type)) && (
          <div className='play-button d-flex rounded-circle align-items-center justify-content-center text-white bg-primary'>
            <NvIcon
              icon='dropdown-arrow'
              size='small'
              altLabel={t.PRACTICE_ROOM.PLAY_VIDEO()}
              onClick={onPlay}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const commentRowstyles = css`
  .comment-row {
    .nv-user-avatar {
      width: 80px;
    }

    .own-comment {
      opacity: 0;
    }
    &:hover, &:focus-within {
      .own-comment {
        opacity: 1;
      }
    }
    .comment-body {
      a[oe-mention] {
        background-color: transparent;
        color: ${primary};
      }
    }
  }
  .highlighted {
    border-left: solid 3px;
    background-color: ${gray7};
    border-color: ${warning};
  }
  .fade-highlighted {
    animation-name: fadeAway;
    animation-duration: 5s;

    @keyframes fadeAway {
      0% {
        border-left: solid 3px;
        background-color: ${gray7};
        border-color: ${warning};
      }
      100% {
        border-left: solid 3px;
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
  .actions {
    & > * {
      cursor: pointer;
    }
  }
`;

const NvCommentRow = ({
  commentId,
  isHighlighted,
  playOnLoad,
  onLike,
  onUndoLike,
  getLikersInfo,
  onDelete,
  onUpdate,
  onReply,
  onTimestampClick,
  lastVisitedAt,
  canDelete,
  displayUserRoleBadge = false,
  mentionableUsers,
  loadMentionableUsers,
  allSubmissionCommentsLoaded,
}: CommentProps) => {
  const highlightedRow = useRef(null);
  const commentRef = useRef(null);

  const comment = useSelector<RootState, PracticeSubmissionComment>((state) => state.models.comments[commentId]);
  const currentUserId = useSelector<RootState, number>((state) => getCurrentUserId(state));
  const currentInstitution = useSelector((state) => getCurrentInstitution(state));

  const [isEditing, setIsEditing] = useState(false);
  const [isNewReply, setNewReply] = useState(false);
  const angularServices: ExposedAngularServices = useContext(AngularServicesContext);
  useEffect(() => {
    if (allSubmissionCommentsLoaded && (comment.highlighted || isHighlighted)) {
      setTimeout(() => {
        if (highlightedRow.current) {
          highlightedRow.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        // A timeout of 100 millisec to finish the transition (if any) before scrolling
      }, 100);
    }
  }, [allSubmissionCommentsLoaded, comment.highlighted, isHighlighted, comment]);

  const isMyComment = comment?.user?.id === currentUserId;
  const dispatch = useAppDispatch();
  // highlight the comment if isHighlighted or
  // when a new comment is posted by another user
  // ie., comment created > last visited at
  const highlightedComment = isHighlighted
    || (lastVisitedAt
      && moment(comment.createdAt).isAfter(moment(lastVisitedAt))
      && !isMyComment
    );

  const onDeleteComment = () => {
    dispatch(openConfirmationDialog({
      title: t.DISCUSSIONS.DELETE_POST_MSG(),
      confirmText: t.NOVOED.DELETE(),
      cancelText: t.DISCUSSIONS.DECLINE(),
      onConfirm: () => onDelete(comment.isUserFirstReview),
      onCancel: () => {
        setTimeout(() => {
          if (commentRef?.current) {
            commentRef.current.blur();
          }
        });
      },
    }));
  };

  const onUpdateComment = async (post: any) => {
    const isSubmitted = await onUpdate(post);
    if (isSubmitted) {
      setIsEditing(false);
      return true;
    }
    return false;
  };

  const onReplyToComment = async (post: any) => {
    const isSubmitted = await onReply(post);
    if (isSubmitted) {
      setNewReply(false);
    }
    return false;
  };

  if (isEditing) {
    return (
      <NvCommentForm
        isEdit
        content={comment.body}
        onSubmit={onUpdateComment}
        onCancel={() => setIsEditing(false)}
        mentionableUsers={mentionableUsers}
        loadMentionableUsers={loadMentionableUsers}
      />
    );
  }

  let commentBody: any = (
    <ResponsivelyEmbeddedAngularHTML
      template={comment.body}
      angularServices={angularServices}
    />
  );
  if (comment.video) {
    commentBody = <VideoComment file={comment.video} playOnLoad={playOnLoad} />;
  }

  const commentRowClassNames = ['comment-row d-flex my-1 p-2'];
  if (isHandheld()) {
    commentRowClassNames.push('flex-column');
  }
  if (highlightedComment) {
    commentRowClassNames.push('highlighted');
  } else if (comment.highlighted) {
    commentRowClassNames.push('fade-highlighted');
  }

  return (
    comment && (
      <div css={commentRowstyles}>
        <div
          className={commentRowClassNames.join(' ')}
          ref={highlightedRow}
          onAnimationEnd={() => dispatch(removeHighlight({ commentId }))}
        >
          <div className={isHandheld() ? 'd-flex justify-content-between pr-2' : 'user-avatar-container'}>
            <NvUserAvatar
              borderType='round'
              size='md'
              user={comment.user}
              displayName
              className='px-2 mb-1'
              alignNameRight={isHandheld()}
              directToOrgLevelProfile
              displayRoleBadge={displayUserRoleBadge}
            />
            {isHandheld() && (
              comment?.videoTimestamp && (
              <ClickableContainer onClick={() => onTimestampClick?.(commentId)}>
                <Timestamp value={comment.videoTimestamp} />
              </ClickableContainer>
              )
            )}
          </div>
          <div className='ml-2 w-100 pr-4'>
            {comment?.isUserFirstReview && comment.publicFeedback?.title && (
            <div className='d-flex align-items-center mb-2'>
              <NvTooltip text={t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.SUBMISSION_FEEDBACK_TAG.TOOLTIP(comment.publicFeedback?.title)}>
                <NvIcon icon='peer-feedback' size='medium' className='gray-2' />
              </NvTooltip>
              <div className='bg-gray-2 rounded px-2 py-1 text-small text-white ml-2'>
                {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.SUBMISSION_FEEDBACK_TAG.PEER_FFEDBACK_SUBMISSION()}
              </div>
            </div>
            )}
            <div className='d-flex justify-content-between'>
              <div className='comment-body w-100'>
                {commentBody}
              </div>
              {comment?.videoTimestamp && !isHandheld() && (
              <ClickableContainer onClick={() => onTimestampClick?.(commentId)}>
                <Timestamp value={comment.videoTimestamp} />
              </ClickableContainer>
              )}
            </div>
            <div className='d-flex justify-content-between mt-4'>
              <div className='actions d-flex align-items-center'>
                <NvLikeButton
                  isLiked={comment?.liked}
                  likedCount={comment?.votesCount}
                  likedUsers={comment?.likers}
                  iconSize='smallest'
                  iconClassName={!isMyComment ? 'icon-like' : ''}
                  textClassName='text-small text-primary mx-1'
                  onLike={onLike}
                  onUndoLike={onUndoLike}
                  isDisabled={isMyComment}
                  getLikersInfo={getLikersInfo}
                  showLikersInfo
                />
                <ClickableContainer
                  className='text-regular text-gray-3 mx-4'
                  onClick={() => setNewReply(true)}
                >
                  {t.DISCUSSIONS.REPLY()}
                </ClickableContainer>
                {!currentInstitution.disableDiscussionEdits && isMyComment && comment.body && !comment.video && (
                <ClickableContainer
                  className='own-comment text-regular text-gray-3 mr-4'
                  onClick={() => setIsEditing(true)}
                >
                  {t.DISCUSSIONS.EDIT()}
                </ClickableContainer>
                )}
                {(isMyComment || canDelete) && (
                <ClickableContainer
                  ref={commentRef}
                  className='own-comment text-regular text-gray-3 mr-4'
                  onClick={onDeleteComment}
                >
                  {t.NOVOED.DELETE()}
                </ClickableContainer>
                )}
              </div>
              <div className='text-date text-gray-2'>
                {moment(comment?.createdAt).format('lll')}
              </div>
            </div>
          </div>
        </div>
        {isNewReply && (
          <NvCommentForm
            isReply
            mentionedUser={comment.user}
            onSubmit={onReplyToComment}
            onCancel={() => setNewReply(false)}
            timestamp={comment.videoTimestamp}
            mentionableUsers={mentionableUsers}
            loadMentionableUsers={loadMentionableUsers}
          />
        )}
      </div>
    )
  );
};

export default NvCommentRow;
