import { useCallback, useEffect, useState } from 'react';
import t from 'react-translate';

import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { wrapThunkAction } from 'redux/utils';

import { getAiQuizQuestions } from 'redux/actions/quizzes';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AiQuestionType, QuizAIQuestion } from 'redux/schemas/models/quiz';
import { AlertMessageType } from 'redux/schemas/app/alert-message';

const QUESTION_SET_LENGTH = 5;
const MAX_QUESTION_LENGTH = 15;

const QUESTIONS_COUNT = {
  [AiQuestionType.MULTIPLE_CHOICE_SINGLE_ANSWER]: 6,
  [AiQuestionType.MULTIPLE_CHOICE_MULTIPLE_ANSWER]: 3,
  [AiQuestionType.FILL_BLANKS]: 3,
  [AiQuestionType.TRUE_OR_FALSE]: 3,
};

const useQuizAiQuestions = () => {
  const [isLoadingQuestion, setIsLoadingQuestion] = useState<boolean>(true);
  const [showingQuestionsCount, setShowingQuestionsCount] = useState<number>(QUESTION_SET_LENGTH);
  const [questions, setQuestions] = useState<QuizAIQuestion[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const catalogId: string = useSelector((state) => state.app.currentCatalogId);
  const selectedAIContent = useSelector(state => state.app.lecturePage.novoAI.content);
  const lectureComponentToReplace = useSelector(state => state.app.lecturePage.novoAI?.lectureComponentToReplace);

  const dispatch = useAppDispatch();

  const fetchQuestions = useCallback(() => {
    if (catalogId) {
      const params = {
        catalogId,
        aiProperties: {
          aiOriginTarget: selectedAIContent,
          existingQuizLectureComponentId: lectureComponentToReplace?.id,
        },
      };

      // Creating multiple requests based on the question type
      const promises = Object.values(AiQuestionType).map((type) => wrapThunkAction(dispatch(getAiQuizQuestions(
        {
          ...params,
          aiProperties: {
            ...params.aiProperties,
            quizType: type,
            questionsCount: QUESTIONS_COUNT[type],
          },
        },
      ))));

      Promise.all(promises).then((response) => {
        const [
          /**
           * Promises are created based on the AiQuestionType so the order will
           * be the same as the items in the type.
           */
          multiChoiceSingle,
          multiChoiceMulti,
          trueOrFalse,
          fillBlanks,
        ] = response.map(({ payload = [] }) => payload as QuizAIQuestion[]);

        const selectedQuestionsSets = [];

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < QUESTION_SET_LENGTH; i++) {
          const slicedQuestions = (list, count) => list.slice(i * count, i * count + count);

          // Picking questions from different types of sets and creating the list.
          const selectedQuestions = [
            ...slicedQuestions(multiChoiceSingle, 2),
            ...slicedQuestions(multiChoiceMulti, 1), // Multiple Choice: Multiple Answer - 1 Question
            ...slicedQuestions(trueOrFalse, 1), // True/False  - 1 Question
            ...slicedQuestions(fillBlanks, 1), // Fill in the Blank - 1 Question
          ];

          if (selectedQuestionsSets.length < MAX_QUESTION_LENGTH) {
            selectedQuestionsSets.push(...selectedQuestions);
          } else {
            break;
          }
        }

        // Only setting 15 questions to the list
        setQuestions(selectedQuestionsSets.slice(0, MAX_QUESTION_LENGTH));
        setIsLoadingQuestion(false);
      }).catch((error) => {
        setIsLoadingQuestion(false);
        setHasError(true);


        let errorHeader = '';
        let errorMessage = '';
        const errorCode = error?.payload?.code;

        if (['content_too_short', 'blank_text_extract'].includes(errorCode)) {
          errorMessage = t.LECTURE_PAGES.COMPONENTS.QUIZ.AI_QUESTIONS.SHORT_ERROR();
        } else {
          errorHeader = t.FORM.OOPS();
          errorMessage = t.FORM.ERROR_SOMETHING_WRONG_SHORT();
        }

        dispatch(
          addAlertMessage({
            type: AlertMessageType.ERROR,
            header: errorHeader,
            message: errorMessage,
          }),
        );
      });
    }
  }, [catalogId, dispatch, lectureComponentToReplace?.id, selectedAIContent]);

  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  const getMoreQuestions = () => {
    setShowingQuestionsCount(showingQuestionsCount + QUESTION_SET_LENGTH);
  };

  const questionsToShow = useCallback(() => (
    questions.slice(0, showingQuestionsCount)
  ), [questions, showingQuestionsCount]);

  return {
    isLoadingQuestion,
    totalQuestionsCount: questions.length,
    hasError,
    questionsToShow,
    getMoreQuestions,
  };
};

export default useQuizAiQuestions;
