import { ChartData, ChartOptions, TooltipModel, ChartTypeRegistry, TooltipItem } from 'chart.js';
import { useRef, useEffect, createContext } from 'react';
import t from 'react-translate';
import { SubmissionTab, RateOfSpeech } from 'redux/schemas/models/video-practice';
import { NvTab } from 'shared/components/nv-responsive-tabs';
import { primary, gray1, gray4, gray5 } from 'styles/global_defaults/colors';
import { map } from 'underscore';
import { config as configConstants } from '../../../../config/config.json';

export type Tab = NvTab & {
  tabValue: SubmissionTab
};

export const getChartData = (rateOfSpeech: RateOfSpeech[]): {
  data: ChartData, options: ChartOptions
} => {
  const labels = []; const wpm = [];
  map(rateOfSpeech, value => {
    /**
     * 0 -
     * 30 s
     * To display x axis ticks labels in multiple line
     */
    const label = [`${value.start} -`, `${value.end} s`];
    labels.push(label);
    wpm.push(value.wordsPerMinute);
  });
  const data: ChartData = {
    labels,
    datasets: [{
      label: 'WPM',
      data: wpm,
      fill: false,
      borderColor: primary,
      tension: 0,
      borderWidth: 1,
    }],
  };
  const options: ChartOptions = {
    scales: {
      y: {
        suggestedMin: 90,
        suggestedMax: 180,
        grid: { display: false, drawBorder: false },
        ticks: {
          // To add suffix or prefix with tick value
          callback(value, index, ticks) {
            return `${value} WPM`;
          },
          font: {
            size: 10,
            family: 'inherit',
          },
          autoSkip: true,
          color: gray1,
          align: 'inner',
        },
      },
      x: {
        grid: { display: false, borderColor: gray4, borderWidth: 1.5 },
        ticks: {
          color: gray1,
          font: {
            size: 10,
            family: 'inherit',
          },
          align: 'inner',
        },
      },
    },
    borderColor: gray4,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label(this: TooltipModel<keyof ChartTypeRegistry>,
            item: TooltipItem<keyof ChartTypeRegistry>) {
            const label = `${item.formattedValue} WPM`;
            return label;
          },
          title(tooltipItems: TooltipItem<keyof ChartTypeRegistry>[]) {
            const title = tooltipItems[0].label.replace(/[ ,]/g, '');
            return title;
          },
        },
      },
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            yMin: configConstants.recordings.wpm.fast,
            yMax: configConstants.recordings.wpm.fast,
            borderDash: [5, 5],
            borderColor: gray5,
          },
          line2: {
            type: 'line',
            yMin: configConstants.recordings.wpm.slow,
            yMax: configConstants.recordings.wpm.slow,
            borderDash: [5, 5],
            borderColor: gray5,
          },
          label1: {
            type: 'label',
            xValue() {
              if (wpm.length - 1 > 0) { return wpm.length - 1; }
              return 1;
            },
            yValue: configConstants.recordings.wpm.fast,
            content: t.PRACTICE_ROOM.INSIGHTS.WPM.FAST(),
            font: {
              size: 12,
              family: 'inherit',
            },
            color: gray4,
            position: 'end',
          },
          label2: {
            type: 'label',
            xValue() {
              if (wpm.length - 1 > 0) { return wpm.length - 1; }
              return 1;
            },
            yValue: configConstants.recordings.wpm.slow,
            content: t.PRACTICE_ROOM.INSIGHTS.WPM.SLOW(),
            font: {
              size: 12,
              family: 'inherit',
            },
            color: gray4,
            position: 'end',
          },
        },
      },
    },
    elements: {
      point: {
        radius: 5,
        backgroundColor: primary,
      },
    },
  };
  return { data, options };
};

export const bgGradient = 'linear-gradient(88.8deg, #1E9FD6 2.06%, #6597FF 97.94%)';

export const rtlBgGradient = 'linear-gradient(-88.8deg, #1E9FD6 2.06%, #6597FF 97.94%)';

/* @ngInject */
export function selectedViewToIndex(selectedView: SubmissionTab) {
  switch (selectedView) {
    case SubmissionTab.COMMENTS:
      return 0;
    case SubmissionTab.AUTHOR_FEEDBACK:
      return 1;
    case SubmissionTab.ALL_FEEDBACK:
      return 2;
    case SubmissionTab.INSIGHTS:
      return 3;
    default:
      return 0;
  }
}

export const INITIAL_COMMENTS_PER_PAGE = 5;

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const PracticeSubmissionContext = createContext(null);
