import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';

import { getSubmission } from 'redux/selectors/video-practice';
import { PracticeSubmissionContext } from '../../utils';
import KeysToAvoid from './keys-to-avoid';
import KeysToCover from './keys-to-cover';
import Stats from './stats';

const KeyPhrases = () => {
  const { submissionId } = useContext(PracticeSubmissionContext);
  const {
    rateOfSpeech,
    wordsPerMinute,
    keyphrasesToCover,
    keyphrasesToAvoid,
    coveredKeyphrases,
    notAvoidedKeyphrases,
  } = useSelector((state) => getSubmission(state, submissionId));

  return (
    <div>
      <div className='gray-1 text-small'>
        {t.PRACTICE_ROOM.INSIGHTS.DESCRIPTION()}
      </div>
      <Stats rateOfSpeech={rateOfSpeech} wordsPerMinute={wordsPerMinute} />
      {keyphrasesToCover?.length > 0 && (
        <KeysToCover
          keyphrasesToAvoid={keyphrasesToAvoid}
          keyphrasesToCover={keyphrasesToCover}
          coveredKeyphrases={coveredKeyphrases}
        />
      )}
      {keyphrasesToAvoid?.length > 0 && (
        <KeysToAvoid notAvoidedKeyphrases={notAvoidedKeyphrases} />
      )}
    </div>
  );
};

export default KeyPhrases;
