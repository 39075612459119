import { useMediaQuery } from 'react-responsive';
import { css } from '@emotion/react';
import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import { gray2, gray4, primary } from 'styles/global_defaults/colors';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';
import { quarterSpacing, standardSpacing, largeSpacing } from 'styles/global_defaults/scaffolding';
import { handheld, screenXsMax } from 'styles/global_defaults/media-queries';

type PracticeActivityProgressProps = {
  modalStepIndex: number;
};

const PracticeActivityProgress = ({
  modalStepIndex,
}: PracticeActivityProgressProps) => {
  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const styles = css`
    margin: 0 auto;
    display: flex;
    width: 800px;
    padding-left: ${largeSpacing}px;
    padding-right: ${largeSpacing}px;
    color: ${gray2};
    padding-bottom: ${standardSpacing}px;

    ${handheld(css`
      width: 100%;
    `)}

    .divider {
      flex: 1;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        border-top: 1px solid ${gray2};
        width: 100%;
        transform: translateY(-50%);
      }

      &.active:before {
        border-top: 1px solid ${primary};
      }
    }

    .step {
      position: relative;

      .step-title {
        position: absolute;
        bottom: -20px;
        left:50%;
        transform: translateX(-50%);
        white-space: nowrap;
        text-transform: uppercase;
        color: ${gray4};
        font-weight: ${semiBoldFontWeight};
      }

      .icon-wrapper {
        border: 2px solid ${gray2};
        border-radius: 50%;
        width: ${isHandheld ? 32 : 48}px;
        height:${isHandheld ? 32 : 48}px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: ${quarterSpacing}px;

        .icon {
          color: ${gray2};
        }
      }

      &.active {
        color: white;

        .step-title {
          color: ${primary};
        }

        .icon-wrapper {
          border: none;
          background-color: ${primary};

          .icon {
            color: white;
          }
        }
      }
    }
  `;

  return (
    <div className='practice-activity-progress' css={styles}>
      <div className={`step ${modalStepIndex >= 1 ? 'active' : ''}`}>
        <div className='icon-wrapper'>
          <NvIcon icon='step-view-prompt' size={isHandheld ? 'smallest' : 'medium'} />
        </div>
        {!isHandheld && (
          <div className='text-small step-title'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.VIEW.NAME()}</div>
        )}
      </div>
      <div className={`divider ${modalStepIndex >= 2 ? 'active' : ''}`} />
      <div className={`step ${modalStepIndex >= 2 ? 'active' : ''}`}>
        <div className='icon-wrapper'>
          <NvIcon icon='practice' size={isHandheld ? 'smallest' : 'medium'} />
        </div>
        {!isHandheld && (
          <div className='text-small step-title'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORD.NAME()}</div>
        )}
      </div>
      <div className={`divider ${modalStepIndex >= 4 ? 'active' : ''}`} />
      <div className={`step ${modalStepIndex >= 4 ? 'active' : ''}`}>
        <div className='icon-wrapper'>
          <NvIcon icon='step-review' size={isHandheld ? 'smallest' : 'medium'} />
        </div>
        {!isHandheld && (
          <div className='text-small step-title'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.REVIEW.NAME()}</div>
        )}
      </div>
    </div>
  );
};

export default PracticeActivityProgress;
