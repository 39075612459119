import { css } from '@emotion/react';
import React, { useState, useContext, Fragment } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import NvPopover from 'shared/components/nv-popover';
import { RootState } from 'redux/schemas';
import { CourseAliases } from 'redux/schemas/models/course';
import { getCourseAliases } from 'redux/selectors/course';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import moment from 'moment';
import { AngularServicesContext } from 'react-app';
import { gray7 } from 'styles/global_defaults/colors';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { RecordingFormat, VideoPracticeScenario } from 'redux/schemas/models/video-practice';

type VideoPracticeScenarioCardProps = {
  scenario: VideoPracticeScenario;
  showSubmissionPrivacySetting?: boolean;
  detailedView?: boolean;
};

const VideoPracticeScenarioCard = ({
  scenario,
  showSubmissionPrivacySetting,
  detailedView,
}: VideoPracticeScenarioCardProps) => {
  const styles = css`
    ${detailedView && css`
      background-color: ${gray7};
      padding: ${standardSpacing};
    `}

    .cursor-pointer {
      cursor: pointer;
    }
  `;

  const { $state } = useContext(AngularServicesContext);
  const aliases: CourseAliases = useSelector((state: RootState) => getCourseAliases(state));

  const [showCourses, setShowCourses] = useState(false);

  return (
    <div className='my-4' css={styles}>
      <div className='my-2 d-flex'>
        <i className='icon icon-smallest icon-new-course warning d-inline mr-2' />
        <div className='d-inline'>
          {scenario.createdInCourse?.catalogId
            ? t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.CREATED_ON_DATE_IN(moment(scenario.createdAt).format('MM/DD/YYYY'))
            : t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.CREATED_ON_DATE(moment(scenario.createdAt).format('MM/DD/YYYY'))}
          {scenario.createdInCourse?.catalogId && (
            <Fragment>
              <a
                href={$state.href('course-home', { catalogId: scenario.createdInCourse.catalogId })}
                target='_blank'
                rel='noreferrer'
                className='font-weight-bold text-primary ml-1'
              >
                {scenario.createdInCourse.name}
              </a>
              <span className='text-small text-gray-3 ml-1'>
                {scenario.createdInCourse.catalogId}
              </span>
            </Fragment>
          )}
        </div>
      </div>
      <div className='my-2 d-flex'>
        <i className='icon icon-smallest icon-course warning d-inline mr-2' />
        <div className='d-inline'>
          {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.USED_IN()}
          <span onClick={() => detailedView && setShowCourses(show => !show)} className={`${detailedView ? 'cursor-pointer text-primary' : ''} font-weight-bold ml-1`}>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.NUM_COURSES({ ...aliases.courseAliases, num: scenario.numCoursesUsedIn })}</span>
          {showCourses && (
            <Fragment>
              <ul className='mb-0'>
                {scenario.coursesUsedIn.map((course) => (
                  <li key={course.catalogId} className='ml-2 text-small'>
                    <a href={$state.href('course-home', { catalogId: course.catalogId })} target='_blank' rel='noreferrer' className='text-primary'>
                      {course.name}
                    </a>
                    <span className='ml-1'>({course.catalogId})</span>
                  </li>
                ))}
              </ul>
              {scenario.numCoursesUsedIn - scenario.coursesUsedIn.length > 0 && <div className='ml-5 pl-1 text-small'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.VIEW_PRACTICE_SCENARIO.PLUS_X_MORE_COURSES({ ...aliases.courseAliases, num: scenario.numCoursesUsedIn - scenario.coursesUsedIn.length })}</div>}
            </Fragment>
          )}
        </div>
      </div>
      {showSubmissionPrivacySetting && (
        <div className='my-2 d-flex'>
          <i className='icon icon-smallest icon-share warning d-inline mr-2' />
          <div>
            <div className='d-inline'>
              {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SUBMISSION_GALLERY_SETTING[scenario.privacy.toUpperCase()]({ ...aliases.teachingTeamAliases, ...aliases.learnersAliases })}
            </div>
            <NvPopover
              showOnHover
              placement='top'
              className='d-inline ml-1 align-bottom'
              content={scenario.privacy === 'shared'
                ? <div>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SUBMISSION_GALLERY_SETTING.SHARED_TOOLTIP({ ...aliases.learnersAliases, ...aliases.courseAliases })}</div>
                : <div>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SUBMISSION_GALLERY_SETTING.RESTRICTED_TOOLTIP({ ...aliases.learnersAliases, ...aliases.courseAliases })}</div>}
            >
              <i className='icon icon-xss-smallest icon-info text-primary d-inline' aria-hidden='true' />
            </NvPopover>
          </div>
        </div>
      )}
      <div className='my-2 d-flex'>
        <i className='icon icon-smallest icon-share warning d-inline mr-2' />
        <div>
          <div className='d-inline'>{scenario.recordingFormat === RecordingFormat.AUDIO
            ? t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.ALLOWED_SUBMISSION_TYPE_SETTING.SUBMISSION_TYPE_AUDIO()
            : t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.ALLOWED_SUBMISSION_TYPE_SETTING.SUBMISSION_TYPE_VIDEO()}
          </div>
          <NvPopover
            showOnHover
            placement='top'
            className='d-inline ml-1 align-bottom'
            content={<div>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.ALLOWED_SUBMISSION_TYPE_SETTING.HELP_TEXT()}</div>}
          >
            <i className='icon icon-xss-smallest icon-info text-primary d-inline' aria-hidden='true' />
          </NvPopover>
        </div>
      </div>
      <div className='my-2 d-flex'>
        <i className={`icon icon-smallest icon-${scenario.reusable === 'reusable' ? 'sharable' : 'duplicate'} warning d-inline mr-2`} />
        <div>
          <div className='d-inline'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCENARIO_REUSABILITY_SETTING[scenario.reusable.toUpperCase()]({ ...aliases.courseAliases })}</div>
          <NvPopover
            showOnHover
            placement='top'
            className='d-inline ml-1 align-bottom'
            content={scenario.reusable === 'reusable'
              ? <div>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCENARIO_REUSABILITY_SETTING.REUSABLE_TOOLTIP({ ...aliases.learnersAliases, ...aliases.courseAliases })}</div>
              : <div>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCENARIO_REUSABILITY_SETTING.NOT_REUSABLE_TOOLTIP()}</div>}
          >
            <i className='icon icon-xss-smallest icon-info text-primary d-inline' aria-hidden='true' />
          </NvPopover>
        </div>
      </div>
    </div>
  );
};

export default VideoPracticeScenarioCard;
