import React, { useContext, useEffect, useState } from 'react';
import t from 'react-translate';
import { css } from '@emotion/react';
import PusherService from 'shared/services/pusher-service';
import { AngularServicesContext } from 'react-app';
import { tripleSpacing } from 'styles/global_defaults/scaffolding';
import humps from 'humps';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { syncLecturePage } from 'redux/actions/collections';
import { openConfirmationDialog, closeConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { getLecturePage } from 'redux/selectors/timeline';
import { getLinkedCourses } from 'redux/selectors/collections';
import { getCourse } from 'redux/selectors/course';
import { RootState } from 'redux/schemas';
import { SyncingStatus } from 'redux/schemas/models/lecture-page';
import { setLecturePageSyncAsCompleted } from 'redux/actions/lecture-pages';

// Components
import { useExitLecturePageMode, useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { LecturePageMode, LecturePageSyncEvent } from 'lecture_pages/components';
import { Button } from 'react-bootstrap';
import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';
import { getAutoGenerationInProgress } from 'redux/selectors/lecture-components';
import NvTooltip from 'shared/components/nv-tooltip';
import { config } from '../../../../config/config.json';
import LinkedCourseModal from '../collection-home/linked-course-modal';

const TopHeader = () => {
  const styles = css`
    // Overriding the lecture page light header
    & {
      position: absolute;
      width: 100%;
      top: -60px;
      height: ${tripleSpacing}px;
    }
  `;

  const dispatch = useAppDispatch();
  const { $state, CurrentPermissionsManager } = useContext(AngularServicesContext);
  const { mode, catalogId, lecturePageId } = useLecturePageParams();
  const collection = useSelector((state: RootState) => getCourse(state, catalogId));
  const lecturePage = useSelector((state: RootState) => getLecturePage(state, lecturePageId));
  const exitMode = useExitLecturePageMode();
  const linkedCourses = useSelector((state: RootState) => getLinkedCourses(state, lecturePageId));
  const isPushChangesEnabled = !lecturePage?.hasUpdatedLinks;
  const isCollectionBuilder = CurrentPermissionsManager.hasCollectionBuilderPermissions();
  const isCollectionViewer = CurrentPermissionsManager.hasCollectionViewerPermissions();
  const [showLinkedCoursesModel, setShowLinkedCoursesModel] = useState(false);
  const isAutoGenerationInProgress = useSelector((state: RootState) => getAutoGenerationInProgress(state, lecturePageId));

  useEffect(() => {
    const pusherChannel = PusherService.initializeCourseChannel(collection?.id);
    pusherChannel.bind('lecture_page_sync_completed', (data) => {
      const pusherData = humps.camelizeKeys(data) as unknown as LecturePageSyncEvent;
      dispatch(setLecturePageSyncAsCompleted(pusherData.lecturePageId));
    });

    return () => {
      pusherChannel.unbind('lecture_page_sync_completed');
    };
  }, [collection?.id, dispatch]);

  const goToCollectionHome = () => {
    $state.go(
      'content-library-collection-home',
      { collectionId: collection.id, catalogId, institutionId: collection.institutionId },
    );
  };

  const doSyncLecturePage = () => {
    dispatch(syncLecturePage({ lecturePageId })).then(() => {
      dispatch(addAlertMessage({
        type: AlertMessageType.INFO,
        header: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.PUSH_CHANGES.PUSHING(),
        message: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.PUSH_CHANGES.SUCCESS(),
      }));
    });
  };
  const openLinkedLessonModal = () => {
    setShowLinkedCoursesModel(true);
    dispatch(closeConfirmationDialog());
  };
  const closeLinkedLessonModal = () => {
    setShowLinkedCoursesModel(false);
    onPushChanges();
  };

  const onPushChanges = () => {
    const bodyText: JSX.Element = (
      <div className='text-large-body'>
        <div>
          {t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.PUSH_CHANGES.CONFIRMATION.DESCRIPTION()}
        </div>
        <div className='d-flex justify-content-center'>
          <ClickableContainer
            onClick={openLinkedLessonModal}
          >
            <u>{t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.PUSH_CHANGES.CONFIRMATION.CHECK_LINKED_LESSONS()}</u>
          </ClickableContainer>
        </div>
      </div>
    );

    dispatch(openConfirmationDialog({
      title: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.PUSH_CHANGES.CONFIRMATION.TITLE(),
      bodyText,
      cancelText: t.FORM.CANCEL(),
      confirmText: t.FORM.YES_SURE(),
      onConfirm: () => doSyncLecturePage(),
    }));
  };

  return (
    <div
      className={`d-flex px-4 border-bottom align-items-center ${mode !== LecturePageMode.VIEW ? 'bg-gray-2' : 'justify-content-between'}`}
      css={styles}
    >
      <div>
        {isCollectionViewer && (
          <div
            className={`d-flex align-items-center py-3 cursor-pointer ${mode !== LecturePageMode.VIEW && 'text-white'}`}
            onClick={goToCollectionHome}
            data-qa={config.pendo.contentManagement.backToCollectionHome}
          >
            <NvIcon icon='back' size='small' className='pr-2' />
            <div className='course-title-small bold'>
              {t.INSTITUTIONS.CONTENT_LIBRARY.LECTURE_PAGE.BACK_TO_COLLECTION()}
            </div>
          </div>
        )}
      </div>

      {mode === LecturePageMode.EDIT && (
        <Button
          variant='primary'
          className='d-flex align-items-center mx-auto my-2'
          onClick={() => exitMode()}
          data-qa={config.pendo.contentManagement.exitEditMode}
        >
          {t.INSTITUTIONS.CONTENT_LIBRARY.LECTURE_PAGE.DONE_EDITING()}
        </Button>
      )}

      {mode === LecturePageMode.VIEW && (
        <React.Fragment>
          <div className='course-title-small bold'>{collection?.title}</div>
          <div>
            {isCollectionBuilder && (
              <React.Fragment>
                {linkedCourses.length > 0 && isPushChangesEnabled && (
                  <NvTooltip
                    text={t.LECTURE_VIDEO.AUTO_GENERATED_TRANSCRIPT_AND_CAPTIONS.IS_IN_PROGRESS()}
                    enabled={isAutoGenerationInProgress}
                    placement='bottom'
                  >
                    <Button
                      variant='primary'
                      className='d-flex align-items-center my-2'
                      disabled={isAutoGenerationInProgress || lecturePage.syncingStatus === SyncingStatus.SYNC_IN_PROGRESS}
                      onClick={() => onPushChanges()}
                      data-qa={!(lecturePage.syncingStatus === SyncingStatus.SYNC_IN_PROGRESS) ? config.pendo.contentManagement.collectionLessonPushChanges : ''}
                    >
                      { lecturePage.syncingStatus === SyncingStatus.SYNC_IN_PROGRESS
                        ? t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.PUSH_CHANGES.PUSHING()
                        : t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.STATUSES.PUSH_CHANGES()}
                    </Button>
                  </NvTooltip>
                )}
                {linkedCourses.length > 0 && !isPushChangesEnabled && (
                  <div className='text-small text-gray-1'>
                    {t.INSTITUTIONS.CONTENT_LIBRARY.LECTURE_PAGE.LINKS_UP_TO_DATE()}
                  </div>
                )}
                {lecturePage && linkedCourses?.length === 0 && (
                  <div className='text-small text-gray-1'>
                    {t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.STATUSES.NO_LINKS()}
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      )}
      <LinkedCourseModal
        lessonId={lecturePage?.id}
        showLinkedCourseModel={showLinkedCoursesModel}
        closeLinkedCourseModel={closeLinkedLessonModal}
      />
    </div>
  );
};

export default TopHeader;
