import { LecturePageMode } from 'lecture_pages/components';
import { ComponentTrueType, ComponentType, LectureComponent, LectureComponentCommon, LectureComponentsNormalized, NLectureComponent, TemporaryLectureComponent } from './lecture-component';
import { Normalized } from '../normalized';
import { LeftPanelView } from '../app/lecture-page';

import { TimelineLectureVideosNormalized } from './lecture-video';
import { CourseExercise, TimelineExercisesNormalized } from './exercise';
import { TimelineQuizzesNormalized } from './quiz';
import { TimelineTimedQuizzesNormalized } from './timed-quiz';
import { TimelinePeerEvaluationsNormalized } from './peer-evaluation';
import { TimelineLiveSessionsNormalized } from './live-session';
import { TimelineExternalToolsNormalized } from './external-tool';
import { TimelineTeamSetsNormalized } from './team-set';
import { TimelineGroupTeamSetsNormalized } from './group-team-set';
import { TimelineSurveysNormalized } from './survey';
import { TimelinePostsNormalized } from './timeline-post';
import { TeamDiscussionsNormalized } from './team-discussion';
import { Communication, CommunicationsNormalized, OwnerType } from './course-communication';
import { VideosNormalized } from './video';
import { Course } from './course';
import { BaseUser } from './my-account';

export enum CompletionStatus {
  NOT_STARTED,
  COMPLETED,
  IN_PROGRESS,
}

export interface ActivityCommunicationsCount {
  type: OwnerType;
  id: number;
  count: number;
}

export interface MinimalLecturePage {
  id: number;
  title: string;
  type: LecturePageType.LECTURE;
  releaseDate?: Date | string;
}

// Prev/Next page in lecture page
interface LecturePageNav {
  id: number,
  title: string,
}

interface MinimalCollection {
  catalogId: string,
  id: number,
  title: string,
}

export interface LecturePage extends MinimalLecturePage {
  id: number;
  title: string;
  index: number;
  lectureSectionId: number;
  releaseDate: Date | string;
  released: boolean;
  viewed: boolean;
  completed: boolean;
  started: boolean;
  coverImage?: LecturePageCoverImage,
  cardViewImageUrl: string;
  coverImageUrl: string;
  viewOptions: LecturePageViewOptions;
  lectureComponents: LectureComponent[];
  titleImage: null;
  course: Course;
  prerequisite?: Prerequisite;
  studentPrerequisites: Array<LecturePageStudentPrerequisites>;
  usersRecentlyViewed: null;
  numUsersRecentlyViewed: null;
  estimation: Estimation;
  totalPoints: Array<number | null>;
  pointsReceived: number;
  showTitle: boolean;
  titleImageHeight: null;
  titleImageWidth: null;
  hasStructuralIssues: boolean;
  hasTimelineIssues: boolean;
  hasAnyActivities: boolean;
  hasContent: boolean;
  hasDeadlines: boolean;
  downloadInfo: null;
  communicationsCount?: number,
  communications?: Communication[],
  activityCommunicationsCount?: ActivityCommunicationsCount[],
  activitiesCommunicationsErrorsCount?: number,
  catalogId: string;
  bookmarkId?: number;
  achievableTotalPoints?: number; // OO-TODO - Remove this
  translatedTitle?: string;

  // Content management linking related fields
  isLinked: boolean;
  contentManagementCollection: MinimalCollection;
  cloneParentId: number;

  previousPage: LecturePageNav,
  nextPage: LecturePageNav,
}

interface ContentManagementLinkCourse {
  id: number,
  name: string,
  catalogId: string,
}

export interface ContentManagementLink {
  course: ContentManagementLinkCourse,
  hasUpdatedLink: boolean,
  targetId: number
}

export enum SyncingStatus {
  SYNC_IN_PROGRESS = 'sync_in_progress',
  LINK_IN_PROGRESS = 'link_in_progress',
  COMPLETED = 'completed',
}
export interface CollectionLecturePage extends LecturePage {
  contentManagementEnabled: boolean
  contentManagementLinks: ContentManagementLink[],
  hasUpdatedLinks: boolean,
  description: string,
  folderId: number
  syncingStatus: SyncingStatus
  lastEditor: BaseUser
  updatedAt: string;
  coverImageFileName: string;
  coverImageFileSize: number;
  coverImageFileType: string;
  coverImageFileUniqueId: string;
}

export type CombinedLecturePage = LecturePage & CollectionLecturePage;
export type NLecturePage = Normalized<CombinedLecturePage, 'lectureComponents' | 'communications'>;

export enum LecturePageKey {
  LECTURE = 'lecturePages',
}

export enum LecturePageType {
  LECTURE = 'LecturePage',
}

export interface Prerequisite {
  expired: boolean;
  id: number;
  lectureComponentId: number;
  lecturePageId: number;
  released: boolean;
  title: string;
  type: string;
}

export interface Estimation {
  estimatedEffort: number;
  estimatedEffortType: 'Read' | 'Watch' | 'Listen' | 'Work'
  estimatedEffortMeasure: 'Min' | 'Hour' | 'Day';
}

export interface LecturePageViewOptions {
  /** The white/black buttons set this to #fff or #000, but titles are created as NovoEd Black */
  titleColor: string;
  backgroundColor?: string;
  tabColor: string;
  // TODO: This is not used anywhere. Ask to remove from backend
  cardViewBackgroundColor: string;
  cardViewIconColorBlack: boolean;
}

// This is very similar to LectureComponent's ComponentPicture, but with slightly different and fewer properties
export interface LecturePageCoverImage {
  fileName: string,
  fileSize: number,
  fileType: string,
  uniqueId: string,
}

export interface LecturePageStudentPrerequisites {
  id: number,
  title: string,
  type: string,
  lecturePageId: number,
  lectureComponentId: number,
  expired: boolean,
  released: boolean,
}

export interface LecturePagesNormalized { [id: string]: NLecturePage }

// TODO: Quite frankly I'm finding this a bit bizarre. Let's figure out what this is
// trying to accomplish and figure out how it does and should/shouldn't relate
// to the new NLecturePage type
export interface LecturePageEntities {
  lecturePages: LecturePagesNormalized,
  lectureComponents: LectureComponentsNormalized,
  communications: CommunicationsNormalized,
  exercises: TimelineExercisesNormalized,
  quizzes: TimelineQuizzesNormalized,
  timedQuizzes: TimelineTimedQuizzesNormalized,
  surveys: TimelineSurveysNormalized,
  teamSets: TimelineTeamSetsNormalized,
  groupTeamSets: TimelineGroupTeamSetsNormalized,
  posts: TimelinePostsNormalized,
  teamDiscussions: TeamDiscussionsNormalized,
  peerEvaluations: TimelinePeerEvaluationsNormalized,
  externalTools: TimelineExternalToolsNormalized,
  liveSessions: TimelineLiveSessionsNormalized,
  lectureVideos: TimelineLectureVideosNormalized,
  video: VideosNormalized,
}

export enum PreventNavigationReason {
  SAVING_IN_PROGRESS = 'saving_in_progress',
  REORDER_IS_DIRTY = 'reorder_is_dirty',
}

export enum NovoAIItemType {
  NO_AI = 'no_ai',
  SUMMARY = 'summary',
  KEY_TAKEAWAYS = 'key_takeaways',
  DISCUSSION = 'discussion',
  QUIZ = 'quiz',
}

export enum NovoAIContentType {
  LESSON = 'LecturePage',
  SUBSECTION = 'LectureSubsection',
  SECTION = 'LectureSection',
}

export interface NovoAI {
  itemType: NovoAIItemType,
  content: NovoAIContent,
  itemTrueType?: ComponentTrueType,
  lengthText?: string,
  tone?: string,
  lectureComponentToReplace?: LectureComponentCommon<any>,
}

export interface NovoAIContent {
  id: number,
  type: NovoAIContentType,
}

export interface LecturePageState {
  currentLectureId: number;

  /** The current page mode. Note that this is *not* the same as the 'mode' queryParam; the queryParam
   * intentionally reads as 'edit' even when the user is in RestrictedEdit mode */
  mode: LecturePageMode,
  /** If true, requests the lecture page with the `edit_mode` boolean query param. Also checked
   * in other places to determine if "admin" specific data is loaded.
   * Despite the name of this param, this is set true when the page is viewed in either
   * Edit or Reorder mode */
  isAdminMode: boolean,

  /**
   * A copy of the order of lecture components. Need it to reset the data
   * if cancel is pressed after reorder.
   */
  lectureComponentsOrderCopy?: number[],

  /** The ID of any component specified as the activity to navigate to when entering the lecture page
   * Currently only used for scrollTo() behavior. This should be cleared out whenever the page URL no longer specifies
   * the activity */
  targetActivityId: number,

  /** The curren't users preference on whether the left panel displays as open or closed by default.
   * This gets updated every time the user manually toggles the Outline open/closed */
  leftPanelVisibilityPreference: boolean,

  // This variable is to check whether the lecture page has reached to the end of the page or not.
  isLecturePageBottomReached: boolean,
  isLeftPanelVisible: boolean,
  isBottomBarVisible: boolean,
  isTopBarVisible: boolean,
  isCurrentLectureLoaded: boolean,
  isTimelineContentLoaded: boolean,
  leftPanelView: LeftPanelView,
  // The positional index at which a new component will be added when a component is selected in the LHS
  newComponentIndex: number,

  // Whether we're currenty saving data in the lecture page header area. Triggers a loading indicator
  isHeaderSaving: boolean,

  isModeSwitching: boolean,

  /** When set, prevents navigating out of the lecture page.
   * Toggled on during many lecture page data save flows to prevent data loss on page load,
   * and toggled back off when complete. Also toggled on Reordering
   * */
  preventPageNavigation: PreventNavigationReason,

  /** A lookup of lecture page IDs indicating which pages have been previously loaded via getLecturePage().
   * If a user attempts viewing a lecture page in non-admin mode and that page has a key in this set, we skip
   * re-fetching the page data via getLecturePage()
   *
   * This is a mapping of lecture page Ids to booleans because Sets are not serializable. A page should be treated as cached so long as it has a an entry in
   * this lookup *and* that value is set `true`
   * */
  cachedLecturePageLookup: Record<number, boolean>,

  // Replacement for persistedComponents. Intended to be a local-only version of components that are created
  // to show temporary experience in the time after a component has been added
  // but before the backend save is complete
  temporaryComponents: { [id: string]: TemporaryLectureComponent },

  /** Data on all the exercises existing on the current course. This is distinct from state.models.exercises because it's retrieved
   * from a different endpoint and only in admin mode - is used to determine which exercise-dependant component types should be disabled
   * from being added */
  exercises: CourseExercise[],

  /**
   * Lookups of lecture page ids indicating which pages have been viewed &
   * exited in the current session, this to track which lecture pages need to
   * wait to be exited to be marked as completed.
   */
  exitedLecturePages: { [id: string]: boolean },
  locallyViewedLecturePages: { [id: string]: boolean },

  isTranslating: boolean,
  translationFailed: boolean,
  novoAI: NovoAI,
}

export interface UpdateLectureReleaseDateResponse {
  dependentUpdates: Partial<LecturePage>[],
  updatedObject: Partial<LecturePage>,
}

