import { css } from '@emotion/react';
import { keys } from 'underscore';
import t from 'react-translate';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';

// Hooks
import { makeQueryString } from 'shared/hooks/use-query';

// Schemas
import { RootState } from 'redux/schemas';
import { PracticeRoomTab, StatType, VideoPracticeSubmission } from 'redux/schemas/models/video-practice';

// Actions
import { getSubmissionsForUsers, getComments, getComment } from 'redux/actions/video-practice';

// Selectors
import { getSubmissionPreviews, getUserSubmissions, getUserIdsForOverview, getSkillTags } from 'redux/selectors/video-practice';

// Styles
import { hexToRgbaString, teal, warning } from 'styles/global_defaults/colors';
import {
  doubleSpacing, halfSpacing, standardSpacing, threeQuartersSpacing, largeSpacing, tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import { handheld, isHandheld } from 'styles/global_defaults/media-queries';

// Components
import NvIcon from 'shared/components/nv-icon';
import { NvUserAvatar } from 'components/nv-user-avatar';
import LoadingWrapper, { LoaderType } from 'shared/components/loading-wrapper';
import PracticeSubmission from 'practice_room/components/shared/practice-submission';
import NvTooltip from 'shared/components/nv-tooltip';
import ClickableContainer from 'components/clickable-container';
import { User } from 'redux/schemas/models/my-account';
import { StatCountType, VideoPracticeSubmissionsState } from 'redux/schemas/app/video-practice';
import { statMeta } from '../my-practice/my-practice-tab';
import UserSubmissionSlider, { SliderAction } from '../shared/user-submission-slider';

/**
 * Base component to display a Practice Submission Overview.
 */

const styles = css`
  .container {
    max-width: 800px;
  }

  .back-to-icon {
    cursor: pointer;
  }
`;

const userStatusStyles = css`
  .highlight-icon-wrapper {
    width: ${isHandheld() ? threeQuartersSpacing : standardSpacing}px;
    height: ${isHandheld() ? threeQuartersSpacing : standardSpacing}px;
    background-color: ${warning};
  }

  .team-member {
    cursor: pointer;
    background-color: ${hexToRgbaString(teal, 0.2)};
    border-radius: 50%;
    width: 18px;
    height: 18px;
    .icon-community {
      color: ${teal};
    }
  }

  .extra-small {
    /**
     * The featured icon size is smaller than the 'xss-smallest'(12px).
     * So adding an 'extra-small' class and overriding the font-size.
     */
    font-size: ${halfSpacing}px !important;
  }
/* We don't have an avatar with size of 80px so overriding the avatar of xl size */
  .user-initials, .avatar {
    width: ${2 * doubleSpacing}px !important;
    height: ${2 * doubleSpacing}px !important;

    /** When there is no profile picture, default avatar will be
    * user initials, and its fontSize is always half of the avatar size
    */
    font-size: ${doubleSpacing}px !important;
    ${handheld(css`
      width: ${tripleSpacing}px !important;
      height: ${tripleSpacing}px !important;
      font-size: ${largeSpacing}px !important;
    `)};
  }
`;

type SubmittedUserProps = {
  user: User,
  isTeamMember?: boolean,
  teamName?: string,
  counts?: StatCountType,
};

export const SubmittedUser = ({
  user,
  isTeamMember,
  teamName,
  counts,
}: SubmittedUserProps) => {
  const getIconClass = (stat) => {
    let iconClass = '';
    if (stat === StatType.FEATURED) {
      iconClass = `text-white ${isHandheld() ? 'extra-small' : ''}`;
    } else {
      iconClass = statMeta[stat].class;
    }
    return iconClass;
  };

  return (
    <div className='d-flex' css={userStatusStyles}>
      <NvUserAvatar
        borderType='round'
        size='xl'
        user={user}
        directToOrgLevelProfile
      />
      <div className={`d-flex justify-content-between ml-2 ml-md-4 py-md-1 ${counts ? 'flex-column' : ''}`}>
        <div className='d-flex align-items-center'>
          {user?.fullName && (
            <span className='course-title mr-2'>{user.fullName}</span>
          )}
          {isTeamMember && teamName && (
            <NvTooltip text={t.PRACTICE_ROOM.TOOLTIP.TEAM_MEMBER(teamName)}>
              <div className='d-flex team-member justify-content-center align-items-center'>
                <NvIcon icon='community' size='xss-smallest' />
              </div>
            </NvTooltip>
          )}
        </div>
        {counts && (
          <div className='d-flex'>
            {keys(counts).map((stat: StatType) => (
              counts[stat] ? (
                <div
                  key={stat}
                  className='d-flex align-items-center mr-3 mr-md-5'
                >
                  <div className={`${statMeta[stat].icon}-icon-wrapper d-flex justify-content-center align-items-center`}>
                    <NvIcon
                      icon={statMeta[stat].icon}
                      size={(stat === StatType.FEATURED || isHandheld()) ? 'xss-smallest' : 'small'}
                      className={getIconClass(stat)}
                    />
                  </div>
                  <div
                    className={`d-flex course-title-xs ${statMeta[stat].class} ml-1 ml-md-2`}
                  >
                    { isHandheld() ? counts[stat] : t.PRACTICE_ROOM.STATS[stat](counts[stat])}
                  </div>
                </div>
              ) : null
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const UserSubmissions = () => {
  const dispatch = useAppDispatch();
  const {
    selectedTab: backToTab,
    userId,
    scenarioId,
    newNotification,
  } = useSelector((state) => state.app.practiceRoom.params);
  const videoPracticeSubmissions: VideoPracticeSubmissionsState[] = useSelector((state) => state.app.videoPracticeSubmissions);


  const submissions = useSelector((state) => getUserSubmissions(state, userId));
  const skillTags = useSelector(state => getSkillTags(state, scenarioId));
  useEffect(() => {
    if (submissions?.submissionIds?.length > 0) {
      submissions.submissionIds.forEach((submissionId) => {
        const appState = videoPracticeSubmissions?.[submissionId];
        if (!appState?.commentsLoading && !appState?.commentsOnceLoaded) {
          dispatch(getComments({ submissionId, page: 1 }));
        }
      });
    }
  }, [dispatch, newNotification?.commentId, newNotification?.submissionId, submissions?.submissionIds, videoPracticeSubmissions]);
  useEffect(() => {
    if (newNotification?.commentId) {
      dispatch(getComment({ submissionId: newNotification.submissionId, commentId: newNotification.commentId }));
    }
  }, [dispatch, newNotification?.submissionId, newNotification?.commentId, newNotification]);

  const history = useHistory();
  const backToTabKey = backToTab === PracticeRoomTab.GALLERY ? 'GALLERY' : 'FEATURED';

  return (
    <div css={styles}>
      <div className='container mt-4 mb-6 w-100'>
        <LoadingWrapper isLoaded={submissions?.isOnceLoaded} loaderType={LoaderType.PLACEHOLDER}>
          <div className='d-flex flex-column'>
            {backToTab && (
              <ClickableContainer
                className='back-to-icon d-flex align-items-center text-primary my-4 ml-1'
                onClick={() => history.push(makeQueryString({
                  selected: backToTab,
                }))}
              >
                <NvIcon icon='back' size='small' />
                <div className='label text-primary ml-2'>
                  {t.PRACTICE_ROOM.BACK_TO_COMPONENT[backToTabKey]()}
                </div>
              </ClickableContainer>
            )}
            {submissions?.user && (
              <SubmittedUser
                user={submissions?.user}
                isTeamMember={submissions?.isTeamMember}
                counts={submissions?.counts}
                teamName={submissions.teamName}
              />
            )}
            {submissions?.submissionIds?.map((submissionId, index) => (
              <div className='border-top border-gray-4 pt-5 mt-5' key={submissionId}>
                <PracticeSubmission
                  submissionId={submissionId}
                  index={submissions.submissionIds.length - index}
                  skillTags={skillTags}
                  submissionIds={submissions.submissionIds}
                />
              </div>
            ))}
          </div>
        </LoadingWrapper>
      </div>
    </div>
  );
};
export type SubmissionPreview = {
  [key in SliderAction]?: VideoPracticeSubmission
};
const SubmissionOverview = () => {
  const {
    scenarioId,
    userId,
    submissionId,
    selectedTab,
  } = useSelector((state) => state.app.practiceRoom.params);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const userIds = useSelector<RootState, number[]>((state) => getUserIdsForOverview(state));
  const { next: nextSubmission, prev: prevSubmission } = useSelector<RootState, SubmissionPreview>((state) => getSubmissionPreviews(state, submissionId));

  const [action, setAction] = useState<SliderAction>(null);

  const userIdsToFetch = useCallback(() => {
    const selectedUserIndex = userIds.findIndex(id => id === userId);
    if (selectedUserIndex > -1 && userIds.length > 1) {
      if (!action) {
        // Initially when user enters the overview, there won't be any action
        // So load prev and next user's submissions
        const firstIndex = selectedUserIndex - 2 > 0 ? selectedUserIndex - 2 : 0;
        const lastIndex = userIds.length - 1 <= selectedUserIndex + 2 ? userIds.length - 1 : selectedUserIndex + 2;
        return userIds.slice(firstIndex, lastIndex + 1);
      }
      if (action === SliderAction.NEXT && userIds[selectedUserIndex + 1]) {
        return [userIds[selectedUserIndex], userIds[selectedUserIndex + 1]];
      }
      if (action === SliderAction.PREV && userIds[selectedUserIndex - 1]) {
        return [userIds[selectedUserIndex], userIds[selectedUserIndex - 1]];
      }
    }
    return [userId];
  }, [action, userId, userIds]);

  const onSlide = (submission: VideoPracticeSubmission) => {
    if (submission) {
      history.push(makeQueryString({
        user: submission?.user.id,
        submission: submission?.id,
        selected: selectedTab,
      }));
    }
  };

  useEffect(() => {
    dispatch(getSubmissionsForUsers({
      scenarioId,
      userIds: userIdsToFetch(),
    }));
  }, [dispatch, scenarioId, userIds, userIdsToFetch]);

  return (
    <React.Fragment>
      {userIds?.length > 1 ? (
        <UserSubmissionSlider
          onNext={() => {
            onSlide(nextSubmission);
            setAction(SliderAction.NEXT);
          }}
          onPrev={() => {
            onSlide(prevSubmission);
            setAction(SliderAction.PREV);
          }}
          isPrev={!!prevSubmission}
          isNext={!!nextSubmission}
          prevPreview={prevSubmission?.videoFile?.thumbnailUrl ?? ''}
          nextPreview={nextSubmission?.videoFile?.thumbnailUrl ?? ''}
        >
          <UserSubmissions />
        </UserSubmissionSlider>
      ) : <UserSubmissions />}
    </React.Fragment>

  );
};

export default SubmissionOverview;
