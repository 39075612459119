import { css } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { range } from 'underscore';
import t from 'react-translate';

import { RootState } from 'redux/schemas';
import { getCourseAliases } from 'redux/selectors/course';
import { CourseAliases } from 'redux/schemas/models/course';
import { PracticeRoomTab, RecordingFormat, SubmissionPrivacySetting, VideoPracticeActivity, VideoPracticeScenario, VideoPracticeSubmission } from 'redux/schemas/models/video-practice';

import { useLectureComponentContext } from 'components/lecture-component-provider';
import BaseLectureComponentContext from 'lecture_pages/directives/components/base-lecture-component/context';
import { AngularServicesContext } from 'react-app';
import Portal from 'shared/components/portal';
import NvIcon from 'shared/components/nv-icon';
import NvSubmissionGallery from 'shared/components/submission/nv-submission-gallery';
import NvGalleryCardPlaceholder from 'shared/components/nv-gallery-card-placeholder';

import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { gray6 } from 'styles/global_defaults/colors';
import { notHandheld } from 'styles/global_defaults/media-queries';
import { useAppDispatch } from 'redux/store';
import { getVideoPracticeSubmissions } from 'redux/actions/video-practice';
import { getSubmissionsForActivity } from 'redux/selectors/video-practice';
import VideoPracticeSpecialGalleryCard from './video-practice-special-gallery-card';
import VideoPracticeGalleryCard from './video-practice-gallery-card';
import { useGetVideoPracticeLectureComponent } from './video-practice-utils';
import { config } from '../../../../config/pendo.config.json';

const loadingCards = range(5).map(i => <NvGalleryCardPlaceholder key={i} />);

const VideoPracticeGallery = () => {
  const styles = css`
    &.video-practice-gallery {
      margin-top: ${standardSpacing}px;
      margin-left: -${standardSpacing}px;
      margin-right: -${standardSpacing}px;

      .nv-submission-gallery {
        padding-top: ${standardSpacing}px;

        .carousel-next, .carousel-prev {
          margin-top: ${standardSpacing}px;
        }
      }

      .no-gallery {
        background-color: ${gray6};
        margin: 0 -${standardSpacing}px;
      }

      .nv-submission-gallery {
        .carousel-next, .carousel-prev {
          ${notHandheld(css`
            height: 240px;
          `)}
        }
      }
    }
  `;

  const dispatch = useAppDispatch();

  const { extraContentAreaRef } = useContext(BaseLectureComponentContext);
  const { isEdit } = useLectureComponentContext();
  const { $state } = useContext(AngularServicesContext);

  const { practiceActivity: practiceActivityId } = useGetVideoPracticeLectureComponent();
  const {
    progress,
    scenarioId,
    submissionId,
    submissionsLoaded,
    remainingSubmissions,
  } = useSelector<RootState, VideoPracticeActivity>((state) => state.models.practiceActivities[practiceActivityId]);
  const { privacy, recordingFormat } = useSelector<RootState, VideoPracticeScenario>((state) => state.models.practiceScenarios[scenarioId]);
  const mySubmission = useSelector<RootState, VideoPracticeSubmission>((state) => state.models.practiceSubmissions[submissionId]);
  const submissions = useSelector<RootState, VideoPracticeSubmission[]>((state) => getSubmissionsForActivity(state, { activityId: practiceActivityId, userId: mySubmission?.user?.id }));

  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const aliases: CourseAliases = useSelector((state: RootState) => getCourseAliases(state));

  const [isLoading, setIsLoading] = useState(false);
  const [galleryCards, setGalleryCards] = useState<JSX.Element[]>();

  const missedOrCompleted = progress === 'completed' || progress === 'missed';

  useEffect(() => {
    if (!submissionsLoaded && missedOrCompleted) {
      setIsLoading(true);
      setGalleryCards(loadingCards);
      dispatch(getVideoPracticeSubmissions({
        miniGallery: true,
        catalogId,
        activityId: practiceActivityId,
        scenarioId,
      }));
    }
  }, [
    dispatch,
    catalogId,
    scenarioId,
    missedOrCompleted,
    practiceActivityId,
    submissionsLoaded,
  ]);

  const exploreMore = () => {
    $state.go('practice-room-modal', {
      scenarioId,
      selected: PracticeRoomTab.GALLERY,
    });
  };

  useEffect(() => {
    // If submissions have been loaded, create list of JSX.Elements that will be displayed in gallery
    if (submissionsLoaded) {
      let cards: JSX.Element[] = [];

      if (!submissions.length) {
        cards.push(
          <VideoPracticeSpecialGalleryCard
            key='no-submissions'
            header={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.GALLERY.EXPLORE_OTHERS()}
            title={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.GALLERY.NO_SUBMISSIONS()}
          />,
        );
      } else {
        cards = cards.concat(submissions.map((submission, i) => {
          if (i === 0) {
            return (
              <VideoPracticeGalleryCard
                key={submission.id}
                {...submission}
                header={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.GALLERY.EXPLORE_OTHERS()}
                pendoTagName={config.pendo.practice.openPracticeMiniGalleryCard}
                isAudio={recordingFormat === RecordingFormat.AUDIO}
              />
            );
          }

          return (
            <VideoPracticeGalleryCard
              key={submission.id}
              {...submission}
              pendoTagName={config.pendo.practice.openPracticeMiniGalleryCard}
              isAudio={recordingFormat === RecordingFormat.AUDIO}
            />
          );
        }));

        if (remainingSubmissions > submissions.length) {
          cards.push(
            <VideoPracticeSpecialGalleryCard
              key='more-submissions'
              title={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.GALLERY.MORE_SUBMISSIONS()}
              onClick={exploreMore}
              pendoTagName={config.pendo.practice.openPracticeMiniGalleryCard}
            />,
          );
        }
      }

      /**
       * user will have uncompleted submission if activity, which we don't want to display.
       * Only show submission if activity is completed.
       */
      if (mySubmission && progress === 'completed') {
        cards.unshift(
          <VideoPracticeGalleryCard
            key={submissionId}
            {...mySubmission}
            header={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.GALLERY.MY_PRACTICE()}
            pendoTagName={config.pendo.practice.viewMyPractice}
            isAudio={recordingFormat === RecordingFormat.AUDIO}
          />,
        );
      }

      setGalleryCards(cards);
      setIsLoading(false);
    }
  }, [submissionsLoaded]);

  let galleryContent;
  if (isEdit) {
    galleryContent = (
      <div css={styles} className='video-practice-gallery'>
        <div className='no-gallery d-flex flex-column align-items-center justify-content-center text-gray-3'>
          <NvIcon className='mt-5 mb-2' icon='practice' size='medium' />
          <div className='mb-5'>
            {privacy === SubmissionPrivacySetting.SHARED
              ? t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.GALLERY.EDIT_MSG.SHARED()
              : t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.GALLERY.EDIT_MSG.PRIVATE()}
          </div>
        </div>
      </div>
    );
  } else if (galleryCards && missedOrCompleted) {
    galleryContent = (
      <div css={styles} className='video-practice-gallery'>
        <NvSubmissionGallery
          spacing={30}
          arrowsHidden={isLoading}
          bottomPadding={40}
        >
          {galleryCards}
        </NvSubmissionGallery>
      </div>
    );
  }

  return (galleryContent) ? (
    <Portal ref={extraContentAreaRef}>
      {galleryContent}
    </Portal>
  ) : null;
};

export default VideoPracticeGallery;
